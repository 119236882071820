document.addEventListener('DOMContentLoaded', globalEvent => {
  if (globalEvent.target.location.pathname !== '/') {
    return;
  }

  // devsalaries (08.2017), roadgo (11.2017), вектор (03.2018)
  // sarycheva (04.2019), mdwrld (05.2019), скроллфер (07.2019), додо (12.2019)
  // госрасходы (01.2020), алтай (02.2020), timeline (04.2020), bias (06.2020)
  // pins (09.2020), снежинки (01.2021), benchmarks (03.2021),
  // geofences (06.2021), hal9 (11.2021), holyjs (06.2022), mazes (07.2022),
  // esg (08.2022), ioc (11.2022), moma (10.2023), bali-heroes (12.2023),
  // sparkles (02.2024)

  const projectMonths = [
    { month: 7.5, link: '2017' },
    { month: 10.5, link: '2017' },
    { month: 14.5, link: '2018' },
    { month: 27.5, link: '2019' },
    { month: 28.5, link: '2019' },
    { month: 30.5, link: '2019' },
    { month: 35.5, link: '2019' },
    { month: 36.5, link: '2020' },
    { month: 37.5, link: '2020' },
    { month: 39.5, link: '2020' },
    { month: 41.5, link: '2020' },
    { month: 44.5, link: '2020' },
    { month: 48.5, link: '2021' },
    { month: 50.5, link: '2021' },
    { month: 53.5, link: '2021' },
    { month: 58.5, link: '2021' },
    { month: 65.5, link: '2022' },
    { month: 66.5, link: '2022' },
    { month: 67.5, link: '2022' },
    { month: 70.5, link: '2022' },
    { month: 80.5, link: '2023' },
    { month: 82.5, link: '2023' },
    { month: 84.5, link: '2024' },
  ];

  const main = document.querySelector('main');
  const projects = document.querySelector('.projects');
  const timeline = document.querySelector('.timeline');
  const today = new Date();
  const startYear = 2017;
  const endYear = today.getFullYear();
  const fullYears = endYear - startYear;
  const fullMonths = fullYears * 12 + today.getMonth() + 2;

  const calculateTimeline = () => {
    timeline.innerHTML = '';

    const margin = 10;
    const width = timeline.clientWidth;
    const monthWidth = (width - margin * 2) / fullMonths;

    const line = document.createElement('hr');
    line.className = 'main';
    timeline.appendChild(line);

    [...Array(fullYears + 1).keys()].forEach(i => {
      const left = margin + i * 12 * monthWidth;

      const tick = document.createElement('hr');
      tick.className = 'year';
      tick.style.left = `${left}px`;
      timeline.appendChild(tick);

      const label = document.createElement('div');
      label.className = 'label';
      label.innerText = startYear + i;
      label.style.left = `${left}px`;
      timeline.appendChild(label);
    });

    projectMonths.forEach(({ month, link }) => {
      const left = margin + month * monthWidth;

      const point = document.createElement('a');
      point.href = `#${link}`;
      point.className = 'point';
      point.style.left = `${left}px`;
      timeline.appendChild(point);

      point.addEventListener('click', event => {
        event.preventDefault();

        const top =
          document.querySelector(`[name='${link}']`).offsetTop +
          main.offsetTop +
          projects.offsetTop -
          timeline.clientHeight -
          10;

        document.querySelector('body').scrollTo(0, top);
      });
    });
  };
  calculateTimeline();

  window.addEventListener('resize', () => {
    calculateTimeline();
  });
});
