import * as d3 from 'd3';
import data from '../data/moma-artists';

document.addEventListener('DOMContentLoaded', globalEvent => {
  if (globalEvent.target.location.pathname !== '/moma-artists') {
    return;
  }

  const margin = { top: 20, right: 15, bottom: 40, left: 45 };

  let width;
  let height;
  let ticksCount;

  const svg = d3.select('svg');
  const svgNode = svg.node();
  const group = svg
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  const tooltip = d3.select('.tooltip');

  const currentYear = new Date().getFullYear();
  const cleanData = data
    .filter(v => v.count >= 1 && !!v.endYear && v.startYear < 1970 && v.endYear - v.startYear > 30)
    .sort((v1, v2) => v2.count - v1.count);

  const nationalities = cleanData.reduce((res, v) => {
    res[v.nationality] = res[v.nationality] || 0;
    res[v.nationality] += 1;
    return res;
  }, {});

  let selected = null;
  let markers = null;

  const filters = {
    Female: true,
    Male: true,
    xs: true,
    sm: true,
    nationality: 'All nationalities',
  };

  const xDomain = [
    d3.min(cleanData, d => d.startYear),
    d3.max(cleanData, d => d.startYear),
  ];
  const yDomain = [
    d3.min(cleanData, d => ((d.endYear || currentYear) - d.startYear)),
    d3.max(cleanData, d => ((d.endYear || currentYear) - d.startYear)),
  ];
  const sizeDomain = [0, d3.max(cleanData, d => d.count)];

  const xScale = d3.scaleLinear().domain(xDomain);
  const yScale = d3.scaleLinear().domain(yDomain);
  const sizeScale = d3.scaleLog().domain([1, 10]).range([1, 8]);

  const xAxis = d3.axisBottom(xScale).tickFormat(d3.format('d'))
  const yAxis = d3.axisLeft(yScale);

  const xAxisGroup = group.append('g');
  const yAxisGroup = group.append('g');

  const xAxisTitle = svg.append('text')
    .text('Year of Birth')
    .attr('class', 'axis-title');

  const yAxisTitle = svg.append('text')
    .text('Years Lived')
    .attr('transform', 'rotate(-90)')
    .attr('class', 'axis-title');

  const getTooltip = (d) => (
    `<div class='${d.gender}'><b>${d.name}</b></div>${d.count} work${d.count > 1 ? 's' : ''}</br>
      <div class='small'><i>${d.nationality}</i><br/><i>${d.startYear} - ${d.endYear}</i></div>`
  );

  const onSelectMarker = (d) => {
    if (!markers) return;
    tooltip
      .html(getTooltip(d))
      .transition()
      .duration(50)
      .style('opacity', 1)
      .style('pointer-events', 'all');
    markers
      .filter(v => d.name !== v.name)
      .transition()
      .duration(50)
      .style('opacity', 0.33)
      .style('stroke', 'none');
  };
  const onDeselectMarker = (name) => {
    if (!markers) return;
    tooltip
      .transition()
      .duration(50)
      .style('opacity', 0)
      .style('pointer-events', 'none');
    markers
      .filter(v => name !== v.name)
      .transition()
      .duration(50)
      .style('opacity', 1)
      .style('stroke', '#333');
  };

  const renderMarkers = () => {
    markers = group.selectAll('circle')
      .data(cleanData)
      .enter()
      .append('circle')
      .attr('cx', d => xScale(d.startYear))
      .attr('cy', d => yScale((d.endYear || currentYear) - d.startYear))
      .attr('r', d => sizeScale(d.count))
      .attr('data-gender', d => d.gender)
      .attr('data-count', d => d.count)
      .attr('data-nationality', d => d.nationality)
      .attr('fill', d => d.gender === 'Female' ? '#ea608c99' : '#0d558999')
      .attr('stroke', '#333')
      .on('click', (event, d) => {
        if (!!selected) onDeselectMarker(selected);
        if (d.name === selected) {
          selected = null;
          return;
        }
        selected = d.name;
        onSelectMarker(d);
      })
      .on('mouseover', (event, d) => {
        if (!!selected) return;
        onSelectMarker(d);
      })
      .on('mouseout', (event, d) => {
        if (!!selected) return;
        onDeselectMarker(d.name);
      });
  };

  const filterMarkersBySelector = (selector, value) => {
    group
      .selectAll(selector)
      .transition()
      .duration(100)
      .attr('r', d => value ? sizeScale(d.count) : 0)
      .style('opacity', value ? 1 : 0);
  };

  const filterMarkers = () => {
    filterMarkersBySelector('circle', true);

    if (!filters.xs && !filters.sm) {
      filterMarkersBySelector('[data-count="1"],[data-count="2"],[data-count="3"],[data-count="4"]', false);
    } else if (!filters.sm) {
      filterMarkersBySelector('[data-count="2"],[data-count="3"],[data-count="4"]', false);
    } else if (!filters.xs) {
      filterMarkersBySelector('[data-count="1"]', false);
    }

    if (!filters.Female) {
      filterMarkersBySelector('[data-gender="Female"]', false);
    }
    if (!filters.Male) {
      filterMarkersBySelector('[data-gender="Male"]', false);
    }

    if (filters.nationality !== 'All nationalities') {
      filterMarkersBySelector(`circle:not([data-nationality='${filters.nationality}'])`, false);
    }

  };

  const filterByNationality = () => {
    const value = filters.nationality;

    if (value === 'All nationalities') {
      filterMarkers('circle[data-nationality]', true);
    } else {
      filterMarkers('circle[data-nationality]', true);
      filterMarkers(`circle:not([data-nationality='${value}'])`, false);
    }
  };

  const resize = () => {
    width = svgNode.clientWidth - margin.left - margin.right;
    height = svgNode.clientHeight - margin.top - margin.bottom;
    ticksCount = Math.min(10, width / 50);

    group.attr('width', width).attr('height', height);

    xScale.range([0, width]);
    yScale.range([height, 0]);

    xAxis.ticks(ticksCount);
    yAxis.ticks(ticksCount);

    xAxisGroup.attr('transform', `translate(0,${height})`).call(xAxis);
    yAxisGroup.call(yAxis);

    xAxisTitle
      .attr('x', width / 2 + margin.left)
      .attr('y', height + margin.top + margin.bottom - 5);
    yAxisTitle
      .attr('y', 15)
      .attr('x', -height / 2 - margin.top);

    group.selectAll('circle')
      .data(cleanData)
      .attr('cx', d => xScale(d.startYear))
      .attr('cy', d => yScale((d.endYear || currentYear) - d.startYear))
  };

  const initSelect = () => {
    const select = document.querySelector('#filter-nation');
    const names = Object.keys(nationalities).sort((n1, n2) => nationalities[n2] - nationalities[n1]);

    const addOption = (name, value) => {
      const option = document.createElement('option');
      option.value = name;
      option.innerHTML = `${name} (${value})`;
      select.appendChild(option);
    };

    select.innerHTML = '';

    addOption('All nationalities', cleanData.length);
    names.forEach(n => {
      addOption(n, nationalities[n]);
    });

    select.addEventListener('change', (e) => {
      filters.nationality = e.target.value;
      filterByNationality();
    });
  };

  svg.on('click', (event) => {
    if (event.target.tagName === 'circle') return;
    if (!!selected) {
      onDeselectMarker(selected);
      selected = null;
    }
  });

  window.addEventListener('resize', resize);

  document.querySelector('#filter-female').addEventListener('change', (e) => {
    filters.Female = e.target.checked;
    filterMarkers();
  });
  document.querySelector('#filter-male').addEventListener('change', (e) => {
    filters.Male = e.target.checked;
    filterMarkers();
  });
  document.querySelector('#filter-xs').addEventListener('change', (e) => {
    filters.xs = e.target.checked;
    filterMarkers();
  });
  document.querySelector('#filter-sm').addEventListener('change', (e) => {
    document.querySelector('#filter-xs').checked = e.target.checked;
    filters.sm = e.target.checked;
    filters.xs = e.target.checked;
    filterMarkers();
  });

  resize();
  initSelect();
  renderMarkers();
});
