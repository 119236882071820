export default [
    {
        "name": "Robert Arneson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1992,
        "count": 1
    },
    {
        "name": "Doroteo Arnaiz",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bill Arnold",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles Arnoldi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Per Arnoldi",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Danilo Aroldi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bill Aron",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Aronson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Irene Aronson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1918,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jüri Arrak",
        "nationality": "Estonian",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "J. Arrelano Fischer",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Folke Arstrom",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Richard Artschwager",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2013,
        "count": 13
    },
    {
        "name": "Ruth Asawa",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1926,
        "endYear": 2013,
        "count": 1
    },
    {
        "name": "Donald Ashcraft",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Geneviève Asse",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sergio Asti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dana Atchley",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Eero Aarnio",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jules Aarons",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Magdalena Abakanowicz",
        "nationality": "Polish",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 2017,
        "count": 3
    },
    {
        "name": "Nobuya Abe",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Robert Abel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sigmund Abeles",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Raimund Abraham",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Ivor Abrahams",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gerald Abramovitz",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "William Ross Abrams",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Rodolfo Abularach",
        "nationality": "Guatemalan",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Vito Acconci",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 2017,
        "count": 17
    },
    {
        "name": "Milton Ackoff",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luis Alberto Acuña",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Henri Georges Adam",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1967,
        "count": 0
    },
    {
        "name": "Valerio Adami",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Ansel Adams",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Alice Adams",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Clinton Adams",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Dennis Adams",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Adams",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shelby Lee Adams",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles Addams",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1988,
        "count": 3
    },
    {
        "name": "Allan Adler",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Virginia Admiral",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1915,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Pic Adrian",
        "nationality": "Romanian",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roy Adzak",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1987,
        "count": 0
    },
    {
        "name": "Nicholas Africano",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Afro (Afro Basaldella)",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Agam (Yaacov Agam)",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kathleen Agnoli",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Agostini",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1993,
        "count": 1
    },
    {
        "name": "Dongkuk S. Ahn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Aarne Aho",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Otl Aicher (also known as Otto Aicher)",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Lucien Aigner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Gregory Ain",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Edgar Ainsworth",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Roberto Aizenberg",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 1996,
        "count": 2
    },
    {
        "name": "Kinji Akagawa",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ryoji Akiyama",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Munira Al-Kazi",
        "nationality": "Kuwaiti",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Joan Albert",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Albert Alcalay",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 2008,
        "count": 1
    },
    {
        "name": "L. (Alfred Lewin Copley) Alcopley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1992,
        "count": 1
    },
    {
        "name": "Alan Aldridge",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pierre Alechinsky",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Peter Alexander",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "José R. Alicea",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Alinder",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William Allan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Alexander Alland",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Harry Allen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Terry Allen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carlos Alonso",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Glen Alps",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Henry Altchek",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gerhard Altenbourg",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1989,
        "count": 1
    },
    {
        "name": "Hobie Alter",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harold Altman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2003,
        "count": 2
    },
    {
        "name": "John Altoon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1969,
        "count": 0
    },
    {
        "name": "Manuel Álvarez Bravo",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Dag Alveng",
        "nationality": "Norwegian",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Getulio Alviani",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jim Amaral",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Emilio Ambasz",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Irving Amen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Woody van Amen",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gregory Amenoff",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Florencia de Amesti",
        "nationality": "Chilean",
        "gender": "Female",
        "startYear": 1925,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Francisco Amighetti Ruiz",
        "nationality": "Costa Rican",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Emma Amos",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Mauricio Amster Cats",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "William Anastasi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2021,
        "count": 9
    },
    {
        "name": "Stephen Ancona",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jiþi Anderle",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gunnar Aagaard Andersen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Karin Schou Andersen",
        "nationality": "Danish",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carl Anderson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Dennis Anderson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jeremy Anderson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1982,
        "count": 1
    },
    {
        "name": "Jerry Anderson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Anderson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "Neil Anderson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Monika Andersson",
        "nationality": "Swedish",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joe Andoe",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Farnese de Andrade-Neto",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Carl Andre",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 14
    },
    {
        "name": "Pat Andrea",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Henning Andreasen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Laura Andreson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1902,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Benny Andrews",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2006,
        "count": 11
    },
    {
        "name": "Emmy Andriesse",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1914,
        "endYear": 1953,
        "count": 0
    },
    {
        "name": "Claudia Andujar",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Raúl Anguiano",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "David Annesley",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Horst Antes",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "William Anthony",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephen Antonakos",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2013,
        "count": 5
    },
    {
        "name": "Garo Antreasian",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Nemesio Antúnez",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Richard Anuszkiewicz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Carl Apfelschnitt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Karel Appel",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2006,
        "count": 2
    },
    {
        "name": "Ida Applebroog",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 0,
        "count": 13
    },
    {
        "name": "Shusaku Arakawa",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2010,
        "count": 1
    },
    {
        "name": "Diane Arbus",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1923,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Luis Arenal",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Avigdor Arikha",
        "nationality": "Romanian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Gonzalo Ariza",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Siah Armajani",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 2020,
        "count": 1
    },
    {
        "name": "Arman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2005,
        "count": 2
    },
    {
        "name": "Armando",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kenneth Armitage",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "François Arnal",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Conrad Atkinson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Terry Atkinson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean-Michel Atlan",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1960,
        "count": 1
    },
    {
        "name": "Dotty Attie",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carl Auböck",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Frank Auerbach",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Gae Aulenti",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Keith James Aulik",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Mario Avati",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Edward Avedisian",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Richard Avedon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Frances Avery",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1910,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "David Avison",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Kiyoshi Awazu",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Ay-O",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Alice Aycock",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Pierre Ayot",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Michael Ayrton",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Luis Cruz Azaceta",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Umetaro Azechi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Kengiro Azuma",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alice Baber",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1928,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Edmondo Bacci",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Elvira Bach",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Olof Backstrom",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Francis Bacon",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Karl Baden",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mowry Thatcher Baden",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Libero Badii",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Frank Badur",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Donald Baechler",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 2022,
        "count": 0
    },
    {
        "name": "Jo Baer",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 0,
        "count": 24
    },
    {
        "name": "Olle Baertling",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Myrna Báez",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Bageris",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2000,
        "count": 2
    },
    {
        "name": "Heinz Bahr",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Malcolm Bailey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 2011,
        "count": 1
    },
    {
        "name": "Oscar Bailey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "William Bailey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Larry Bair",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Enrico Baj",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Mikal Baker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vagrich Bakhchanyan",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Gerhard H. Bakker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Amèrico Balán",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Jirí Balcar",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 1968,
        "count": 0
    },
    {
        "name": "John Baldessari",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2020,
        "count": 11
    },
    {
        "name": "George Baldessin",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Benjamin Baldwin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Joost Baljeu",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "R. Maxil Ballinger",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1958,
        "count": 0
    },
    {
        "name": "Theo H. Ballmer",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1965,
        "count": 0
    },
    {
        "name": "Alexander Bally",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John F. Balossi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Balthus (Baltusz Klossowski de Rola)",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 2001,
        "count": 8
    },
    {
        "name": "Lewis Baltz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Thomas Bamberger",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "McDonald (Mackey Jeffries) Bane",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1928,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Colette Bangert",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1934,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Walter Darby Bannard",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "John Banting",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1972,
        "count": 0
    },
    {
        "name": "Rudolf Baranik",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Anthony Barboza",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Miquel Barcelo",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Bardazzi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Richard Baringer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1980,
        "count": 1
    },
    {
        "name": "George Barker",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Walter William Barker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2004,
        "count": 1
    },
    {
        "name": "Robert Barnes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Will Barnet",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Tina Barney",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hannelore Baron",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1926,
        "endYear": 1987,
        "count": 1
    },
    {
        "name": "Martin Barooshian",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Susan Barron",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas F. Barrow",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Barry",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 17
    },
    {
        "name": "Robert Bart",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Frances Barth",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Ruodi Barth",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Ralph Bartholomew, Jr.",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Jennifer Bartlett",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 2022,
        "count": 3
    },
    {
        "name": "Robert Bartlett",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Carlo Bartoli",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joseph Bartscherer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gianfranco Baruchello",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Bernard Baschet",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "François Baschet",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Georg Baselitz",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 14
    },
    {
        "name": "Mati Basis",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leonard Baskin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2000,
        "count": 2
    },
    {
        "name": "Jean-Michel Basquiat",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 1988,
        "count": 4
    },
    {
        "name": "Joel Bass",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Saul Bass",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Miriam Bat-Yosef",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Battan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Wall Batterton",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eugen Batz",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Mary Bauermeister",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1934,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Marilyn Baum",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Hans Theo Baumann",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lothar Baumgarten",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Edward Bawden",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Iain Baxter&",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Ed Baynard",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Bayrle",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Douglas Baz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean Bazaine",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Castera Bazile",
        "nationality": "Haitian",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1966,
        "count": 0
    },
    {
        "name": "William Baziotes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1963,
        "count": 2
    },
    {
        "name": "Jack Beal",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2013,
        "count": 2
    },
    {
        "name": "Lester Beall",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1969,
        "count": 0
    },
    {
        "name": "Mark Beard",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Romare Bearden",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1988,
        "count": 9
    },
    {
        "name": "Bruce Beasley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cecil Beaton",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "Alessandro Becchi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bernd Becher",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Thelma Becherer",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1916,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Robert Bechtle",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2020,
        "count": 3
    },
    {
        "name": "Gustav K. Beck",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Fred Becker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Bill Beckley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Hedda Beese",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edith Behring",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1916,
        "endYear": 1996,
        "count": 1
    },
    {
        "name": "John Behringer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "J. J. Beljon",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Alistair Macready Bell",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Larry Bell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ross Bellah",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Joseph Bellanca",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "John Bellany",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Mario Bellini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hans Bellmer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1975,
        "count": 3
    },
    {
        "name": "Rossella Bellusci",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giandomenico Belotti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christian Belpaire",
        "nationality": "Congolese",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Ahron Ben-Shmuel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Adolf Benca",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Niels Sylvester Bendtsen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nair Benedicto",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luis Fernando Benedit",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2011,
        "count": 27
    },
    {
        "name": "Lynda Benglis",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Billy Al Bengston",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2022,
        "count": 0
    },
    {
        "name": "Jim Bengston",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gordon Bennett",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Ward Bennett",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "David Bentley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nicholas Bentley",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Roloff Beny",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Christian Bérard",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1949,
        "count": 1
    },
    {
        "name": "Roberto Berdecio",
        "nationality": "Bolivian",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Vera Berdich",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1915,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Ed Bereal",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephen Lynn Berens",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles Berger",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jason Berger",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Håkan Bergkvist",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Bergtold",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mieczyslaw Berman",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Wallace Berman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Zeke Berman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cundo Bermúdez",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "José Ygnacio Bermudez",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1988,
        "count": 3
    },
    {
        "name": "Jerry Berndt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Ruth Bernhard",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1905,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Antonio Berni",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Janez Bernik",
        "nationality": "Slovenian",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Judith Bernstein",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Bernstein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Miguel Ortiz Berrocal",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "René Bertholo",
        "nationality": "Portuguese",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2005,
        "count": 1
    },
    {
        "name": "Jake Berthot",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 2014,
        "count": 29
    },
    {
        "name": "Gianni Bertini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harry Bertoia",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1978,
        "count": 1
    },
    {
        "name": "Forrest Bess",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1977,
        "count": 0
    },
    {
        "name": "Ruth Bessoudo Courvoisier",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1914,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Alan Randall Best",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wim Beuning",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Joseph Beuys",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1986,
        "count": 38
    },
    {
        "name": "Jyoti Bhatt",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Domenico Bianchi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Pamela Bianco",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1906,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Fulvio Bianconi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Alberto Biasi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Guido Biasi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ashley Bickerton",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles Biederman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "James Biederman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Wilson Bigaud",
        "nationality": "Haitian",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Max Bill",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Manfred Bingler",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Birkhauser",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Robert Birmelin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Werner Bischof",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1954,
        "count": 0
    },
    {
        "name": "Elmer Bischoff",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Isabel Bishop",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1902,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "James Bishop",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2021,
        "count": 2
    },
    {
        "name": "Michael Bishop",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Maureen Bisilliat",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Antonio Bisquert Pérez",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Arnold Bittleman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 1985,
        "count": 3
    },
    {
        "name": "Dolores Dembus Bittleman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ion Bitzan",
        "nationality": "Romanian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1997,
        "count": 1
    },
    {
        "name": "Judith Black",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wendell Black",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1972,
        "count": 0
    },
    {
        "name": "Elizabeth Blackadder",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Morris Blackburn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Tom Blackwell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Ronald Bladen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 1988,
        "count": 10
    },
    {
        "name": "Nell Blaine",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1922,
        "endYear": 1996,
        "count": 2
    },
    {
        "name": "Jean-Charles Blais",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jeffrey Blake",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Blake",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Camilo Blas (José Alfonso Sánchez Urteaga)",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1985,
        "count": 1
    },
    {
        "name": "Werner Blaser",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ross Bleckner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "René Bleibtreu",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pierrette Bloch",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Clarence Lincoln Block",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1942,
        "count": 0
    },
    {
        "name": "Gay Block",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barbara Bloom",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hyman Bloom",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Vern Blosum",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Sandra Blow",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1925,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Norman Bluhm",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1999,
        "count": 5
    },
    {
        "name": "Donald Blumberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anna Blume",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Bernhard Blume",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Peter Blume",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1992,
        "count": 13
    },
    {
        "name": "Marcello Boccacci",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1996,
        "count": 1
    },
    {
        "name": "Mel Bochner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 24
    },
    {
        "name": "Bengt Böckman",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alighiero Boetti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 1994,
        "count": 10
    },
    {
        "name": "Marinus Boezem",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Ricardo Bofill",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Arthur Emil Bofinger",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Varujan Boghosian",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Blythe Bohnen",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Olaf von Bohr",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Karl Bohrmann",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Bruce Boice",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roger Bolomey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jay Bolotin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ilya Bolotowsky",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1981,
        "count": 1
    },
    {
        "name": "Christian Boltanski",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 2021,
        "count": 1
    },
    {
        "name": "Bona",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Claus Bonderup",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Antonio Bonet",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Rodolfo Bonetto",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Marcelo Bonevardi",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Dusti Bongé",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1903,
        "endYear": 1993,
        "count": 1
    },
    {
        "name": "Paolo Boni",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Maria Bonomi",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Bononno",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lee Bontecou",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 0,
        "count": 15
    },
    {
        "name": "Fabio Bonzi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Peter Booth",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 2021,
        "count": 4
    },
    {
        "name": "Osvaldo Borda",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Enrico Bordoni",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1969,
        "count": 0
    },
    {
        "name": "Paul-Émile Borduas",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1960,
        "count": 1
    },
    {
        "name": "Norah Borges de Torre",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1901,
        "endYear": 1998,
        "count": 1
    },
    {
        "name": "Jonathan Borofsky",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Elena Borstein",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Derek Boshier",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Bosman",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Check  Boterf",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fernando Botero",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Dorr Bothwell",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1902,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Mario Botta",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kenneth J. Botto",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Édouard Boubat",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "René Robert Bouché",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1963,
        "count": 2
    },
    {
        "name": "Christine Boumeester",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1904,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Harry Bouras",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 1993,
        "count": 5
    },
    {
        "name": "Louise Bourgeois",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1911,
        "endYear": 2010,
        "count": 57
    },
    {
        "name": "Fergus Bourke",
        "nationality": "Irish",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Margaret Bourke-White",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1904,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Lynn Bowers",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Frank Bowling",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stanley Boxer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2000,
        "count": 1
    },
    {
        "name": "Fionnuala Boyd",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Henderson Boyd",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Mark Boyle",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Boynton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Paul Brach",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Angel Bracho",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Su Braden",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Bradley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dove Bradshaw",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Joe Brainard",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 1994,
        "count": 6
    },
    {
        "name": "Bill Brandt",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Robert Brandwein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 1972,
        "count": 0
    },
    {
        "name": "John Randall Bratby",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Pieter Brattinga",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Victor Brauner",
        "nationality": "Romanian",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1966,
        "count": 2
    },
    {
        "name": "Troy Brauntuch",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Claudio Bravo",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2011,
        "count": 2
    },
    {
        "name": "Stipe Brčić",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George Brecht",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2008,
        "count": 41
    },
    {
        "name": "Robert Breer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Carl-Arne Breger",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "K. P. Brehmer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Raymond Breinin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 2000,
        "count": 1
    },
    {
        "name": "Uwe Bremer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Miguel Bresciano",
        "nationality": "Uruguayan",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Marcel Breuer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "William Brice",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2008,
        "count": 3
    },
    {
        "name": "Marilyn Bridges",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barry Bridgwood",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Judson Briggs",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Serge Brignoni",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Denis Brihat",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Enrique Brinkmann",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Horace Bristol",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "René Brô",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1987,
        "count": 0
    },
    {
        "name": "John Brockman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gottfried Brockmann",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1983,
        "count": 2
    },
    {
        "name": "Gandy Brodie",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Alexander Brodsky",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jürgen Brodwolf",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephen Brody",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stig Brøgger",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Broner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Marcel Broodthaers",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1976,
        "count": 6
    },
    {
        "name": "John Brook",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Brooks",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Milton Brooks",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1956,
        "count": 0
    },
    {
        "name": "Wendell Brooks",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harry Brorby",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Joan Brossa",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Gary Stephen Brotmeyer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gustav A. Brown",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Brown",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 2020,
        "count": 1
    },
    {
        "name": "Joan Brown",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Kathan Brown",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ray Brown",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roger Brown",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Byron Browne",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1961,
        "count": 2
    },
    {
        "name": "Colleen Browning",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Brownjohn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1970,
        "count": 0
    },
    {
        "name": "Kenneth Brozen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Roser Llop Bru",
        "nationality": "Chilean",
        "gender": "Female",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Salvador Bru",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Brühwiler",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William Brui",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Donald Brun",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Peter Brüning",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 1970,
        "count": 3
    },
    {
        "name": "Robert Brunner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Norman Bruns",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Günter Brus",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 9
    },
    {
        "name": "Grisha Bruskin",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wim Brusse",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "William Bryant",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Camille Bryen",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1976,
        "count": 1
    },
    {
        "name": "Tadeusz Brzozowski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 1987,
        "count": 0
    },
    {
        "name": "Gernot Bubenik",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Boris Bućan",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Erich Buchegger",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Buck",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter H. Buckley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "David Budd",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1991,
        "count": 2
    },
    {
        "name": "Dan Budnik",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Bernard Buffet",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Fritz Bühler",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1963,
        "count": 0
    },
    {
        "name": "Adam Bujak",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wynn Bullock",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Torun Bülow-Hübe",
        "nationality": "Swedish",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Louis Bunce",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Willi Bunkowski",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pierre Buraglio",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chris Burden",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 2015,
        "count": 11
    },
    {
        "name": "Daniel Buren",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bill Burke",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ian Burn",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Deirdre Burnett",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joseph Burnett",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marsha Burns",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edward Burra",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1976,
        "count": 1
    },
    {
        "name": "Alberto Burri",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "René Burri",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Larry Burrows",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Richmond Burton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Scott Burton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Pol Bury",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Andrew Foster Bush",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jack Bush",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1977,
        "count": 0
    },
    {
        "name": "Wolf von dem Bussche",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Frances Butler",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Reg Butler",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1983,
        "count": 4
    },
    {
        "name": "Elizabeth Butterworth",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "James Lee Byars",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 1997,
        "count": 9
    },
    {
        "name": "Michael Byron",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luigi Caccia Dominioni",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Cadenhead",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Paul Cadmus",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1999,
        "count": 36
    },
    {
        "name": "Poul Cadovius",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Deborah Fleming Caffery",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Cage",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1992,
        "count": 17
    },
    {
        "name": "Corrado Cagli",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1976,
        "count": 3
    },
    {
        "name": "Miriam Cahn",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Fred Cain",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "José Milner Cajahuaringa",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Charles Cajori",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Letterio Calapai",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Nicolas Calas",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Lawrence Calcagno",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Dale R. Caldwell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harry Callahan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Kenneth Callahan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1986,
        "count": 1
    },
    {
        "name": "Mary Callery",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1903,
        "endYear": 1977,
        "count": 0
    },
    {
        "name": "Jo Ann Callis",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julio Camino Sanchez",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Luis Camnitzer",
        "nationality": "Uruguayan",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jewett Campbell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Steven Campbell",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Antonia Campi",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Campus",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Antonio Cañavate Gómez",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1987,
        "count": 0
    },
    {
        "name": "John Candelario",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Louis Cane",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rafael Canogar",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Domenico Cantatore",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Federico Cantú",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Cornell Capa",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Robert Capa",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1954,
        "count": 0
    },
    {
        "name": "Bruno Capacci",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Paul Caponigro",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Miguel-Ángel Cárdenas",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2015,
        "count": 2
    },
    {
        "name": "Augustin Càrdenas",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Santiago Cárdenas",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Marie Carlier",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1920,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Richard Carlson",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eugenio Carmi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John William Carnell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anthony Caro",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Paolo Carosone",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mario Carreño",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1999,
        "count": 2
    },
    {
        "name": "Mario Carrieri",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leonora Carrington",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1917,
        "endYear": 2011,
        "count": 2
    },
    {
        "name": "Patricia Jon Carroll",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Clarence H. Carter",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Paul Carter",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1938,
        "count": 0
    },
    {
        "name": "Henri Cartier-Bresson",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Carybé (Hector Bernabó)",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1997,
        "count": 2
    },
    {
        "name": "John Casado",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrea Cascella",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1920,
        "endYear": 1990,
        "count": 1
    },
    {
        "name": "Pietro Cascella",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jacqueline S. Casey",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sydney Cash",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tranquillo Casiraghi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "A. M. Cassandre",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1968,
        "count": 0
    },
    {
        "name": "Axel Cassel",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Herbert Carroll Cassill",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Moshe Elazar Castel",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Enrico Castellani",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julio Castellanos",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1947,
        "count": 0
    },
    {
        "name": "Federico Castellon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Achille Castiglioni",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Pier Giacomo Castiglioni",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1968,
        "count": 0
    },
    {
        "name": "Rosemarie Castoro",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 2015,
        "count": 1
    },
    {
        "name": "Enrique Castro-Cid",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 1992,
        "count": 1
    },
    {
        "name": "Umberto Catalano",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elizabeth Catlett",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1915,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Bob Cato",
        "nationality": "Nationality unknown",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Patrick Caulfield",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Alik Cavaliere",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giorgio Cavallon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1989,
        "count": 5
    },
    {
        "name": "Bruno Ceccobelli",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Francis Celentano",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vija Celmins",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 12
    },
    {
        "name": "César (César Baldaccini)",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1998,
        "count": 3
    },
    {
        "name": "Lynn Chadwick",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2003,
        "count": 3
    },
    {
        "name": "Gaston Chaissac",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1964,
        "count": 2
    },
    {
        "name": "Paul Chaleff",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Chamberlain",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2011,
        "count": 3
    },
    {
        "name": "Bruce Chandler",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Linda Chapman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Walter Chappell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chargesheimer (Karl-Heinz Hargesheimer)",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1972,
        "count": 0
    },
    {
        "name": "Sarah Charlesworth",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Louisa Chase",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Edward Chavez",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1995,
        "count": 1
    },
    {
        "name": "José Chávez Morado",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Santos Chávez",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Ivan Chermayeff",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Norman Cherner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Chernyshov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lee Chesney",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sandro Chia",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Judy Chicago",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bernard Childs",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Eduardo Chillida",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2002,
        "count": 1
    },
    {
        "name": "Thomas Chimes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2009,
        "count": 1
    },
    {
        "name": "Marcel Chirnoaga",
        "nationality": "Romanian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Anna Chojnacka",
        "nationality": "Polish",
        "gender": "Female",
        "startYear": 1914,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Seiji Chokai",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1972,
        "count": 0
    },
    {
        "name": "Chunghi Choo",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William Christenberry",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Dan Christensen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 2007,
        "count": 1
    },
    {
        "name": "Christo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2020,
        "count": 8
    },
    {
        "name": "Halina Chrostowska",
        "nationality": "Polish",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Chryssa",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1933,
        "endYear": 2013,
        "count": 24
    },
    {
        "name": "Caldecot Chubb",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John W. Church",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Seymour Chwast",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Ciavolino",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Carmen L. Cicero",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Roman Cieslewicz",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Carlo Cisventi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Larry Clark",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Clem Clarke",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "José Claudio Da Silva",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Carlfriedrich Claus",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Antoni Clavé",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Maude Schuyler Clay",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cléanthe (Cléante Carr)",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1911,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Roman Clemens",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Francesco Clemente",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Lucien Clergue",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fabrizio Clerici",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1993,
        "count": 1
    },
    {
        "name": "Pierre Clerk",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William Clift",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Clinton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carroll Cloar",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Chuck Close",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 2021,
        "count": 8
    },
    {
        "name": "John Timothy Close",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Prunella Clough",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1919,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "William Clutz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Gerald Coble",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Chema Cobo",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sam Cocomise",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Sue Coe",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Bernard Cohen",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Barry Cohen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 1990,
        "count": 2
    },
    {
        "name": "George Cohen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Mark Cohen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sheldon Cohen",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pinchas Cohen Gan",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Van Deren Coke",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luigi Colani",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Colescott",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Warrington Colescott",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Edward Colker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marjory Collins",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1912,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Roy Colmer",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Gino Colombini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gianni Colombo",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Joe Colombo",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Ithell Colquhoun",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1906,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Robert Colquhoun",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1962,
        "count": 1
    },
    {
        "name": "Greg Colson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alvin Colt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2008,
        "count": 32
    },
    {
        "name": "Ian Colverson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alex Colville",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Ulysse Comtois",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Miguel Conde",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "George Condo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "William Congdon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Lee Conklin",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bruce Conner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2008,
        "count": 12
    },
    {
        "name": "Gregory Conniff",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Linda Connor",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pietro Consagra",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "William H. Constable",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1989,
        "count": 1
    },
    {
        "name": "Constant (Constant Anton Nieuwenhuys)",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2005,
        "count": 1
    },
    {
        "name": "Howard Cook",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "Lia Cook",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Francisco Copello",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Hans Coper",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Andries Dirk Copier",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "John Coplans",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "William Copley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1996,
        "count": 11
    },
    {
        "name": "Silvio Coppola",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Edward Corbett",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1971,
        "count": 2
    },
    {
        "name": "Al Corchia",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "E. M. Cordier",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1974,
        "count": 0
    },
    {
        "name": "Corneille (Guillaume van Beverloo)",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Joseph Cornell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1972,
        "count": 8
    },
    {
        "name": "Thomas Cornell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Hector Coronado",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pedro Coronel",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1985,
        "count": 1
    },
    {
        "name": "Antonio Corpora",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Gloria Cortella",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean Cortot",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Steven Cortright",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marie Cosindas",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1923,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Herman Costa",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Olga Costa",
        "nationality": "Mexican",
        "gender": "Female",
        "startYear": 1913,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Toni Costa",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Alan Cote",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Robert Cottingham",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jack Coughlin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Graham Coughtry",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Edward Countey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Pierre Courtin",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Morison S. Cousins",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Lucien Coutaud",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1977,
        "count": 0
    },
    {
        "name": "Robert Couturier",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Wessel Couzijn",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Miguel Covarrubias",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1957,
        "count": 0
    },
    {
        "name": "Robert Cowan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jan Cox",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "Douglas Craft",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2015,
        "count": 1
    },
    {
        "name": "Tony Cragg",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Craig-Martin",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Barbara Crane",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1928,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Meg Cranston",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 66
    },
    {
        "name": "Crash (John Matos)",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mario Cravo Junior",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2018,
        "count": 4
    },
    {
        "name": "Ralston Crawford",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1978,
        "count": 3
    },
    {
        "name": "John Craxton",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Robert Creeley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Robert Cremean",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leonardo Cremonini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2010,
        "count": 1
    },
    {
        "name": "Luca Crippa",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1924,
        "endYear": 2002,
        "count": 2
    },
    {
        "name": "Roberto Crippa",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1972,
        "count": 0
    },
    {
        "name": "Renato Cristiano",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Allan Rohan Crite",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 2007,
        "count": 2
    },
    {
        "name": "Ted Croner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Wim Crouwel",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Robin Crozier",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2001,
        "count": 1
    },
    {
        "name": "Jason Crum",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "William Crutchfield",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Emilio Cruz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2004,
        "count": 1
    },
    {
        "name": "Carlos Cruz-Diez",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "John J. Cu Roi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Enzo Cucchi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "José Luis Cuevas",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2017,
        "count": 3
    },
    {
        "name": "Modest Cuixart",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Gordon Cullen",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ben L. Culwell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 1992,
        "count": 2
    },
    {
        "name": "Robert Cumming",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 2021,
        "count": 1
    },
    {
        "name": "Ben Cunningham (Benjamin Frazier Cunningham)",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "John Timothy Curran",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anthony Currell",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 2022,
        "count": 0
    },
    {
        "name": "William R. Current",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Lily Cushing",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1909,
        "endYear": 1969,
        "count": 2
    },
    {
        "name": "Ronnie Cutrone",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gunnar Cyrén",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Allan D'Arcangelo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1998,
        "count": 4
    },
    {
        "name": "Lajos D'Ebneth",
        "nationality": "Hungarian",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1982,
        "count": 1
    },
    {
        "name": "Roél d'Haese",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1966,
        "count": 3
    },
    {
        "name": "Alfredo da Silva",
        "nationality": "Bolivian",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Karl Fred Dahmen",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Walter Dahn",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael D. Dailey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2009,
        "count": 1
    },
    {
        "name": "Salvador Dalí",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1989,
        "count": 5
    },
    {
        "name": "Francois Dallegret",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Oskar Dalvit",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Hubert Dalwood",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Horia Damian",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jorge Damiani",
        "nationality": "Uruguayan",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Suzanne van Damme",
        "nationality": "Belgian",
        "gender": "Female",
        "startYear": 1901,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Ken Danby",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Bill Dane",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sergio Dangelo",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harvey Daniels",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Peter Danko",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joseph Dankowski",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Bob Danner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Danny (Danny Pierce)",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Jerry Dantzic",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Avery Coffey Danziger",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Louis Danziger",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nassos Daphnis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Hanne Darboven",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 2009,
        "count": 13
    },
    {
        "name": "Aurino Valenca Lins Darel",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sandu Darie",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1991,
        "count": 1
    },
    {
        "name": "Judy Dater",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bruce Davidson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alan Davie",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "John A. Davies",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Brad Davis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Emma Lu Davis",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1905,
        "endYear": 1964,
        "count": 0
    },
    {
        "name": "Faurest Davis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Gene Davis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1985,
        "count": 3
    },
    {
        "name": "James Edward Davis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1974,
        "count": 0
    },
    {
        "name": "Lynn Davis",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Davis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ron Davis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Adrien Dax",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "John Day",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Lucien Day",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Worden Day",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1912,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Daze (Chris Ellis)",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mario De Biasi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Roy DeCarava",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Gino De Dominicis",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Peter De Francia",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2012,
        "count": 2
    },
    {
        "name": "Rudolph De Harak",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Regina Maria De Luise",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Walter De Maria",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2013,
        "count": 4
    },
    {
        "name": "Jonathan De Pas",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Jérôme De Perlinghi",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "José de Rivera",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Niki de Saint Phalle",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 2002,
        "count": 7
    },
    {
        "name": "Lewis deSoto",
        "nationality": "Native American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nicolas de Staël",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1955,
        "count": 0
    },
    {
        "name": "Marco De Valdivia",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "A. R. de Ycaza",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1915,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Deacon",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Joe Deal",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Percy Deane",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1994,
        "count": 1
    },
    {
        "name": "Howard Dearstyne",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Riko Debenjak",
        "nationality": "Slovenian",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1987,
        "count": 0
    },
    {
        "name": "Robert J. Debrey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Dechar",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dorothy Dehner",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1901,
        "endYear": 1994,
        "count": 2
    },
    {
        "name": "Ernesto Deira",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 1986,
        "count": 1
    },
    {
        "name": "Ad Dekkers",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 1974,
        "count": 0
    },
    {
        "name": "Lucio Del Pezzo",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eduardo Del Valle",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jack Delano",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "George Delany",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tony DeLap",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jorge Demirjian",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Agnes Denes",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Robyn Denny",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Co Derr",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Gérard Deschamps",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Arthur Deshaies",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Gianni Dessi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "David Deutsch",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Edward Devine",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean Dewasne",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Jean Deyrolle",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1967,
        "count": 0
    },
    {
        "name": "Roberto Diago",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1955,
        "count": 1
    },
    {
        "name": "Martha Diamond",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stuart Diamond",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "David Diao",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Antonio Dias",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 2018,
        "count": 2
    },
    {
        "name": "Waldo Díaz-Balart",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jan Dibbets",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Jane Dickson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Diebenkorn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1993,
        "count": 4
    },
    {
        "name": "Alex Walter Diggelmann",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1987,
        "count": 0
    },
    {
        "name": "Geurt van Dijk",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Guy Dill",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Laddie John Dill",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Burgoyne Diller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1965,
        "count": 2
    },
    {
        "name": "Jim Dine",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 89
    },
    {
        "name": "Rick Dingus",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dinora (Doudtchitsky)",
        "nationality": "Chilean",
        "gender": "Female",
        "startYear": 1914,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Martin Disler",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 1996,
        "count": 1
    },
    {
        "name": "Wes Disney",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Juan Carlos Distéfano",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eugenio Dittborn",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "John Divola",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pierre Dmitrienko",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1974,
        "count": 1
    },
    {
        "name": "Jun Dobashi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Alexander Dobkin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1975,
        "count": 1
    },
    {
        "name": "Eugène Dodeigne",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2003,
        "count": 1
    },
    {
        "name": "Betty Dodson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Burhan Dogancay",
        "nationality": "Turkish",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Stevan Dohanos",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Wesley Coe Doherty",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Isami Doi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1965,
        "count": 0
    },
    {
        "name": "Robert Doisneau",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Nikolai Dolgorukov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1980,
        "count": 1
    },
    {
        "name": "Roger Doloy",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Oscar Domínguez",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1957,
        "count": 4
    },
    {
        "name": "Tadeusz Dominik",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ken Domon",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Hisao Domoto",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Don Donaghy",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Anthony Donaldson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Enrico Donati",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 2008,
        "count": 1
    },
    {
        "name": "Seena Donneson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Piero Dorazio",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2005,
        "count": 1
    },
    {
        "name": "Roland Dorcély",
        "nationality": "Haitian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Mary Ann Dorr",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Heitor dos Prazeres",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1966,
        "count": 0
    },
    {
        "name": "Francisco Dosamantes",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Christian Dotremont",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1979,
        "count": 1
    },
    {
        "name": "Saliba Douaihy",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Edward Douglas",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gianni Dova",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Dow",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Edward Dowell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Rackstraw Downes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Juan Downey",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 1993,
        "count": 4
    },
    {
        "name": "Joe Downing",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Olin Dows",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Robert Doxat",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2004,
        "count": 1
    },
    {
        "name": "Tom Drahos",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roy R. Drasites",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Henry Dreyfuss",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1972,
        "count": 0
    },
    {
        "name": "Felix Droese",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Achilles Droungas",
        "nationality": "Greek",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sydney Drum",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sally Hazelet Drummond",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Aleksandr Vasilevich Druzhkov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Russell Drysdale",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Doug Dubois",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean Dubuffet",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1985,
        "count": 189
    },
    {
        "name": "Gérard Duchène",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Espinoza Duenas",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dana Duff",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "John Duff",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gert Dumbar",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Douglas Duncan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Carroll Dunham",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 18
    },
    {
        "name": "Bob Duran",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2005,
        "count": 1
    },
    {
        "name": "Ludvik Durchanek",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Stephen Durkee",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hansi Durlach",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alberto Dutary",
        "nationality": "Panamanian",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 1998,
        "count": 2
    },
    {
        "name": "Allen A. Dutton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Suzanne R. Dworsky",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carlus Dyer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Mario Dyyon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Dušan Džamonja",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2009,
        "count": 1
    },
    {
        "name": "Arnold Eagle",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Charles Eames",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Ray Eames",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1912,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "José Echavé",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Christian Eckart",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Eckersley",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Don Eddy",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Doug Edge",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harold Eugene Edgerton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Leonard Edmonson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Melvin Edwards",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Eberhard Eggers",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "William Eggleston",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Otto Eglau",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Shyu Eguchi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marli Ehrman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1904,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Fritz Eichenberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Hans Eichenberger",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hermann Eidenbenz",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Jorge Eielson",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Horst Eigen",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hans Eijkelboom",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lette Eisenhauer",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chester Eisenhuth",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Jacob El Hanani",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Dick Elffers",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Elise (Elise Seeds Armitage)",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1902,
        "endYear": 1963,
        "count": 0
    },
    {
        "name": "Eliot Elisofon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1973,
        "count": 1
    },
    {
        "name": "Ronnie Elliott",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1982,
        "count": 1
    },
    {
        "name": "Kosso Eloul",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Rita Eloul Letendre",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Herman Emmet",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "Jules Engel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Morris Engel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Michael Engelmann",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 1966,
        "count": 0
    },
    {
        "name": "Pieter Engels",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael English",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Mitch Epstein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephan Erfurt",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Boris Nicolaevich Ermolaev",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Hans Erni",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Jimmy Ernst",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Erol (Erol Akyavas)",
        "nationality": "Turkish",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Erró (Gudmundur Gudmundsson)",
        "nationality": "Icelandic",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elliott Erwitt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jesús Escobedo",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "John K. Esler",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bud Esry",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Reed Estabrook",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Estes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roberto Estopinán",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2015,
        "count": 2
    },
    {
        "name": "Sorel Etrog",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Karen Eubel",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dennis Evans",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Garth Evans",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Walker Evans",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Philip Evergood",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1973,
        "count": 1
    },
    {
        "name": "Connor Everts",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2016,
        "count": 1
    },
    {
        "name": "Juanita (Jean) Exley",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hans Fabigan",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Luciano Fabro",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Charles Fahlen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 2010,
        "count": 1
    },
    {
        "name": "Öyvind Fahlström",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 1976,
        "count": 6
    },
    {
        "name": "Ekkehard Fahr",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Patrick Faigenbaum",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Claire Falkenstein",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1908,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "James (Arthur) Fallon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wojciech Fangor",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Nathan Farb",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William Fares",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomaz Farkas",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Francisco Farreras",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Walter Farynk",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Dwain Faubion",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Nicolas Faure",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Louis Faurer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Arthur Fauser",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Philippe Favier",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Fawcett",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Carlos Faz Caimus",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 1953,
        "count": 0
    },
    {
        "name": "Pericle Fazzini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1987,
        "count": 0
    },
    {
        "name": "Helmut Federle",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Paul Feeley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1966,
        "count": 1
    },
    {
        "name": "Philip Fein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Idelle L. Feinberg",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1932,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Ed Feingersh",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1961,
        "count": 0
    },
    {
        "name": "Andreas Feininger",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Theodore Lux Feininger",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Harold Feinstein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Rochelle Feinstein",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nathan I. Felde",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eugene Feldman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Walter Feldman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark Feldstein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sandi Fellman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Frances Felten",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1905,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Jonas J. Fendell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Albert Fenn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Herbert Ferber",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1991,
        "count": 14
    },
    {
        "name": "Gerald Ferguson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Agustín Fernández",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2006,
        "count": 4
    },
    {
        "name": "Justino Fernández",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1972,
        "count": 0
    },
    {
        "name": "Jackie Ferrara",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Jorge Ferrari Hardoy",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1977,
        "count": 0
    },
    {
        "name": "León Ferrari",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2013,
        "count": 15
    },
    {
        "name": "John Ferren",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1970,
        "count": 0
    },
    {
        "name": "Rafael Ferrer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roger C. Ferri",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 1991,
        "count": 1
    },
    {
        "name": "Helene Fesenmaier",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 2013,
        "count": 1
    },
    {
        "name": "William Fett",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2006,
        "count": 2
    },
    {
        "name": "Rainer Fetting",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jed Robert Fielding",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Filliou",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1987,
        "count": 10
    },
    {
        "name": "Albert M. Fine",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 1987,
        "count": 7
    },
    {
        "name": "Harvey J. Finison",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Aaron Fink",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Larry Fink",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ian Hamilton Finlay",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Howard Finster",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "M. Fiorini",
        "nationality": "Algerian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Vittorio Fiorucci",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lothar Fischer",
        "nationality": "Nationality unknown",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "R. M. Fischer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fritz Fischer-Nosbisch",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hella Fischer-Thorer",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1928,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Eric Fischl",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Hans Fischli",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Peter Fischli",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Janet Fish",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vernon Fisher",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dean L. Fisher",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joel Fisher",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stanley Fisher",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Salvatore Fiume",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Audrey Flack",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barry Flanagan",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Dan Flavin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 1996,
        "count": 13
    },
    {
        "name": "Karl Anton Fleck",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 1983,
        "count": 1
    },
    {
        "name": "Alan Fletcher",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Albert (Albert Mentzel) Flocon",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1946,
        "count": 0
    },
    {
        "name": "Eduard Flor",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Florschuetz",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard A. Florsheim",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Johannes Foersom",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean Follett",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1917,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Jean-Michel Folon",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Danny Ho Fong",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Rubén Fontana",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joan Fontcuberta",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ernesto Fontecilla",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Günther Förg",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 2013,
        "count": 39
    },
    {
        "name": "Pere Formiguera",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leander Fornas",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Raquel Forner",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1902,
        "endYear": 1988,
        "count": 1
    },
    {
        "name": "James Forsberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1991,
        "count": 1
    },
    {
        "name": "Jan Forsberg",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Karl Fortess",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Michel Fortier",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christian Fossier",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Steven D. Foster",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "André Fougeron",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1998,
        "count": 1
    },
    {
        "name": "Llyn Foulkes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sheila Fournier",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Richard Alan Fox",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Terry Fox",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 2008,
        "count": 1
    },
    {
        "name": "Hollis Frampton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Sam Francis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1994,
        "count": 1
    },
    {
        "name": "Richard Francisco",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Kaj Franck",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Martine Franck",
        "nationality": "Belgian",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Huguette Franco",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mary Frank",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Frank",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Helen Frankenthaler",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1928,
        "endYear": 2011,
        "count": 7
    },
    {
        "name": "Antonio Frasconi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2013,
        "count": 1
    },
    {
        "name": "Gianfranco Frattini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Charles Ray Frazier",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Karl Free",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1947,
        "count": 26
    },
    {
        "name": "Arthur Freed",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Freed",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Freeman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Mark Freeman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jane Freilicher",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1924,
        "endYear": 2014,
        "count": 2
    },
    {
        "name": "Jared French",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1988,
        "count": 23
    },
    {
        "name": "Leonard French",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ray H. French",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Lucian Freud",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2011,
        "count": 7
    },
    {
        "name": "David Freund",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Freudenthal",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mary E. Frey",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Fried",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Elias Friedensohn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Johnny Friedlaender",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Lee Friedlander",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Benno Friedman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dan Friedman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julius K. Friedman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ken Friedman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 15
    },
    {
        "name": "Robert N. Friedman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Susan Friedman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hartmut Friedrich",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Elisabeth Frink",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 1993,
        "count": 2
    },
    {
        "name": "Katharina Fritsch",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "A. G. Fronzoni",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Stephen Frykholm",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ernst Fuchs",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Masahisa Fukase",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Fukita Fumiaki",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shigeo Fukuda",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Fukui Ryonosuko",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Emily Fuller",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Sue Fuller",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1914,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Hamish Fulton",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Barbara E. Fumagalli",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eugen Funk",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Adam Fuss",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Simon Fussell",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Johannes Gachnang",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Wolfgang Gäfgen",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Gage",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Oliver Gagliani",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vasudeo S. Gaitonde",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Jacek Gaj",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Galo Galecio",
        "nationality": "Ecuadorian",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Frank Gallo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giuseppe Gallo",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Christina Galvez",
        "nationality": "Peruvian",
        "gender": "Female",
        "startYear": 1918,
        "endYear": 1982,
        "count": 1
    },
    {
        "name": "José Gamarra",
        "nationality": "Uruguayan",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Abram Games",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "David Gammon",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Claude Garache",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Domingo Garcia",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Héctor García",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Lyn Gardiner",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Flor Garduño",
        "nationality": "Mexican",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jedd Garet",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William A. Garnett",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Gerard Garouste",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paolo Gasparini",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Oronzo Gasparo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1969,
        "count": 1
    },
    {
        "name": "Arnold Gassan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Marco Gastini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lee Gatch",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1968,
        "count": 0
    },
    {
        "name": "Piero Gatti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yves Gaucher",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Winfred Gaul",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Johannes Gecelli",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2011,
        "count": 1
    },
    {
        "name": "Sonia Gechtoff",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1926,
        "endYear": 2018,
        "count": 3
    },
    {
        "name": "William Gedney",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Peter Gee",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Gego (Gertrud Goldschmidt)",
        "nationality": "Venezuelan",
        "gender": "Female",
        "startYear": 1912,
        "endYear": 1994,
        "count": 12
    },
    {
        "name": "Frank O. Gehry",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ronald R. Geibert",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anna Bella Geiger",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rupprecht Geiger",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 2009,
        "count": 1
    },
    {
        "name": "Jan Gelb",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1906,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Juan Genovés",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Vic Gentils",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "George Passmore",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Thomas George",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2009,
        "count": 1
    },
    {
        "name": "Paul Georges",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2002,
        "count": 1
    },
    {
        "name": "Valery Gerlovin",
        "nationality": "Russian",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rimma Gerlovina",
        "nationality": "Russian",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ad Gerritsen",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Moshe Gershuni",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2017,
        "count": 1
    },
    {
        "name": "Karl Gerstner",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Franz Gertsch",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mario Giacomelli",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Alberto Giacometti",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1966,
        "count": 16
    },
    {
        "name": "Cristos Gianakos",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Umberto Giangrandi",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ralph Gibson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tim Gidal",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Jill Giegerich",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Josef Gielniak",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 1972,
        "count": 0
    },
    {
        "name": "Roland Giguère",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Ruth Gikow",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1915,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "James Francis Gill",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Gregory Gillespie",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sam Gilliam",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2022,
        "count": 2
    },
    {
        "name": "Françoise Gilot",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edgardo Miguel Gimenez",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Henri Ginet",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1970,
        "count": 0
    },
    {
        "name": "Allen Ginsberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Roy Ginstrom",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1977,
        "count": 0
    },
    {
        "name": "Paolo Gioli",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 2022,
        "count": 0
    },
    {
        "name": "John Giorno",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Philip Gips",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alberto Gironella",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Léon Gischia",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harry Gitlin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Len Gittleman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Allan Gittler (Avraham Bar Rashi)",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Joseph Glasco",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1996,
        "count": 2
    },
    {
        "name": "Milton Glaser",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2020,
        "count": 1
    },
    {
        "name": "Arnold Glimcher",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Domenico Gnoli",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 1970,
        "count": 2
    },
    {
        "name": "Robert Gober",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 12
    },
    {
        "name": "Keith Godard",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles L. Goeller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1955,
        "count": 1
    },
    {
        "name": "Mathias Goeritz",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1990,
        "count": 2
    },
    {
        "name": "Henri Goetz",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Giuseppe de Goetzen",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Frank Gohlke",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Lance Goines",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ralph Goings",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Jim Goldberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Goldberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2007,
        "count": 4
    },
    {
        "name": "David Goldblatt",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Raphael Goldchain",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shirley Goldfarb",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1925,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "John Golding",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2012,
        "count": 1
    },
    {
        "name": "Morton Goldsholl",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Lloyd Goldsmith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Leon Golub",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2004,
        "count": 2
    },
    {
        "name": "Mirta Gómez",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Juan Gómez-Quiroz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alexander Gonda",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1977,
        "count": 1
    },
    {
        "name": "Leonel Gongora",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roberto González Goyri",
        "nationality": "Guatemalan",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Sergio Gonzalez-Tornero",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Felix Gonzalez-Torres",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 1996,
        "count": 1
    },
    {
        "name": "Gerald Gooch",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joe Goode",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jon Goodman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark Goodman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sidney Goodman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2013,
        "count": 1
    },
    {
        "name": "Sam Goodman",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1967,
        "count": 0
    },
    {
        "name": "Robert Goodnough",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 2010,
        "count": 1
    },
    {
        "name": "John Goodyear",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Ron Gorchov",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Bonnie Gordon",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Gordon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2022,
        "count": 9
    },
    {
        "name": "Wiktor Górka",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Arshile Gorky",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1948,
        "count": 9
    },
    {
        "name": "Tom Gormley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "April Gornik",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fritz Goro",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Goro Ike",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mieczysław Górowski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Eberhard Göschel",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Gossage",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zbigniew Gostomski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joseph Goto",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Adolph Gottlieb",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1974,
        "count": 1
    },
    {
        "name": "Robert P. Gottlieb",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1977,
        "count": 0
    },
    {
        "name": "Jacques-Enguérrand Gourgue",
        "nationality": "Haitian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1996,
        "count": 1
    },
    {
        "name": "Emmet Gowin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roy Grace",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Werner Graeff",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1978,
        "count": 1
    },
    {
        "name": "Carl Bernhard Graf",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1968,
        "count": 0
    },
    {
        "name": "Dan Graham",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 2022,
        "count": 9
    },
    {
        "name": "David Marshall Graham",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Graham",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Graham",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Carlos Granada",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Toni Grand",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Eugene Fernandez Granell",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 2001,
        "count": 1
    },
    {
        "name": "Martus Granirer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "James Bruce Grashow",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gotthard Graubner",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Ken Graves",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Graves",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2015,
        "count": 14
    },
    {
        "name": "Morris Graves",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 2001,
        "count": 17
    },
    {
        "name": "Nancy Graves",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 1995,
        "count": 4
    },
    {
        "name": "Wayne Gravning",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cleve Gray",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Larry Gray",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Ed Grazda",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sante Graziani",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alan Green",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Randall Green",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Balcomb Greene",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Gertrude Greene",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1904,
        "endYear": 1956,
        "count": 0
    },
    {
        "name": "Stephen Greene",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 1999,
        "count": 2
    },
    {
        "name": "Timothy Greenfield-Sanders",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "B. Colin Greenly",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Caroline Greenwald",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vittorio Gregotti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Farrell Grehan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "April Greiman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Gretczko",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ezio Gribaudo",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2022,
        "count": 0
    },
    {
        "name": "Helmut A. P. Grieshaber",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Fuller Griffith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Franco Grignani",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Marcos Grigorian",
        "nationality": "Iranian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "William Grigsby",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Grillo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Frieder Grindler",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Grippe",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Salvatore Grippi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Laura Grisi",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 2017,
        "count": 10
    },
    {
        "name": "Nikolai Gritsouk",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1976,
        "count": 1
    },
    {
        "name": "René Groebli",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Glenn Grohe",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1956,
        "count": 0
    },
    {
        "name": "Red Grooms",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Jan Groover",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Anthony Gross",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Arnold Gross",
        "nationality": "Hungarian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Chaim Gross",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1991,
        "count": 1
    },
    {
        "name": "Michael Gross",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Richard Gross",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Maurice Grosser",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Sid Grossman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1955,
        "count": 0
    },
    {
        "name": "Robert Grosvenor",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Jan Groth",
        "nationality": "Norwegian",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 9
    },
    {
        "name": "Paul Grotz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Bedrich Grunzweig",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Carlo Guarienti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nancy Guay Giesberger",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Oswaldo Guayasamín",
        "nationality": "Ecuadorian",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1999,
        "count": 1
    },
    {
        "name": "Klaas Gubbels",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wayne Gudmundson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Svavar Gudnason",
        "nationality": "Icelandic",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1988,
        "count": 1
    },
    {
        "name": "Jesús Guerrero Galvan",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1973,
        "count": 2
    },
    {
        "name": "José Guerrero",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1991,
        "count": 1
    },
    {
        "name": "Giuseppe Guerreschi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Hans Gugelot",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1965,
        "count": 0
    },
    {
        "name": "Michel Guino",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Paolo Guiotto",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Satish Gujral",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Philip Guston",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1980,
        "count": 53
    },
    {
        "name": "John Gutmann",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Renato Guttuso",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1987,
        "count": 2
    },
    {
        "name": "Charles Gwathmey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Robert Gwathmey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1988,
        "count": 1
    },
    {
        "name": "Brion Gysin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1986,
        "count": 5
    },
    {
        "name": "Hans Haacke",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kenneth D. Haak",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ernst Haas",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Hildegarde Haas",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1926,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Richard Haas",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Terry Haass",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1923,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Sandi Haber Fifield",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Reinhold Häcker",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Dimitri Hadzi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Ronald L. Haeberle",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Günter Haese",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Hafftka",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hideo Haga",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Otto Hagel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1974,
        "count": 0
    },
    {
        "name": "Charles Hagen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hideoki Hagiwara",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hagiwara Hideo",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Raoul Hague",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Betty Hahn",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Raymond Hains",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Étienne Hajdu",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Otto Herbert Hajek",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2005,
        "count": 1
    },
    {
        "name": "Peter Hajnoczky",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bob Hall",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nigel Hall",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Peter Halley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Gary L. Hallman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Philippe Halsman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Chimei Hamada",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Yozo Hamaguchi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Katsunori Hamanishi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hiroshi Hamaya",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Jörg Hamburger",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Hamburger",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Hamilton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Hamilton",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2011,
        "count": 11
    },
    {
        "name": "Hans Hammarskiold",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Sid Hammer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1969,
        "count": 0
    },
    {
        "name": "David Hammons",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Abraham P. Hankins",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1963,
        "count": 0
    },
    {
        "name": "Aage Helbig Hansen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Hansen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Bill Hanson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David T. Hanson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Simon Hantaï",
        "nationality": "Hungarian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Michio Hanyu",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles Harbutt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Marvin Harden",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "John Harding",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chauncey Hare",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "David Hare",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Keith Haring",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 1990,
        "count": 1
    },
    {
        "name": "Guy Harloff",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Irving Harper",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Richard Harrington",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Alexander E. Harris",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Harris",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Claudia Hart",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Grace Hartigan",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1922,
        "endYear": 2008,
        "count": 3
    },
    {
        "name": "Cedric Hartman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Erich Hartmann",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Hans Hartung",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1989,
        "count": 1
    },
    {
        "name": "Hasegawa Sabro",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1957,
        "count": 0
    },
    {
        "name": "Jan Hashey",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Rafael Hastings",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Makio Hasuike",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pierre Haubensak",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Declan Haun",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Suzanne Hausammann",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Johann Hauser",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Ashley Havinden",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1973,
        "count": 0
    },
    {
        "name": "Markus Hawlik",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kirsten Hawthorne",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alex Hay",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Yoshio Hayakawa",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David V. Hayes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Stanley William Hayter",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Howard Head",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Dennis Hearne",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dave Heath",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Edith Heath",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1911,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Milton Elting Hebald",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Caroline Hebbe-Hammarskiold",
        "nationality": "Swedish",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wally Hedrick",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Jon Hedu",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Willy Heeks",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Henry Heerup",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Maria Hees",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bernhard Heiliger",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1995,
        "count": 1
    },
    {
        "name": "Robert Heinecken",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Michael Heizer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "John Hejduk",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Al Held",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2005,
        "count": 5
    },
    {
        "name": "Riva Helfond",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1910,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Jean Hélion",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1987,
        "count": 3
    },
    {
        "name": "Nancy Hellebrand",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Geoffrey Hendricks",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2018,
        "count": 4
    },
    {
        "name": "Fritz Henle",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Jan Henle",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Frederick H. K. Henrion",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Barbara Hepworth",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1903,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Erik Herløw",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Anthony Hernandez",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joan Hernández Pijuan",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Helena Hernmarck",
        "nationality": "Swedish",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Georg Herold",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jacques Hérold",
        "nationality": "Romanian",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1991,
        "count": 1
    },
    {
        "name": "Marcia Herscovitz",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eva Hesse",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1936,
        "endYear": 1970,
        "count": 14
    },
    {
        "name": "Raymonde Heudebert",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Anton Heyboer",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ken Heyman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Antonin Heythum",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1954,
        "count": 1
    },
    {
        "name": "Sheila Hicks",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nankoku Hidai",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1999,
        "count": 5
    },
    {
        "name": "Kenneth Hiebert",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Higa Yoshiharu",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chester Higgins Jr.",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dick Higgins",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 1998,
        "count": 14
    },
    {
        "name": "Edward Higgins",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ernest C. Higgins",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jene Highstein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 2013,
        "count": 2
    },
    {
        "name": "Rowland Hilder",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles Christopher Hill",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Clinton Hill",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tristram Hillier",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Hans Hillmann",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fannie Hillsmith",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1911,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Hans Himbert",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harvey Himelfarb",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles Hinman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Kit Hinrichs",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hans Hinterreiter",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1989,
        "count": 1
    },
    {
        "name": "Randal Richard Hinz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Philippe Hiquily",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Key Hiraga",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Joseph Hirsch",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Milton Hirschl",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Terry Hirst",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Franz Hitzler",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Erik Hjorth",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Rick McKee Hock",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Antonius Höckelman",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2000,
        "count": 2
    },
    {
        "name": "David Hockney",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 59
    },
    {
        "name": "Howard Hodgkin",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Beth Van Hoesen",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1926,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Paul van Hoeydonck",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles Hoff",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1970,
        "count": 0
    },
    {
        "name": "Arlene Hoffman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bernard Hoffman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Lee Hoffman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Phillip W. Hoffman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alfred Hofkunst",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Rudolf Hoflehner",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1995,
        "count": 2
    },
    {
        "name": "Armin Hofmann",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christopher Martin Hofstetter",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Steven Holl",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tom Holland",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Hans Hollein",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Gordon Holler",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Holmgren",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pieter Holstein",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jenny Holzer",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Friedl Holzer-Kjellberg",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "David Holzman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Lorenzo Homar",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Gottfried Honegger",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 2016,
        "count": 1
    },
    {
        "name": "Göran Hongell",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1973,
        "count": 0
    },
    {
        "name": "Ethelyn Honig",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dorothy Hood",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1918,
        "endYear": 2000,
        "count": 1
    },
    {
        "name": "John Hoover",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Saara Hopea",
        "nationality": "Finnish",
        "gender": "Female",
        "startYear": 1925,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Barbara Horosko",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Bruce Horowitz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eikoh Hosoe",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Coy Howard",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Howson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Hoyland",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Alfred Hrdlicka",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Max Huber",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Robert Hudson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Douglas Huebler",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1997,
        "count": 10
    },
    {
        "name": "Ralph Hughes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Toni Hughes",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1907,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Georges Hugnet",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1974,
        "count": 0
    },
    {
        "name": "John Hultberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Sandy Hume",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ralph Humphrey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 1990,
        "count": 2
    },
    {
        "name": "Hundertwasser (Friedrich Stowasser)",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2000,
        "count": 1
    },
    {
        "name": "Bryan Hunt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Richard Hunt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "John Hunter",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Huot",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dudley Huppler",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1988,
        "count": 1
    },
    {
        "name": "Michael Hurson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Luchita Hurtado",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1920,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Gene Hurwitt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Terry Husebye",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Hutchinson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Paul Huxley",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Garth Huxtable",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Kyu-Baik Hwang",
        "nationality": "Korean",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lisa Hyatt",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Philip Hyde",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Scott Hyde",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Tetsuya Ichimura",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Ickovic",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shoichi Ida",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Ide Sakiko",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Takenobu Igarashi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yoshiaki Iida",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ikeda Fumio (pen Name: Suijo)",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Masuo Ikeda",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 1997,
        "count": 2
    },
    {
        "name": "Ikko (Ikko Narahara)",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Hisae Imai",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Toshimitsu Imai",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Birney Imes III",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jörg Immendorff",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 2007,
        "count": 21
    },
    {
        "name": "Akira Inada",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Inagaki Tomoo",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "Gérald Incandela",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Indiana",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2018,
        "count": 1
    },
    {
        "name": "Anthony Ingolia",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Ingram",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Mark Innerst",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Genichiro Inokuma",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Will Insley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2011,
        "count": 4
    },
    {
        "name": "Eugène Ionesco",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Jean Ipoustéguy",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2006,
        "count": 1
    },
    {
        "name": "Angelo Ippolito",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2001,
        "count": 1
    },
    {
        "name": "David Ireland",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard W. Ireland",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Carlos Irizarry",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Marcos Irizarry",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Robert Irwin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rolf Iseli",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sheila Isham",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yasuhiro Ishimoto",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Eiko Ishioka",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Yukihisa Isobe",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Arata Isozaki",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marvin Israel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1984,
        "count": 1
    },
    {
        "name": "Robert Israel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 38
    },
    {
        "name": "Junichi Itahashi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kenji Itoh",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Graciela Iturbide",
        "nationality": "Mexican",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Olja Ivanjicki",
        "nationality": "Serbian",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 2009,
        "count": 1
    },
    {
        "name": "Earl Iversen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Norman Ives",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Koji Iwagami",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Reika Iwami",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Takeji Iwamiya",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Yoshiyuki Iwase",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Iyola",
        "nationality": "Canadian Inuit",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Izis",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "Shigeru Izumi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Christian Jaccard",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joseph Jachna",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Bill Jacklin",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Robert Jacks",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gerald Jackson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Martin Jackson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Oliver Jackson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Jacob",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Aldo Jacober",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Arne Jacobsen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Alain Jacquet",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Yvonne Jacquette",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1934,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "N. Jay Jaffee",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Shirley Jaffe",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1923,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Christopher P. James",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tony Jannetti",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Angela Jansen",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Horst Janssen",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 1995,
        "count": 2
    },
    {
        "name": "Rudolph Janu",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richards Jarden",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Valerie Jaudon",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jeff (Jeff Russell)",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Werner Jeker",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Jenkins",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Thomas Jenkins",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Neil Jenney",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alfred Jensen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1981,
        "count": 5
    },
    {
        "name": "Bill Jensen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Jacob Jensen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Jess",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2004,
        "count": 2
    },
    {
        "name": "Luis Jimenez",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Max Jiménez",
        "nationality": "Costa Rican",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1949,
        "count": 0
    },
    {
        "name": "Edith Jimenez",
        "nationality": "Paraguayan",
        "gender": "Female",
        "startYear": 1918,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Patricia Johanson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Jiří John",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1972,
        "count": 0
    },
    {
        "name": "Jasper Johns",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 20
    },
    {
        "name": "Daniel LaRue Johnson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Lester F. Johnson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Philip Johnson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Ray Johnson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1995,
        "count": 22
    },
    {
        "name": "Ynez Johnston",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1920,
        "endYear": 2019,
        "count": 1
    },
    {
        "name": "Joan Jonas",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1936,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Allen Jones",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "E. Powis Jones",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harold Jones",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joe Jones",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 1993,
        "count": 10
    },
    {
        "name": "John Paul Jones",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1999,
        "count": 2
    },
    {
        "name": "Pirkle Jones",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Sven Jonson",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1981,
        "count": 1
    },
    {
        "name": "Asger Jorn",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1973,
        "count": 2
    },
    {
        "name": "Ken Josephson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bernard Joubert",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Carl Jakob Jucker",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Donald Judd",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 1994,
        "count": 11
    },
    {
        "name": "Finn Juhl",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Sven-Eric Juhlin",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mervin Jules",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Bishin Jumonji",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dora Jung",
        "nationality": "Finnish",
        "gender": "Female",
        "startYear": 1906,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "Theodor Jung",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Joan Junyer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1994,
        "count": 3
    },
    {
        "name": "Robert Kabak",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Reuben Kadish",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Menashe Kadishman",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Frida Kahlo",
        "nationality": "Mexican",
        "gender": "Female",
        "startYear": 1907,
        "endYear": 1954,
        "count": 0
    },
    {
        "name": "Louis I. Kahn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1974,
        "count": 0
    },
    {
        "name": "Max Kahn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Richard Kahn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wolf Kahn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2020,
        "count": 2
    },
    {
        "name": "Jacob Kainen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Viaceslav Kalinin",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Horst-Egon Kalinowski",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2013,
        "count": 1
    },
    {
        "name": "Simpson Kalisher",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Kalvar",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yusaku Kamekura",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Takahisa Kamijyo",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gerome Kamrowski",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2004,
        "count": 1
    },
    {
        "name": "Kanamori Yoshio",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bob Kane",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Matsumi Kanemitsu",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1992,
        "count": 6
    },
    {
        "name": "Mitsuo Kano",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tim Kantor",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anatoli Lvovich Kaplan",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "Jerome Kaplan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peggy Jarrell Kaplan",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anish Kapoor",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 15
    },
    {
        "name": "Susan Kaprov",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Allan Kaprow",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Janusz Kapusta",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Erica Karawina",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1904,
        "endYear": 2003,
        "count": 31
    },
    {
        "name": "Dennis Kardon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wolf Karnagel",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gaston Karquel",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Yousuf Karsh",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Pentti Kaskipuo",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barbara Kasten",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Karl Kasten",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "William Katavolos",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Alex Katz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 15
    },
    {
        "name": "Joel Katz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Herbert Katzman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Craig Kauffman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Donald  Kaufman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Willy Kaufmann",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kikuji Kawada",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "On Kawara",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2014,
        "count": 30
    },
    {
        "name": "Takeshi Kawashima",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kazuko Miyamoto",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jerry Kearns",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kim Keever",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Douglas Kelley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mike Kelley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 2012,
        "count": 14
    },
    {
        "name": "Jean Kellogg",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1910,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Daniel Kelly",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ellsworth Kelly",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2015,
        "count": 1037
    },
    {
        "name": "James Kelly",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leon Kelly",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1982,
        "count": 1
    },
    {
        "name": "Richard Kelly",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1977,
        "count": 0
    },
    {
        "name": "Paul Kelpe",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1985,
        "count": 2
    },
    {
        "name": "Richard Kemble",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Zoltan Kemeny",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1965,
        "count": 0
    },
    {
        "name": "Mel Kendrick",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Isamu Kenmochi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Gene Kennedy",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Colleen Frances Kenyon",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "György Kepes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Juliet Kepes",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1919,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "G. Ray Kerciu",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alan Kessler",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jon Kessler",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Glenn Ketchum",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mar Jean Kettunen",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bhupen P. Khakhar",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Krishen Khanna",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Henry Khudyakov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kiawak Ashoona",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Kidner",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hubert Kiecol",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anselm Kiefer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "William Kienbusch",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1980,
        "count": 1
    },
    {
        "name": "Edward Kienholz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1994,
        "count": 3
    },
    {
        "name": "Günther Kieser",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ken Kiff",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2001,
        "count": 1
    },
    {
        "name": "Mokuma Kikuhata",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Chris Killip",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Kenneth Kilstrom",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Tchah-Sup Kim",
        "nationality": "Korean",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ihei Kimura",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1974,
        "count": 0
    },
    {
        "name": "Kosuke Kimura",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Risaburo Kimura",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "David Kindersley",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bruce Anthony King",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Phillip King",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "Dong Kingman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 2000,
        "count": 1
    },
    {
        "name": "Hazel Kingsbury",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1907,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Jonah Kinigstein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tomio Kinoshita",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Martin Kippenberger",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 1997,
        "count": 40
    },
    {
        "name": "Yasuhiro Kira",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Kirby",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Guenther C. Kirchberger",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alain Kirili",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "Per Kirkeby",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2018,
        "count": 41
    },
    {
        "name": "Toshiyuki Kita",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shozo Kitadai",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "R. B. Kitaj",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2007,
        "count": 3
    },
    {
        "name": "Fumio Kitaoka",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Poul Kjærholm",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "Charles Klabunde",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gabi Klasmer",
        "nationality": "Israeli",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Felix Klee",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1990,
        "count": 1
    },
    {
        "name": "Ron Kleemann",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Allen Klein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Klein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Irwin B. Klein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 1974,
        "count": 0
    },
    {
        "name": "William Klein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2022,
        "count": 0
    },
    {
        "name": "Yves Klein",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 1962,
        "count": 1
    },
    {
        "name": "Richard Kleinberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lea Kleiner",
        "nationality": "Chilean",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vera Klement",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Werner Klemke",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark Klett",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carl Heinz Kliemann",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Franz Kline",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1962,
        "count": 4
    },
    {
        "name": "Stuart Klipper",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gustav Kluge",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stefan Knapp",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Werner Knaupp",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Ernest Knee",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Gwen Knight",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1913,
        "endYear": 2005,
        "count": 1
    },
    {
        "name": "Karla Knight",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Donald R. Knorr",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles Knowles",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 1958,
        "count": 0
    },
    {
        "name": "Christopher Knowles",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 10
    },
    {
        "name": "Jonathan Knowlton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Win Knowlton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jack Knox",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yasuhide Kobashi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Robert Kobayashi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Aleksander Kobzdej",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1972,
        "count": 1
    },
    {
        "name": "Kenneth Koch",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Karl Koehler",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fritz Koenig",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2017,
        "count": 2
    },
    {
        "name": "W. Koenigstein",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Henry Koerner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Oskar Kogoj",
        "nationality": "Slovenian",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gabriel Kohn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Misch Kohn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Nobuaki Kojima",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jirí Kolár",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2002,
        "count": 2
    },
    {
        "name": "Stanislav Kolibal",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Ray Komai",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Komai Tetsuro",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Vitaly Komar",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Makoto Komatsu",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Béla Kondor",
        "nationality": "Hungarian",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 1972,
        "count": 0
    },
    {
        "name": "Kasper König",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elaine de Kooning",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1920,
        "endYear": 1989,
        "count": 8
    },
    {
        "name": "Willem de Kooning",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1997,
        "count": 56
    },
    {
        "name": "Henning Koppel",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Chaim Koppelman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Schlomo Koren",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Mark Kostabi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Claire Wagner Kosterlitz",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1903,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Lewis Kostiner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Takehisa Kosugi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Joseph Kosuth",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Josef Koudelka",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jannis Kounellis",
        "nationality": "Greek",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2017,
        "count": 9
    },
    {
        "name": "Ron Kowalke",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Antoni Kowalski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joyce Kozloff",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kirsten Kraa",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Frans Krajcberg",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "William E. Krallman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Margia Kramer",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lee Krasner",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1908,
        "endYear": 1984,
        "count": 6
    },
    {
        "name": "Dimitri Mikhailovich Krasnopevtsev",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Jovan Kratohvil",
        "nationality": "Serbian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "George Krause",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Doris Kreindler",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1901,
        "endYear": 1974,
        "count": 0
    },
    {
        "name": "Vasyl Krychevsky",
        "nationality": "Ukrainian",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Norbert Kricke",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Leon Krier",
        "nationality": "Luxembourger",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Irving Kriesberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Paul Smith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Les Krims",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harold Krisel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Ferdinand Kriwet",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vilem Kriz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Barron Krody",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Abram Krol",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Louise Kruger",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1924,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Barbara Kruger",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harry van Kruiningen",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nicholas Krushenick",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Michel Szulc Krzyzanowski",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shigeko Kubota",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 2015,
        "count": 2
    },
    {
        "name": "Alena Kučerová",
        "nationality": "Czech",
        "gender": "Female",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tetsumi Kudo",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 1990,
        "count": 1
    },
    {
        "name": "Gary Kuehn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Rudolf Kügler",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Charles Kuhn",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yrjö Kukkapuro",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Kulicke",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Kipton Cornelius Kumler",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Kunz",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Lawrence Kupferman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1982,
        "count": 1
    },
    {
        "name": "Moshe Kupfermann",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2003,
        "count": 3
    },
    {
        "name": "Juan Kurchan",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "William Kurelek",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1977,
        "count": 0
    },
    {
        "name": "Mervyn Kurlansky",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Seitaro Kuroda",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Masayuki Kurokawa",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Helmuth Kurtz",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1959,
        "count": 0
    },
    {
        "name": "Kenji Kusaka",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yayoi Kusama",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 0,
        "count": 12
    },
    {
        "name": "Rainer Küschall",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Kushner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Kwilecki",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean L'Anselme",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Alain de La Bourdonnaye",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Chet La More",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "Terence La Noue",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Frank LaPena",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Syl Labrot",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 1977,
        "count": 0
    },
    {
        "name": "Jacques Lacomblez",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Lacroix",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Justen Ladda",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Adriano Lagostina",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Massimo Lagostina",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wolfgang Laib",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Gerald Laing",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2011,
        "count": 2
    },
    {
        "name": "Jennett Lam",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1911,
        "endYear": 1983,
        "count": 2
    },
    {
        "name": "Wifredo Lam",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1982,
        "count": 7
    },
    {
        "name": "Jacqueline Lamba",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1910,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Roberto De Lamónica",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark Lancaster",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Colin Lanceley",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2015,
        "count": 2
    },
    {
        "name": "Jacob Landau",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 2001,
        "count": 1
    },
    {
        "name": "Nils Landberg",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Armin Landeck",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Ronnie Landfield",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edoardo Landi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bengt Landin",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard W. Landis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Darrell Landrum",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Herman Landshoff",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Stanley Landsman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Octave Landuyt",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Lois Lane",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "David Lang",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Helmut Lang",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Juan Langlois",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "André Lanskoy",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Louis Lanzano",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ernesto Bruno La Padula",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1968,
        "count": 0
    },
    {
        "name": "Harry S. Lapow",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Eugene Larkin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Johannes Larsen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lisa Larsen",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1925,
        "endYear": 1959,
        "count": 0
    },
    {
        "name": "William G. Larson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Mauricio Lasansky",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Pat Lasch",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jonathan Lasker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Ibram Lassaw",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Charles Lassiter",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Maria Lassnig",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1919,
        "endYear": 2014,
        "count": 2
    },
    {
        "name": "Ger Lataster",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "John Latham",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Clarence John Laughlin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Uwe Lausen",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 1970,
        "count": 0
    },
    {
        "name": "Ray Lauzzana",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Warja Lavater",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1913,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Jacob Lawrence",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 2000,
        "count": 3
    },
    {
        "name": "Michael Lax",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Elizabeth \"Grandma\" Layton",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1909,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Gustavo Lazarini Terradas",
        "nationality": "Uruguayan",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 1987,
        "count": 2
    },
    {
        "name": "Giulio Lazzotti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean Le Gac",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean Le Moal",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Julio Le Parc",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barry Le Va",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 2021,
        "count": 15
    },
    {
        "name": "David Leach",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alun Leach-Jones",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "June Leaf",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jean-Jacques Lebel",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jan Lebenstein",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1999,
        "count": 4
    },
    {
        "name": "Dick Lebowitz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christopher Le Brun",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Juana Lecaros",
        "nationality": "Chilean",
        "gender": "Female",
        "startYear": 1920,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Baldwin S. Lee",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joong Seop Lee",
        "nationality": "Korean",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1956,
        "count": 3
    },
    {
        "name": "Catherine Lee",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Colin Lee",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Russell Lee",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Tom Lee",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1971,
        "count": 20
    },
    {
        "name": "Ung-no Lee",
        "nationality": "Korean",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1989,
        "count": 3
    },
    {
        "name": "Theyre Lee-Elliott",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Yoram Lehman",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leonard Lehrer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Arthur Leipzig",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Saul Leiter",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Michael Lekakis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1987,
        "count": 0
    },
    {
        "name": "Paul Lemagny",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Annette Lemieux",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stanley Lench",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2000,
        "count": 1
    },
    {
        "name": "Jan Lenica",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Thomas Lenk",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Candice Lenney",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Lennon",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "Louis L. Lepoix",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Lepper",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1991,
        "count": 1
    },
    {
        "name": "Stanislao Lepri",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "Gustav Lepschy",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Norman Lerner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Danuta Leszczynska-Kluza",
        "nationality": "Polish",
        "gender": "Female",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alfred Leslie",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Janusz Lesniak",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Lesnick",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gilbert Lesser",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julian Lethbridge",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anna Letycia Quadros",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gerd Leufert",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1998,
        "count": 6
    },
    {
        "name": "Herbert Leupin",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "John Levee",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2017,
        "count": 1
    },
    {
        "name": "Hans Levi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Josef Levi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Levin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wayne Levin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jack Levine",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Les Levine",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sherrie Levine",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 56
    },
    {
        "name": "Tom Levine",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 2020,
        "count": 6
    },
    {
        "name": "Mon Levinson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Leon Levinstein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Helen Levitt",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1913,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Julien Levy",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Norman Lewis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1979,
        "count": 1
    },
    {
        "name": "Sol LeWitt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2007,
        "count": 48
    },
    {
        "name": "Jay Leyda",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Alexander Liberman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1999,
        "count": 1
    },
    {
        "name": "Sol Libsohn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Roy Lichtenstein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1997,
        "count": 26
    },
    {
        "name": "Al Lieberman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Klaus Liebig",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 1996,
        "count": 2
    },
    {
        "name": "Jerome Liebling",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "David Ligare",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Stig Lindberg",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Linda Lindeberg",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1915,
        "endYear": 1973,
        "count": 1
    },
    {
        "name": "Francesca Lindh",
        "nationality": "Finnish",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Lindh",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Richard Lindner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1978,
        "count": 3
    },
    {
        "name": "Linda Lindroth",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "O. Winston Link",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Leo Lionni",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Peter Lipman-Wulf",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Lippold",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 2002,
        "count": 21
    },
    {
        "name": "Seymour Lipton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1986,
        "count": 20
    },
    {
        "name": "Vera Lisková",
        "nationality": "Czech",
        "gender": "Female",
        "startYear": 1924,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Ross Littell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Harvey Littleton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ludwig Littmann",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephen Livick",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Frank Lobdell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean Locey",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George Lockwood",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 1969,
        "count": 0
    },
    {
        "name": "Uwe Loesch",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Paul Lohse",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Lois Long",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1918,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Long",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 15
    },
    {
        "name": "Robert Longo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Vincent Longo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Fernando López Anaya",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1987,
        "count": 0
    },
    {
        "name": "Domingo López",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luis López Loza",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Antonio López García",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2021,
        "count": 1
    },
    {
        "name": "Erle Loran",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "John Loring",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Reagan Louie",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Morris Louis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1962,
        "count": 1
    },
    {
        "name": "Jim Love",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Vreni Löwensberg",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1912,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Lee Lozano",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 1999,
        "count": 14
    },
    {
        "name": "Dietrich Lubs",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lucebert",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1994,
        "count": 2
    },
    {
        "name": "Thomas Lüchinger",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Lueders-Booth",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Konrad Lueg",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Jan Lukas",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sven Lukin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Norman Lundin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Evert Lundquist",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Francois Lunven",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Markus Lüpertz",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 23
    },
    {
        "name": "Boris Lurie",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Urs Lüthi",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nicholas Luttinger",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George Platt Lynes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1955,
        "count": 0
    },
    {
        "name": "George Lynn",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Danny Lyon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joan Lyons",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nathan Lyons",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Richard Lytle",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ernst Mumenthaler",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Dora Maar",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1907,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Mabuchi Toru",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Robert MacBryde",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1966,
        "count": 0
    },
    {
        "name": "Rómulo Macció",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Eduardo A. MacEntyre",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Greg MacGregor",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sheldon Machlin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Saša Janez Mächtig",
        "nationality": "Slovenian",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Loren MacIver",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1909,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Heinz Mack",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Owen F. Maclaren",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Angus MacLise",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Wendy MacNeil",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Alen MacWeeney",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bea Maddock",
        "nationality": "Australian",
        "gender": "Female",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Josaku Maede",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Toshiro Maeda",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Paul Maenz",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Aloisio Lahyre de  Magalhães",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1982,
        "count": 1
    },
    {
        "name": "Victor Magariños",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1993,
        "count": 5
    },
    {
        "name": "Vico Magistretti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Greta Magnusson Grossman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1906,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "William Maguire",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Mahon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Schilli Maier",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1989,
        "count": 1
    },
    {
        "name": "Samuel Maitin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "William Majors",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1982,
        "count": 2
    },
    {
        "name": "Haku Maki",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Mitsuo Maki",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zbigniew Makowski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Gerard Malanga",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Malaval",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 1980,
        "count": 2
    },
    {
        "name": "Robert Mallary",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Antonio Maluf",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Mateo Manaure",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Michael Mandel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alfred Manessier",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1993,
        "count": 1
    },
    {
        "name": "Robert Mangold",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 16
    },
    {
        "name": "Sylvia Plimack Mangold",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Per Maning",
        "nationality": "Norwegian",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sally Mann",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jack Manning",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Constantine Manos",
        "nationality": "Greek",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Russell Manoy",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Mansbridge",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Matthias Mansen",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leo Manso",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1993,
        "count": 2
    },
    {
        "name": "Piero Manzoni",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 1963,
        "count": 1
    },
    {
        "name": "Giacomo Manzù",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Pio Manzù",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 1969,
        "count": 0
    },
    {
        "name": "Kumberai Mapanda",
        "nationality": "Zimbabwean",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Mapplethorpe",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Ralph Maradiaga",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Adriana Jadranka Maraž",
        "nationality": "Slovenian",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Conrad Marca-Relli",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 2000,
        "count": 1
    },
    {
        "name": "André Marchand",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Léo Marchutz",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1976,
        "count": 5
    },
    {
        "name": "Brice Marden",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 47
    },
    {
        "name": "Maréchal",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Boris Margo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Richard M. Margolis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Enzo Mari",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Mariano (Mariano Rodríguez)",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1990,
        "count": 1
    },
    {
        "name": "Marino Marini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1980,
        "count": 6
    },
    {
        "name": "Tom Marioni",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Marisol (Marisol Escobar)",
        "nationality": "Venezuelan",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 2016,
        "count": 3
    },
    {
        "name": "Henry Mark",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ronald Markman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2017,
        "count": 2
    },
    {
        "name": "Gino Marotta",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Anne Steele Marsh",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1901,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Takis Marthas",
        "nationality": "Greek",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1965,
        "count": 1
    },
    {
        "name": "Agnes Martin",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1912,
        "endYear": 2004,
        "count": 10
    },
    {
        "name": "Charles Martin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fletcher Martin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Fred Martin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kenneth Martin",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1984,
        "count": 3
    },
    {
        "name": "Mary Martin",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1907,
        "endYear": 1969,
        "count": 0
    },
    {
        "name": "Philip John Martin",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2014,
        "count": 1
    },
    {
        "name": "Ezio Martinelli",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Eugene Martinez",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luis Martínez Pedro",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1990,
        "count": 2
    },
    {
        "name": "Ricardo Martinez",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Raúl Martínez",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1995,
        "count": 1
    },
    {
        "name": "Aldemir Martins",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2006,
        "count": 3
    },
    {
        "name": "Mort N. Marton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Martone",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Antonio Martorell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Maryan S. Maryan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1977,
        "count": 2
    },
    {
        "name": "Denis Masi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alice Trumbull Mason",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1904,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Raymond Mason",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2010,
        "count": 2
    },
    {
        "name": "Kristen Corinne Masri",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Massey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luigi Massoni",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Umberto Mastroianni",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Gregory Masurovsky",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2009,
        "count": 1
    },
    {
        "name": "Georges Mathieu",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2012,
        "count": 1
    },
    {
        "name": "Bruno Mathsson",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Marcel Mathys",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vera Matiukh",
        "nationality": "Russian",
        "gender": "Female",
        "startYear": 1912,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Akira Matsumoto",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Toshio Matsumoto",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Matsumura Sadaiku",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shin Matsunaga",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roberto Matta",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 2002,
        "count": 7
    },
    {
        "name": "Herbert Matter",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Holger Matthies",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ingo Maurer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Neil Maurer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Almir Mavignier",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Max",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Mayen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Elaine Mayes",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roger Mayne",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Michael Mazur",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2009,
        "count": 3
    },
    {
        "name": "Azaria Mbatha",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dr. John McArthur",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Jay McCafferty",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Byron McClintock",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Allan McCollum",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Leonard McCombe",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ann McCoy",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John McCracken",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Jean McEwen",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Rory McEwen",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 1982,
        "count": 1
    },
    {
        "name": "Elizabeth McFadden",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1912,
        "endYear": 1986,
        "count": 1
    },
    {
        "name": "Lawrence McFarland",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James McGarrell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Kenneth McGowan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Byron McKeeby",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rhondal McKinney",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bruce McLean",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard McLean",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Michael McLoughlin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George McNeil",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Larry Mcpherson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Frederick Edward McWilliam",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1992,
        "count": 1
    },
    {
        "name": "John McWilliams",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Clement Meadmore",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2005,
        "count": 1
    },
    {
        "name": "Sol Mednick",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1970,
        "count": 0
    },
    {
        "name": "Howard Mehring",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Richard Meier",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alex Melamid",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mohamed Melehi",
        "nationality": "Moroccan",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "José Meléndez Contreras",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Waldimiro Melgarejo Muñoz",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "John Melin",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fausto Melotti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Pierre Mendell",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leopoldo Méndez",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1969,
        "count": 0
    },
    {
        "name": "Ana Mendieta",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 1985,
        "count": 4
    },
    {
        "name": "Alessandro Mendini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Antonio Mendoza",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edward Meneeley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wendy Meng",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roberto Menghi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jan Menses",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Richard Merkin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2009,
        "count": 1
    },
    {
        "name": "Hugh Merrill",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roger Mertin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Gerhard Merz",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mario Merz",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2003,
        "count": 15
    },
    {
        "name": "E.L.T. (Edouard Léon Théodore) Mesens",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1968,
        "count": 1
    },
    {
        "name": "Kiar Bogdan Meško",
        "nationality": "Slovenian",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gary Metz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Ray K. Metzker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Sheila Metzner",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tommy Mew",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Floris Meydam",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Melissa Meyer",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Hans Meyer-Petersen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joel Meyerowitz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Meyers",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Guillermo Meza",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1997,
        "count": 3
    },
    {
        "name": "Lucjan Mianowski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Duane Michals",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Midorikawa Yoichi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "James Miho",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tomoko Miho",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Jun Miki",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Tomio Miki",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Josef Mikl",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Eleanore Mikus",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1927,
        "endYear": 2017,
        "count": 1
    },
    {
        "name": "Stephen R. Milanowski",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gjon Mili",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Raúl Milián",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1984,
        "count": 4
    },
    {
        "name": "Pedro Millar",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Manolo Millares",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1972,
        "count": 0
    },
    {
        "name": "Earl Miller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "John Miller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Melissa Miller",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Nachume Miller",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Peter Caldwell Millett",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Edward Millman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1964,
        "count": 0
    },
    {
        "name": "Lev Mills",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Keith Milow",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Zwi Milshtein",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Milton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marcello Minale",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Keiko Minami",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1911,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "André Minaux",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephen Mindel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vera Bocayuva Mindlin",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1920,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Roger Minick",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Minkler",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Marilyn Minter",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marta Minujín",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luciano Miori",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2006,
        "count": 1
    },
    {
        "name": "Jean Miotte",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Victor Mira",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Antoni Miralda",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rodolfo Mishaan",
        "nationality": "Guatemalan",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Richard Misrach",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mary Miss",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Julio Mitchel",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joan Mitchell",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1925,
        "endYear": 1992,
        "count": 7
    },
    {
        "name": "Akira Miyoshi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jan Młodożeniec",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Lisette Model",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1901,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Paul Mogensen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bill Moggridge",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Benjamín Molina",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Guido Molinari",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Eduardo Moll",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sahlan Momo",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ana Maria Moncalvo",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1921,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Jay Monroe",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gustavo Monroy",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rubén Ortiz-Torres",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jan Montyn",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Moon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John D. Mooney",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Claire Moore",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1917,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "David Moore",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Frank Moore",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Margaret Ann Moorman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Francisco (\"Pancho\") Mora",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Armando Morales Sequeira",
        "nationality": "Nicaraguan",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Ibi Trier Mørch",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "François Morellet",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2016,
        "count": 1
    },
    {
        "name": "Mattia Moreni",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gabriel Morera",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carlo Moretti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Maud Morgan",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1903,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Norma G. Morgan",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1928,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Shigeyoshi Morioka",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Morita Yasuji",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1959,
        "count": 1
    },
    {
        "name": "Andreas Moritz",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Masamitsu Moriwaki",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Daido Moriyama",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Malcolm Morley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2018,
        "count": 1
    },
    {
        "name": "Carl Morris",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George L. K. Morris",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Hilda Morris",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1911,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Robert Morris",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2018,
        "count": 16
    },
    {
        "name": "Wright Morris",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Andrew Morrison",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jonathan Morse",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Mortensen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Ree Morton",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1936,
        "endYear": 1977,
        "count": 83
    },
    {
        "name": "Mike Mosco",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Victor Moscoso",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jill Moser",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Ed Moses",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Robert Moskowitz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Charles William Moss",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Olivier Mosset",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Robert Motherwell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1991,
        "count": 67
    },
    {
        "name": "Sadamasa Motonaga",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Deborah de Moulpied",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edward Moulthrop",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Olivier Mourgue",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stanley Mouse",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Seong Moy",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Marcello Muccini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Grant Mudford",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Muench",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Shuji Mukai",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Mukarobgwa",
        "nationality": "Zimbabwean",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1999,
        "count": 3
    },
    {
        "name": "Mark W. Mulhern",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Mullen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Fridolin Müller",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Grégoire Müller",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jan Müller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1958,
        "count": 0
    },
    {
        "name": "Robert Müller",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Josef Müller-Brockmann",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Peter Müller-Munk",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1967,
        "count": 0
    },
    {
        "name": "Shizuko Muller-Yoshikawa",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gerd Alfred Müller",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lee Mullican",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1998,
        "count": 4
    },
    {
        "name": "Matt Mullican",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Shiko Munakata",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Bruno Munari",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1998,
        "count": 4
    },
    {
        "name": "Robert Munford",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Maina-Miriam Munsky",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 1999,
        "count": 1
    },
    {
        "name": "Donald Munson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Reiko Tanabe",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Walter Tandy Murch",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1967,
        "count": 1
    },
    {
        "name": "Zühtü Müritoğlu",
        "nationality": "Turkish",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Catherine Murphy",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Michaela Allan Murphy",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elizabeth Murray",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 2007,
        "count": 19
    },
    {
        "name": "Edo Murtić",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Isaac Lane Muse",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1996,
        "count": 1
    },
    {
        "name": "Zoran Mušič",
        "nationality": "Slovenian",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Peter Mussfeldt",
        "nationality": "Ecuadorian",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Toby Mussman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carl Mydans",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kazumasa Nagai",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Nagel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Masatoshi Naitoh",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Naoto Nakagawa",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Makoto Nakamura",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hitoshi Nakazato",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Mauricio Nannucci",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Nares",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Ben Nason",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carlo Nason",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jacques Nathan-Garamond",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Robert Natkin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Ulrike Nattermuller",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gertrud Natzler",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1908,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Otto Natzler",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Bruce Nauman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 13
    },
    {
        "name": "Ernst Wilhelm Nay",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1968,
        "count": 2
    },
    {
        "name": "Genevieve Naylor",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1914,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Joseph Ndandarika",
        "nationality": "Zimbabwean",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Reginald Neal",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joseph Nechvatal",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Nedo Mion Ferrario",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Édgar Negret",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Ernst Neizvestny",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2016,
        "count": 1
    },
    {
        "name": "George Nelson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Joan Nelson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert A. Nelson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gerda Mikhailova Nemenova",
        "nationality": "Russian",
        "gender": "Female",
        "startYear": 1905,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Vladimir Nikolaevich Nemukhin",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Virginia Nepodal",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1914,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Lowell Nesbitt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 1993,
        "count": 1
    },
    {
        "name": "Steven E. Nestler",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bea Nettles",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert S. Neuman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Joshua Neustein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Albert Newall",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1989,
        "count": 1
    },
    {
        "name": "Beaumont Newhall",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Arnold Newman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Barnett Newman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1970,
        "count": 6
    },
    {
        "name": "John Newman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Gordon Newton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Don Nice",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Herbert B. Nichols",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Robert Lovis Nicoidski",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Nic Nicosia",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Palle Nielson",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Rodolfo Nieto",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Frans van Nieuwenborg",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Minoru Niizuma",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Key L. Nilson",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Gladys Nilsson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Bruno Ninaber Van Eyben",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hermann Nitsch",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2022,
        "count": 3
    },
    {
        "name": "Erik Nitsche",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Niviaksiak",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1959,
        "count": 0
    },
    {
        "name": "Costantino Nivola",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Nicholas Nixon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anthony Nobile",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tetsuya Noda",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Francis Noel",
        "nationality": "Nationality unknown",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Georges Noël",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Anne Noggle",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1922,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Isamu Noguchi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1988,
        "count": 3
    },
    {
        "name": "Sidney Nolan",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1992,
        "count": 7
    },
    {
        "name": "Kenneth Noland",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Richard Nonas",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Noneman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barbara Norfleet",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dorothy Norman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1905,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Lorie Novak",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gastone Novelli",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1968,
        "count": 0
    },
    {
        "name": "David Novros",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Ira Nowinski",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Nozkowski",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Sam Joseph Ntiro",
        "nationality": "Tanzanian",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Yki Nummi",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Guillermo Núñez",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Jim Nutt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Futzie Nutzle",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gunnel Nyman",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1948,
        "count": 0
    },
    {
        "name": "Thom O'Connor",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hugh O'Donnell",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Juan O'Gorman",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Frederick O'Hara",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pablo O'Higgins",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Jon T. O'Neal",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John O'Reilly",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shuichi Obata",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Oberheim",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Isidoro Ocampo",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Miguel Ocampo",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Tetsuo Ochikubo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Starr Ockenga",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Will Hiroshi Oda",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Albert Oehlen",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 16
    },
    {
        "name": "Donald M. Oenslager",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1975,
        "count": 1
    },
    {
        "name": "Hiroshi Ohchi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1974,
        "count": 0
    },
    {
        "name": "Shinro Ohtake",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rikuro Okamoto",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Shigeo Okamoto",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yoichi R. Okamoto",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Okamura Kichiemon",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William Bayard Okie Jr.",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1971,
        "count": 1
    },
    {
        "name": "Tetsu Okuhara",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rafal Olbinski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Serge Oldenbourg",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Claes Oldenburg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2022,
        "count": 29
    },
    {
        "name": "Jules Olitski",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2007,
        "count": 6
    },
    {
        "name": "Nathan Oliveira",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2010,
        "count": 1
    },
    {
        "name": "Arthur Ollman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barbara Olmsted",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1915,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Eric H. Olson",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Lennart Olson",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Ono Takahiro",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yoko Ono",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1933,
        "endYear": 0,
        "count": 25
    },
    {
        "name": "Gordon Onslow-Ford",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Roman Opalka",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2011,
        "count": 1
    },
    {
        "name": "Rodolfo Opazo",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Dennis Oppenheim",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2011,
        "count": 2
    },
    {
        "name": "Meret Oppenheim",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1913,
        "endYear": 1985,
        "count": 1
    },
    {
        "name": "John Opper",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Ezra Orion",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Alicia Orlandi",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Felipe Orlando",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 2001,
        "count": 1
    },
    {
        "name": "Don Ornitz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Emilio Ortiz",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George Ortman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Erik Ortvad",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 2008,
        "count": 1
    },
    {
        "name": "Robert Osborn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1994,
        "count": 1
    },
    {
        "name": "Christine Osinski",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roberto Ossaye",
        "nationality": "Guatemalan",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1954,
        "count": 0
    },
    {
        "name": "Alfonso Ossorio",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1990,
        "count": 2
    },
    {
        "name": "Anders Osterlin",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fayga Ostrower",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1920,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Arthur Osver",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Alejandro Otero",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1990,
        "count": 15
    },
    {
        "name": "Maria Catalina Otero Lamas",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1909,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ryszard K. Otreba",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tom Otterness",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Bill Owens",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alice Rahon",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1904,
        "endYear": 1987,
        "count": 2
    },
    {
        "name": "Wolfgang Paalen",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1959,
        "count": 4
    },
    {
        "name": "Onofrio A. Pacenza",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Homer Page",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Patrick D. Pagnano",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrzej Pagowski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nam June Paik",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2006,
        "count": 23
    },
    {
        "name": "Timo Tauno Pajunen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luisa Zuloaga de Palacios",
        "nationality": "Venezuelan",
        "gender": "Female",
        "startYear": 1923,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Mimmo Paladino",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julio Palazuelos",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Blinky Palermo",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 1977,
        "count": 9
    },
    {
        "name": "Marion Palfi",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1917,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Hans Heinrich Palitzsch",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tony Palladino",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sven Palmqvist",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Panamarenko",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 2019,
        "count": 2
    },
    {
        "name": "Hally Pancer",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "José Pancetti",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1958,
        "count": 0
    },
    {
        "name": "Jerzy Panek",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Ivo Pannaggi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1981,
        "count": 1
    },
    {
        "name": "Verner Panton",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Cesare Paolini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eduardo Paolozzi",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2005,
        "count": 30
    },
    {
        "name": "Peter Paone",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tod Papageorge",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Antonio Papasso",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Diógenes Paredes",
        "nationality": "Ecuadorian",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1968,
        "count": 1
    },
    {
        "name": "Richard Pare",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mimi Parent",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1924,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Harold Paris",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "David Park",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1960,
        "count": 2
    },
    {
        "name": "Ann Parker",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Brynhild Parker",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1987,
        "count": 0
    },
    {
        "name": "Olivia Parker",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Raymond Parker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Martin Parr",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roger Parry",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1977,
        "count": 0
    },
    {
        "name": "Jürgen Partenheimer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Partin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edward Paschke",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Victor Pasmore",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "César Paternosto",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Izhar Patkin",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Benjamin Patterson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2016,
        "count": 5
    },
    {
        "name": "Thomas Patti",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pierre Paulin",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Pauta",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Pavlos",
        "nationality": "Greek",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "José Pavon",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Philip Pearlstein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Henry Pearson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2006,
        "count": 3
    },
    {
        "name": "John Pearson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Ronald Hayes Pearson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Mary Peck",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carl-Henning Pedersen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1993,
        "count": 5
    },
    {
        "name": "Paolo Pedrizzetti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eleanore Peduzzi-Riva",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Pemberton",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gianni Penati",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "A.R. Penck (Ralf Winkler)",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 2017,
        "count": 58
    },
    {
        "name": "Irving Penn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Giuseppe Penone",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Irene Rice Pereira",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1907,
        "endYear": 1971,
        "count": 2
    },
    {
        "name": "Gilles Peress",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Achille Perilli",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bernard Perlin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2014,
        "count": 2
    },
    {
        "name": "Abigail Perlmutter",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charlotte Perriand",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1903,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Stephen Perrin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Mario Persico",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dolly Perutz",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1908,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Gaetano Pesce",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gabor Peterdi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Roland Petersen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Irving Petlin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Trude Petri-Raben",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1906,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Paul Petricone",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Dimitri Petrov",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1986,
        "count": 1
    },
    {
        "name": "William Pettet",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Pettibone",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cesare Peverelli",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2000,
        "count": 2
    },
    {
        "name": "Wilfred Pezold",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Judy Pfaff",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Peter Pfisterer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1963,
        "count": 0
    },
    {
        "name": "Bernard Pfriem",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1996,
        "count": 1
    },
    {
        "name": "Ellen Phelan",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 0,
        "count": 15
    },
    {
        "name": "Helen Phillips",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1913,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Mary Walker Phillips",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Phillips",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tom Phillips",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Celestino Piatti",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Joseph Piccillo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Ivan Picelj",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Walter Pichler",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2012,
        "count": 8
    },
    {
        "name": "Alton Pickens",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1991,
        "count": 1
    },
    {
        "name": "Carl Pickhardt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 2004,
        "count": 1
    },
    {
        "name": "Paul Peter Piech",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Otto Piene",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2014,
        "count": 1
    },
    {
        "name": "Leona Pierce",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1921,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Édouard Pignon",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1993,
        "count": 1
    },
    {
        "name": "Faramarz Pilaram",
        "nationality": "Iranian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 1982,
        "count": 1
    },
    {
        "name": "David Pilbrow",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Howardena Pindell",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Adão Pinheiro",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giovanni Pintori",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "John Piper",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1992,
        "count": 3
    },
    {
        "name": "Lauren Piperno",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ezio Pirali",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giancarlo Piretti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michelangelo Pistoletto",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gerardo Pita",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Pitseolak",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1904,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Armando Pizzinato",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "José Pla-Narbona",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sylvia Plachy",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hans Platschek",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dimitri Petrovich Plavinsky",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2012,
        "count": 1
    },
    {
        "name": "Linda Plotkin",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Plowden",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marjan Pogačnik",
        "nationality": "Slovenian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Alfred Pohl",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Anne Poirier",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Patrick Poirier",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Marcel Polak",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1962,
        "count": 2
    },
    {
        "name": "Rogelio Polesello",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 2014,
        "count": 1
    },
    {
        "name": "Steve Poleskie",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sigmar Polke",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 2010,
        "count": 31
    },
    {
        "name": "Reginald Pollack",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Jackson Pollock",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1956,
        "count": 13
    },
    {
        "name": "Arnaldo Pomodoro",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Giò Pomodoro",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2002,
        "count": 1
    },
    {
        "name": "Joan Ponç",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Michael Ponce De Leon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Jacqueline Poncelet",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Clayton Pond",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Isabel Pons",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1912,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Carlo Ponti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "C. Emanuele Ponzio",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1923,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Irene Poon",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Larry Poons",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "James Poppitz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bruce Porter",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eliot Porter",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Fairfield Porter",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Katherine Porter",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Liliana Porter",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Candido Portinari",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1962,
        "count": 2
    },
    {
        "name": "Giacomo Porzano",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2006,
        "count": 1
    },
    {
        "name": "Stephen Posen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Helen Margaret Post",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1907,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Hannes Postma",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joanna Pousette-Dart",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Pousette-Dart",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1992,
        "count": 3
    },
    {
        "name": "Rudy Pozzatti",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "Lucio Pozzi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Mario Prassinos",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Charles Pratt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Davis J. Pratt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1987,
        "count": 0
    },
    {
        "name": "Léon Prébandier",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Prentice",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Prestini",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Henri Pribik",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ken Price",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2012,
        "count": 5
    },
    {
        "name": "Ursula Morley Price",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zigmunds Priede",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Prifti",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Johan Thorn Prikker",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 1968,
        "count": 0
    },
    {
        "name": "Stephen Prina",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Douglas Prince",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Prince",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 16
    },
    {
        "name": "Antoine Prinner",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Leah De Prizio",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Patrick Procktor",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2003,
        "count": 4
    },
    {
        "name": "Rick Prol",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pudlo Pudlat",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Peter Rose Pulham",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1956,
        "count": 0
    },
    {
        "name": "Arthur Pulos",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Purves-Smith",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1949,
        "count": 0
    },
    {
        "name": "Martin Puryear",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Theodor Puschkarski",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mavis Pusey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William Pye",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Leonard Pytlak",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Gertrude Quastler",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1909,
        "endYear": 1963,
        "count": 0
    },
    {
        "name": "Harvey Quaytman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2002,
        "count": 4
    },
    {
        "name": "Bernard Quentin",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles Quest",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Herb Quick",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Lothar Quinte",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Walter Quirt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1968,
        "count": 0
    },
    {
        "name": "Peter Raacke",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fanny Rabel",
        "nationality": "Mexican",
        "gender": "Female",
        "startYear": 1922,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "David Rabinowitch",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Leo Rabkin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2015,
        "count": 5
    },
    {
        "name": "Ernest Race",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1964,
        "count": 0
    },
    {
        "name": "André Racz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1994,
        "count": 2
    },
    {
        "name": "Lucia Radochonska",
        "nationality": "Polish",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Markus Raetz",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Joseph Raffael",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bedri Rahmi",
        "nationality": "Turkish",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Charles Rain",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1985,
        "count": 18
    },
    {
        "name": "Arnulf Rainer",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Tomas Rajlich",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Naftali Rakuzin",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gunter Rambow",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Everardo Ramirez",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1992,
        "count": 1
    },
    {
        "name": "Alfonso Ramirez Fajardo",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1969,
        "count": 2
    },
    {
        "name": "Eduardo Ramírez Villamizar",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2004,
        "count": 1
    },
    {
        "name": "Rammellzee",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 2010,
        "count": 13
    },
    {
        "name": "Mel Ramos",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "José Carlos Ramos Galvez",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Daniel Ranalli",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Rand",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Herbert E. Randall",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "W. D. Randall",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Edward Ranney",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hans H. Rath",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1968,
        "count": 0
    },
    {
        "name": "Robert Rauschenberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2008,
        "count": 45
    },
    {
        "name": "Tony Ray-Jones",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 1972,
        "count": 0
    },
    {
        "name": "Marie Raymond",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1908,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Jean Pierre Raynaud",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Josef Raynefeld",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1940,
        "count": 2
    },
    {
        "name": "Omar Rayo",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2010,
        "count": 1
    },
    {
        "name": "Martial Raysse",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George Reavey",
        "nationality": "Irish",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "N. Krishna Reddy",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "June Redfern",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Junius Redwood",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1991,
        "count": 1
    },
    {
        "name": "Anton Refregier",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Johannes Regn",
        "nationality": "Nationality unknown",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paula Rego",
        "nationality": "Portuguese",
        "gender": "Female",
        "startYear": 1935,
        "endYear": 2022,
        "count": 0
    },
    {
        "name": "Steve Reich",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jesse Reichek",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2005,
        "count": 1
    },
    {
        "name": "Josua Reichert",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vilem Reichmann",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William Reimann",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ad Reinhardt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1967,
        "count": 10
    },
    {
        "name": "Nono Reinhold",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Reinhoud (Reinhoud D'haese)",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2007,
        "count": 2
    },
    {
        "name": "Nancy Reitkopf",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Emilio J. Renart",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1991,
        "count": 1
    },
    {
        "name": "Eric P. Renner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edda Renouf",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 0,
        "count": 11
    },
    {
        "name": "Marcia Resnick",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Milton Resnick",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 2004,
        "count": 1
    },
    {
        "name": "Pierre Restany",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Maria Reuter",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Carl Fredrik Reuterswärd",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2016,
        "count": 1
    },
    {
        "name": "Paul Revel",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Nancy Rexroth",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jeanne Reynal",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1903,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Alan Reynolds",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Antonio Reynoso",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Serge Rezvani",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marvin Rhodes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barbara Chase-Riboud",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Marc Riboud",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Franco Ricci",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leland Rice",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ceri Richards",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Eugene Richards",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Germaine Richier",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1904,
        "endYear": 1959,
        "count": 0
    },
    {
        "name": "Gerhard Richter",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 56
    },
    {
        "name": "Roy Richter",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Marco Richterich",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George Rickey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "James Riddle",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Lucie Rie",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1902,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "John Rieben",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Claus Joseph Riedel",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Henry Ries",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "J.J.J. (Jean-Jacques-Joachim) Rigal",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Bridget Riley",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Terry Riley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Klaus Rinke",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean-Paul Riopelle",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Murray Riss",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Risser",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Humberto Rivas",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2009,
        "count": 1
    },
    {
        "name": "Augusto Rivera",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Carlos Raquel Rivera",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Manuel Rivera",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Larry Rivers",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2002,
        "count": 25
    },
    {
        "name": "Dan Rizzie",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bruce Robbins",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Robbins",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Hulda Robbins",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1910,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "LeRoy Southward Robbins",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1987,
        "count": 0
    },
    {
        "name": "Ione Robinson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1989,
        "count": 2
    },
    {
        "name": "Walter Robinson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "T. H. Robsjohn-Gibbings",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Gunther Rochelt",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dorothea Rockburne",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1932,
        "endYear": 0,
        "count": 19
    },
    {
        "name": "Juan Antonio Roda",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Emilio Rodríguez Larraín",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Nick Roericht",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kurt Roesch",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Walter Rogalski",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mary Rogers",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roger van Rogger",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Rogi André (Rozsa Klein)",
        "nationality": "Hungarian",
        "gender": "Female",
        "startYear": 1905,
        "endYear": 1970,
        "count": 0
    },
    {
        "name": "Milton Rogovin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Robert Rohm",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Charles Roitz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Carlos Rojas",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 1997,
        "count": 1
    },
    {
        "name": "Thomas Roma",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Clare Romano",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1922,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Osvaldo Romberg",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2019,
        "count": 4
    },
    {
        "name": "William Ronald",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "James Rosati",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1988,
        "count": 2
    },
    {
        "name": "Brian Rose",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Rose",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Herman Rose",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Walter Rosenblum",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "James Rosenquist",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2017,
        "count": 11
    },
    {
        "name": "Bernard Rosenthal",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Seymour Rosofsky",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1981,
        "count": 1
    },
    {
        "name": "Brown Ross",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Judith Joy Ross",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Merg Ross",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ed Rossbach",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Jaroslav Rössler",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Alfredo Rostgaard",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Theodore Roszak",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1981,
        "count": 4
    },
    {
        "name": "Thurman Rotan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Mimmo Rotella",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Dieter Roth",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1998,
        "count": 73
    },
    {
        "name": "Jack Roth",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2004,
        "count": 1
    },
    {
        "name": "Susan Rothenberg",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 2020,
        "count": 8
    },
    {
        "name": "H. A. Rothholz",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Mark Rothko",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1970,
        "count": 7
    },
    {
        "name": "H. D. Rothschild",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Arthur Rothstein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Paul Rotterdam",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "François Rouan",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Kosti Ruohomaa",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1961,
        "count": 0
    },
    {
        "name": "Gaston Louis Roux",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "David Rowland",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Roy",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2001,
        "count": 1
    },
    {
        "name": "Félix Rozen",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Richards Ruben",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Ernö Rubik",
        "nationality": "Hungarian",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leo Rubinfien",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mabel Rubli",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dorothy Ruddick",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Rudolph",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 1997,
        "count": 1
    },
    {
        "name": "Gregory Rukavina",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Knut Rumohr",
        "nationality": "Norwegian",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Robert Miles Runyan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Margit Ruoff",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sheron Rupp",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Allen Ruppersberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 14
    },
    {
        "name": "Edward Ruscha",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 25
    },
    {
        "name": "Irving Rusinow",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Mario Russo",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Reiner Ruthenbeck",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2016,
        "count": 3
    },
    {
        "name": "Viktor Rutz",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Ryman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2019,
        "count": 5
    },
    {
        "name": "Betye Saar",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eero Saarinen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1961,
        "count": 0
    },
    {
        "name": "Roland Sabatier",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lino Sabattini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George Sadek",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Donald Saff",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kiyoshi Saito",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Takako Saito",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Asao Sakamoto",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ben Sakoguchi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jack Sal",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ibrahim El-Salahi",
        "nationality": "Sudanese",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 37
    },
    {
        "name": "Attilio Salemme",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1955,
        "count": 3
    },
    {
        "name": "David Salle",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Steve Salmieri",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Salt",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alberto Salvati",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dana Salvo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mohan B. Samant",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Lucas Samaras",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 12
    },
    {
        "name": "Roberto Sambonet",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Gilván Samico",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ralph Samuels",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1972,
        "count": 0
    },
    {
        "name": "America Sanchez",
        "nationality": "Spanish",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Emilio Sanchez",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1999,
        "count": 2
    },
    {
        "name": "Humberto Jaimes Sanchez",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Fred Sandback",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 2003,
        "count": 80
    },
    {
        "name": "Ludwig Sander",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Benita Sanders",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joop Sanders",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Douglas Sandhage",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barbara Sandler",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Arturo Sandoval",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Juvenal Sansó",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giuseppe Santomaso",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Pasquale Santoro",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Richard Sapper",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Alan Saret",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Gino Sarfatti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Sarkis (Sarkis Zabunyan)",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Sarkisian",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Timo Sarpaneva",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Enrico Sarsini",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Takao Sasai",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Aligi Sassu",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Ado Sato",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Koichi Sato",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Key Sato",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Michel Sauer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Saul",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "David Saunders",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Raymond Saunders",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Antonio Saura",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1998,
        "count": 7
    },
    {
        "name": "Naomi Savage",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1927,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Angelo Savelli",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Raymond Savignac",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Tad Savinar",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sawada Tetsuro",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 1998,
        "count": 3
    },
    {
        "name": "Jan Sawka",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Diet Sayler",
        "nationality": "Romanian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jeffrey Scales",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Emilio Scanavino",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Italo Scanga",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Afra Scarpa",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carlo Scarpa",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Tobia Scarpa",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Salvatore Scarpitta",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Louis Schanker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Philip von Schantz",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Miriam Schapiro",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1923,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Bezalel Schatz",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Xanti Schawinsky",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Stephen Scheer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gustav Schenk",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1969,
        "count": 0
    },
    {
        "name": "Chris Schiavo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elso Schiavo",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Moik Schiele",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "John Schlesinger",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edwin Schlossberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Regina Schmeken",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Georg Schmid",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Arnold Schmidt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Arthur Schmidt",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julius Schmidt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Michael Schmidt",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Wolfgang Schmidt",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Helmut Schmidt-Rhen",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julian Schnabel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Martin Schneider",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Schneider",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Toni Schneiders",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bob Schnepf",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Klaus A. Schnitzer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nicolas Schöffer",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Fritz Scholder",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Laurence Scholder",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Philipp Scholz Rittermann",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ben Schonzeit",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elmer Schooley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Jan J. Schoonhoven",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1994,
        "count": 9
    },
    {
        "name": "John Schott",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Karl Schrag",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Richard Schultz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bernard Schultze",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Emil Schumacher",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elfi Schuselka",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Colin Schuster",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Schütte",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 38
    },
    {
        "name": "Peter Schuyff",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Aubrey Schwartz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Douglas Schwartz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Manfred Schwartz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1970,
        "count": 1
    },
    {
        "name": "Robin Schwartz",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hannes Schwarz",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William Schwedler",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Emma Gismondi Schweinberger",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carlos Scliar",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2001,
        "count": 1
    },
    {
        "name": "John Scofield",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Antonio Scordia",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joe Scorsone",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Douglas Scott",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Patrick Scott",
        "nationality": "Irish",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Tim Scott",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William Scott",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1989,
        "count": 2
    },
    {
        "name": "Sean Scully",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Gilbert H. Seaver",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1977,
        "count": 0
    },
    {
        "name": "James Seawright",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James A. Sebastian",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carole Seborovski",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Arthur Secunda",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George Segal",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2000,
        "count": 4
    },
    {
        "name": "Antonio Segui",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2022,
        "count": 4
    },
    {
        "name": "Jochen Seidel",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1971,
        "count": 1
    },
    {
        "name": "Doris Seidler",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1912,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Judy Seigel",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Peter Sekaer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1950,
        "count": 0
    },
    {
        "name": "Hakuyou Seki",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jun'ichiro Sekino",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Sonja Sekula",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1918,
        "endYear": 1963,
        "count": 5
    },
    {
        "name": "Jason Seley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Colin Self",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Charles Seliger",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Paul Seligman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Seltzer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sandra Semchuk",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eusebio Sempere",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1985,
        "count": 1
    },
    {
        "name": "Jan Senbergs",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luis Seoane",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Artemio Sepulveda",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Iaroslav Serpan",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Richard Serra",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 16
    },
    {
        "name": "Daniel Serra-Badué",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Jaroslav Šerých",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Ron Sewell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Seymour",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1956,
        "count": 0
    },
    {
        "name": "Li-Fa Shaih",
        "nationality": "Taiwanese",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ted Shaine",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Shapiro",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joel Shapiro",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 12
    },
    {
        "name": "Honoré Sharrer",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1920,
        "endYear": 2009,
        "count": 11
    },
    {
        "name": "Dmitri M. Shashurin",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "John Shaw",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Shaw",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Susan Shaw",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Judith Shea",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Musya Sheeler",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1908,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Peter Shelton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cindy Sherman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sarai Sherman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1922,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Sherwood",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alan Shields",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 2005,
        "count": 1
    },
    {
        "name": "Jody Shields",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tamami Shima",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Shinagawa Takumi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Ushio Shinohara",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mieko Shiomi",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 40
    },
    {
        "name": "Jun Shiraoka",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Melissa Shook",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Stephen Shore",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kenneth Showell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "José María Sicilia",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Sicurella",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Arthur Siegel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Irene Siegel",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean Signovert",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Oli Sihvonen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1991,
        "count": 1
    },
    {
        "name": "Todd Siler",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sewell Sillman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1992,
        "count": 2
    },
    {
        "name": "Joyce Sills",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Sills",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Carlos Silva",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1987,
        "count": 1
    },
    {
        "name": "Walter Silver",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Louis Silverstein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Jeffrey Silverthorne",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 2022,
        "count": 0
    },
    {
        "name": "Jerome Simon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles Simonds",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert J. Simone",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Simpson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Iain Sinclair",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Singer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Raghubir Singh",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Art Sinsabaugh",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Mitchell Siporin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1976,
        "count": 1
    },
    {
        "name": "Aaron Siskind",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Vasily Yakovlovich Sitnikov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1987,
        "count": 5
    },
    {
        "name": "Harold Sitterle",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Trudi Sitterle",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1921,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Birgit Skiöld",
        "nationality": "Swedish",
        "gender": "Female",
        "startYear": 1923,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Finn Sködt",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nancy Skolos",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hella Skowronski",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1905,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Skunder (Skunder Boghossian)",
        "nationality": "Ethiopian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2003,
        "count": 1
    },
    {
        "name": "Clarissa Sligh",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sandi Slone",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Howard Smagula",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Neal Small",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yves De Smet",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Noemi Smilansky",
        "nationality": "Israeli",
        "gender": "Female",
        "startYear": 1918,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Beuford Smith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Smith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1965,
        "count": 4
    },
    {
        "name": "Gordon A. Smith",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "G. E. Kidder Smith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Jack Smith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 1989,
        "count": 9
    },
    {
        "name": "Joel Smith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Keith Smith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kiki Smith",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Kimber Smith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Leon Polk Smith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1996,
        "count": 4
    },
    {
        "name": "Michael A. Smith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mike Smith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Moishe Smith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Smith",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2016,
        "count": 2
    },
    {
        "name": "Tony Smith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1980,
        "count": 4
    },
    {
        "name": "Vincent Smith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "W. Eugene Smith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Robert Smithson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 1973,
        "count": 61
    },
    {
        "name": "Kenneth Snelson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2016,
        "count": 4
    },
    {
        "name": "Michael Snow",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Joan Snyder",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Giorgio Soavi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Howard Sochurek",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Sage Sohier",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ap Sok",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Jaume Solá Valleys",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Susana Solano",
        "nationality": "Spanish",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Luis Alberto Solari",
        "nationality": "Uruguayan",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Rosalind Fox Solomon",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Francesco Somaini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Frederick Sommer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "K.R.H. Sonderborg",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Jack Sonenberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Alan Sonfist",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Eve Sonneman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Keith Sonnier",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 2020,
        "count": 6
    },
    {
        "name": "Wayne Sorce",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Jørgen Haugen Sørensen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Juan Soriano",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2006,
        "count": 1
    },
    {
        "name": "Steven Sorman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "László Sós",
        "nationality": "Hungarian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jesús Rafael Soto",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Ettore Sottsass",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Pierre Soulages",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2022,
        "count": 0
    },
    {
        "name": "Al Souza",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pravoslav Sovak",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giuseppe Spagnulo",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gregory Spaid",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marko Spalatin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Merle Spandorfer",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Spano",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Sparks",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrew Spence",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nicholas Sperakis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nancy Spero",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1926,
        "endYear": 2009,
        "count": 2
    },
    {
        "name": "Alan Spigelman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Spina",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Serge Spitzer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 2012,
        "count": 2
    },
    {
        "name": "Daniel Spoerri",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Hans Jurgen Spohn",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles W. Springman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Benton Spruance",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1967,
        "count": 0
    },
    {
        "name": "Everett Spruce",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Carlos Maria Miguel Squirru",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jerzy Srokowski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Friedrich St. Florian",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Stackpole",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Erich Walter Stadler",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1968,
        "count": 0
    },
    {
        "name": "Hans Staeger",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lawrence Stafford",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Staiger",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marcin Stajewski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carl-Harry Stålhane",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Eric Staller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jan Staller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ted Stamm",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 1984,
        "count": 4
    },
    {
        "name": "Theodoros Stamos",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Julian Stanczak",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Eugeniusz Stankiewicz",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Stankiewicz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Robert Stanley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Larry Stark",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Franciszek Starowieyski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Andrew Stasik",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Staub",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alex R. Stavenitz",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1960,
        "count": 0
    },
    {
        "name": "Nikos Stavroulakis",
        "nationality": "Greek",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Bernard Steffen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "J. L. Steg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Harold Steggles",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1938,
        "count": 0
    },
    {
        "name": "Joël Stein",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Walter Stein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Saul Steinberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1999,
        "count": 4
    },
    {
        "name": "Michael Steiner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Otto Steinert",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Joe Steinmetz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Mark Steinmetz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pat Steir",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 11
    },
    {
        "name": "Juan Carlos Stekelman",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Frank Stella",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 63
    },
    {
        "name": "Gary Stephan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Magnus Stephensen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "David Stephenson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joseph Sterling",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Bert Stern",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marina Stern",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1928,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Harry Sternberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Hedda Sterne",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1910,
        "endYear": 2011,
        "count": 4
    },
    {
        "name": "Joel Sternfeld",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Louis Stettner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Norman Stevens",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Clyfford Still",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "Nanny Still",
        "nationality": "Finnish",
        "gender": "Female",
        "startYear": 1926,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "James Stirling",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Mark Stock",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Walter Stöhrer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ezra Stoller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Edward Durell Stone",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Jim Stone",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marly Stone",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Storey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Lou Stoumen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Myron Stout",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1987,
        "count": 2
    },
    {
        "name": "John Stram",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Théodore (Stravinsky) Strawinsky",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Thelma Johnson Streat",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1911,
        "endYear": 1959,
        "count": 1
    },
    {
        "name": "Tal Streeter",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2014,
        "count": 1
    },
    {
        "name": "Marianne Strengell",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1909,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "James Strombotne",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ian Stuart",
        "nationality": "Irish",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michelle Stuart",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Juliusz Studnicki",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1978,
        "count": 4
    },
    {
        "name": "Walter Stuempfig",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1970,
        "count": 0
    },
    {
        "name": "Jock Sturges",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jan Stussy",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Harry Suchland",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Tum Suden",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean-Pierre Sudre",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kumi Sugaï",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "George Sugarman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1999,
        "count": 2
    },
    {
        "name": "Hiroshi Sugimoto",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tsune Sugimura",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Donald Sultan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Larry Sultan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Martin Sumers",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carol Summers",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Britt-Louise Sundell",
        "nationality": "Swedish",
        "gender": "Female",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Miroslav Šutej",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Hugo Suter",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Graham Sutherland",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1980,
        "count": 1
    },
    {
        "name": "William Stephen Sutton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark di Suvero",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Mutsumi Suzuki",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Max Walter Svanberg",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George A. Swanson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Waldemar Swierzy",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Swope",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Maltby Sykes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Joseph Szabo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Steve Szabo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Sam Szafran",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2019,
        "count": 2
    },
    {
        "name": "Karen Szekessy",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Geza Szobel",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1963,
        "count": 0
    },
    {
        "name": "Tomasz Szulecki",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fernando Szyszlo",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carol Block Taback",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "Jun Tabohashi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yasse Tabuchi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Kenji Tadamoto",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tadasky (Tadasuke Kuwayama)",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Walter Tafelmaier",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shinkichi G. Tajiri",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Takahama Kazuhide",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rikio Takahashi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Peter Takal",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1995,
        "count": 1
    },
    {
        "name": "Jiro Takamatsu",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 1998,
        "count": 22
    },
    {
        "name": "Takeshi Imaji",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Takis",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2019,
        "count": 2
    },
    {
        "name": "René Pierre Tal-Coat",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1985,
        "count": 1
    },
    {
        "name": "John Talleur",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Reuben Tam",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Shigeru Tamura",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1987,
        "count": 0
    },
    {
        "name": "Shisui Tanahashi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Atsuko Tanaka",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1932,
        "endYear": 2005,
        "count": 1
    },
    {
        "name": "Ikko Tanaka",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Tokutaro Tanaka",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Tancredi (Tancredi Parmeggiani)",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1964,
        "count": 1
    },
    {
        "name": "Richard Taninbaum",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dorothea Tanning",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1910,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Mark Tansey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jorge Tapia",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Antoni Tàpies",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Leopoldo Tartarini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lenore Tawney",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1907,
        "endYear": 2007,
        "count": 3
    },
    {
        "name": "Al Taylor",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 1999,
        "count": 3
    },
    {
        "name": "Prentiss Taylor",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Richard Taylor",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1970,
        "count": 5
    },
    {
        "name": "Terrance Nelson Taylor",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jerzy Tchorzewski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Tebo (Ángel Torres Jaramillo)",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1937,
        "count": 1
    },
    {
        "name": "Hervé Télémaque",
        "nationality": "Haitian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eugenio Tellez",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Franco Teodoro",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rouben Ter-Arutunian",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1992,
        "count": 1
    },
    {
        "name": "Eduardo Terrazas",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edmund Teske",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Nahum Tevet",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Bob Thall",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joan Josep Tharrats",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Forrest Thayer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1951,
        "count": 9
    },
    {
        "name": "Pär G. Thelander",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Francois Thépot",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wayne Thiebaud",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "Byron Thomas",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "André Thomkins",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1985,
        "count": 1
    },
    {
        "name": "Bob Thompson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 1966,
        "count": 1
    },
    {
        "name": "Francis Thompson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Jerry L. Thompson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mildred Thompson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1935,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "William Thompson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Esias Thoren",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Leslie Thornton",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kn Thurlbeck",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George A. Tice",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rolf Tietgens",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Sidney Tillim",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2001,
        "count": 1
    },
    {
        "name": "Joe Tilson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Elizabeth Timberman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1908,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Walasse Ting",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2010,
        "count": 1
    },
    {
        "name": "Jean Tinguely",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1991,
        "count": 5
    },
    {
        "name": "Bruce Tippett",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gérard Titus-Carmel",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hap Tivey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Masatoshi Toda",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Francisco Toledo",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 2019,
        "count": 3
    },
    {
        "name": "Hans Tollin",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Henryk Tomaszewski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Shomei Tomatsu",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Kristians Tonny",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1977,
        "count": 0
    },
    {
        "name": "George Tooker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2011,
        "count": 1
    },
    {
        "name": "Roland Topor",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Mario Toral",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Endre Tót",
        "nationality": "Hungarian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carl Toth",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William N. Touzani",
        "nationality": "Moroccan",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harold Tovish",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harold Barling Town",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Hugh Townley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2008,
        "count": 1
    },
    {
        "name": "Toyen (Marie Čermínová)",
        "nationality": "Czech",
        "gender": "Female",
        "startYear": 1902,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "Tomonori Toyofuku",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Neil Trager",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Philip Trager",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Steven Trefonides",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "Marc Treib",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joyce Treiman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1922,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Otto Treumann",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hann Trier",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1999,
        "count": 1
    },
    {
        "name": "Rosemarie Trockel",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 71
    },
    {
        "name": "Rudi Tröger",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ernest  Trova",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "David Trowbridge",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 1998,
        "count": 2
    },
    {
        "name": "Niklaus Troxler",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David True",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Van Day Truex",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Anne Truitt",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1921,
        "endYear": 2004,
        "count": 6
    },
    {
        "name": "Nina Tryggvadottir",
        "nationality": "Icelandic",
        "gender": "Female",
        "startYear": 1913,
        "endYear": 1968,
        "count": 0
    },
    {
        "name": "George Tscherny",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jan Tschichold",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1974,
        "count": 0
    },
    {
        "name": "Augustin Tschinkel",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Oswald Tschirtner",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Lill Tschudi",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1911,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Tokiyoshi Tsubouchi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hiromi Tsuchida",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Akihiko Tsukamoto",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Albert Tucker",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Nicolas Tucker",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Toba Tucker",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William Tucker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Rafael Tufiño",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Jakob Tuggener",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Thomas Tulis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Igael Tumarkin",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Earl S. Tupper",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Giulio Turcato",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Marko Turk",
        "nationality": "Slovenian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "William Turnbull",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Roberto Turnbull Buenrostro",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alwyn Scott Turner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alan Turner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Turrell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Anne Turyn",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Tuttle",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 27
    },
    {
        "name": "Léon Arthur Tutundjian",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1968,
        "count": 5
    },
    {
        "name": "Guenter Tuzina",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Cy Twombly",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2011,
        "count": 5
    },
    {
        "name": "Alexei Alexandrovich Tyapushkin",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Raoul Ubac",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Ansei Uchima",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Günther Uecker",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shoji Ueda",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Jerry Uelsmann",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ulla af Ugglas",
        "nationality": "Swedish",
        "gender": "Female",
        "startYear": 1916,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Timm Ulrichs",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Umbo (Otto Umbehr)",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "Richard Upton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "José Urbach",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Albert Urban",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1959,
        "count": 0
    },
    {
        "name": "Reva Urban",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1925,
        "endYear": 1987,
        "count": 0
    },
    {
        "name": "Maciej Urbaniec",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Graziella Urbinati",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1927,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Tony Urquhart",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Demetrio Urruchúa",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Kenji Ushiku",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ilya Utkin",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Burk Uzzle",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Vachon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Roger Vail",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Meyer Vaisman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Walter Valentini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gino Valle",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Philip Van Aver",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Richard Van Buren",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roland van den Berghe",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ed van Der Elsken",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "James Van Dijk",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2002,
        "count": 1
    },
    {
        "name": "Willard Van Dyke",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Ger van Elk",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 2014,
        "count": 1
    },
    {
        "name": "Jan L. A. van Goethem",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Diederick Van Kleef",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wim van Stek",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "William Vandivert",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Yarom Vardimon",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Victor Vasarely",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1997,
        "count": 2
    },
    {
        "name": "Mohsen Vaziri Moghaddam",
        "nationality": "Iranian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gene Vass",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1996,
        "count": 5
    },
    {
        "name": "Ben Vautier",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 27
    },
    {
        "name": "Kristian Vedel",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Emilio Vedova",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1995,
        "count": 1
    },
    {
        "name": "Jorge de la Vega",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1971,
        "count": 2
    },
    {
        "name": "Harriet M. Veissi",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1903,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jukka Veistola",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vladimir Veličković",
        "nationality": "Serbian",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2019,
        "count": 1
    },
    {
        "name": "Bernar Venet",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Michael Venezia",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Robert Venturi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "JoAnn Verburg",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "André Verlon",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1993,
        "count": 1
    },
    {
        "name": "Luigi Veronesi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Raúl Veroni",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Kurt Versen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Renzo Vespignani",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2001,
        "count": 4
    },
    {
        "name": "Michael Vessa",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "David Vestal",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Heinz Veuhoff",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ivan (Vial Williams) Vial",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Claude Viallat",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alberto Viani",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Esteban Vicente",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 2001,
        "count": 1
    },
    {
        "name": "Roger Vieillard",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Constance Brenner Vieira Da Cunha",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Maria Helena Vieira da Silva",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1908,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Jean-Pierre Vielfaure",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Romas Viesulas",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Massimo Vignelli",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Juan Vila Vilacasas",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eduardo Vilches",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jacques Villeglé",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "René Vincent",
        "nationality": "Haitian",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lauretta Vinciarelli",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Claude Viseux",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2008,
        "count": 1
    },
    {
        "name": "Carel Visser",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2015,
        "count": 1
    },
    {
        "name": "Carlo L. Vivarelli",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Michael Vivo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 1984,
        "count": 1
    },
    {
        "name": "Lucien Vlerick",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ruth Vollmer",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1903,
        "endYear": 1982,
        "count": 1
    },
    {
        "name": "Monika Von Boch",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1915,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Priska Von Martin",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1912,
        "endYear": 1982,
        "count": 1
    },
    {
        "name": "Jan Voss",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wolf Vostell",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 1998,
        "count": 5
    },
    {
        "name": "Andrei Voznesensky",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lazar Vujaklija",
        "nationality": "Serbian",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gérard Vulliamy",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Konrad Wachsmann",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "Theodore Waddell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jaap Wagemaker",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1972,
        "count": 0
    },
    {
        "name": "John F. Waggaman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Aijiro Wakita",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sylvia Wald",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1915,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Paul Waldman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Butch Walker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Walker",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Sandy Walker",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Todd Walker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "August Walla",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Don Wallance",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Edward Wallowitch",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "William Walmsley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bernard Walsh",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Ernest Walters",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2004,
        "count": 1
    },
    {
        "name": "Eugene Walters",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jo Ann Walters",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nicolaas Warb (Sophie Warburg)",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1906,
        "endYear": 1957,
        "count": 0
    },
    {
        "name": "Velox Ward",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1944,
        "count": 0
    },
    {
        "name": "Frederick Wardy",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Andy Warhol",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 1987,
        "count": 17
    },
    {
        "name": "Dody Weston Thompson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Marian Warzecha",
        "nationality": "Polish",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Bradford Washburn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Yukichi Watabe",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Ryo Watanabe",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sadao Watanabe",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Yoshio Watanabe",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Gilbert A. Watrous",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Brian J. Watson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Henning Watterston",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Robert Watts",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1988,
        "count": 35
    },
    {
        "name": "June Wayne",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1918,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Todd Webb",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Hugo Weber",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "William Frederick Weege III",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harry Mohr Weese",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "William Wegman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Hans Wegner",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Elbert Weinberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Dan Weiner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1959,
        "count": 0
    },
    {
        "name": "Lawrence Weiner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 2021,
        "count": 33
    },
    {
        "name": "Wolfgang Weingart",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "David Weinrib",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Joyce Weinstein",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "John Spence Weir",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Weiss",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "John Joseph Weiss",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Sabine Weiss",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1924,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "Mieczyslaw Wejman",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Stanislaw Wejman",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roger Welch",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Neil Welliver",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "James Lesesne Wells",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Lynton Wells",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jack Welpott",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Stow Wengenroth",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Jane Wenger",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Wesley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2022,
        "count": 11
    },
    {
        "name": "Henry Wessel, Jr.",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Tom Wesselmann",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2004,
        "count": 5
    },
    {
        "name": "Timothy West",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2012,
        "count": 1
    },
    {
        "name": "H. C. Westermann",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1981,
        "count": 12
    },
    {
        "name": "Brett Weston",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Stefan Wewerka",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Dennis Wheeler",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rex Whistler",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1944,
        "count": 0
    },
    {
        "name": "Charles White",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 1979,
        "count": 2
    },
    {
        "name": "Linda White",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Minor White",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Neal White",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephen White",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Brett Whiteley",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 1992,
        "count": 1
    },
    {
        "name": "Forbes Johnstone Whiteside",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Robert Whitman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Jack Whitten",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 2018,
        "count": 4
    },
    {
        "name": "Jan Wichers",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hannah Weiner",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1928,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Anne Wienholt",
        "nationality": "Australian",
        "gender": "Female",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Don Wight",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Svend Wiig Hansen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Bjorn Wiinblad",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "William T. Wiley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2020,
        "count": 6
    },
    {
        "name": "Ulfert Wilke",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "John Willenbecher",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Casey Williams",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Daniel S. Williams",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Emmett Williams",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2007,
        "count": 5
    },
    {
        "name": "Fred Williams",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1982,
        "count": 3
    },
    {
        "name": "Mason Williams",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William T. Williams",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thornton Willis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Manfred Willmann",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christopher Wilmarth",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 1987,
        "count": 23
    },
    {
        "name": "Jane Wilson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1924,
        "endYear": 2015,
        "count": 5
    },
    {
        "name": "Michael Wilson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Wilson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 24
    },
    {
        "name": "David Wing",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Trevor Winkfield",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gerd Winner",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Geoff Winningham",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Garry Winogrand",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Neil Winokur",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rolf Winquist",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1968,
        "count": 0
    },
    {
        "name": "Jackie Winsor",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas W. Winstead",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fritz Winter",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Alfred Winter-Rust",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2000,
        "count": 1
    },
    {
        "name": "Robin Winters",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Terry Winters",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Tapio Wirkkala",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Kurt Wirth",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marc Wise",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Francis Wishart",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Benno Wissing",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Adrian Wiszniewski",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Joan Witek",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Joel-Peter Witkin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rainer Wittenborn",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Emerson Woelffer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Stanislav Wojtowicz",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Marion Post Wolcott",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1910,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Arne Wolf",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sylvia Wolf",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jeffrey Wolin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wols (A. O. Wolfgang Schulze)",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1951,
        "count": 3
    },
    {
        "name": "Helena (Mie Yuan Wong) Wong",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 1990,
        "count": 1
    },
    {
        "name": "Paul Wonner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2008,
        "count": 1
    },
    {
        "name": "Art Wood",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Brian Wood",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Esther Wood",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gross Wood",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "J. Trevor Wood",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pamela Woodhead",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "George Woodman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2017,
        "count": 2
    },
    {
        "name": "Bill Woodrow",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christopher Wool",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Hal Woolf",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1962,
        "count": 0
    },
    {
        "name": "Piet Worm",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Edward Wormley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Troels Wörsel",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Don Worth",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Fritz Wotruba",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1975,
        "count": 1
    },
    {
        "name": "Dick Wray",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Russel Wright",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Andrzej J. Wroblewski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Theo Wujcik",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2014,
        "count": 1
    },
    {
        "name": "Paul Wunderlich",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2010,
        "count": 2
    },
    {
        "name": "Andrew Wyeth",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "James Wyeth",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Lance Wyman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bryan Wynter",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Llewellyn Xavier",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jaume Juez i Castella (Xirinius)",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Desiderio Hernández Xochitiotzin",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Ahmed Yacoubi",
        "nationality": "Moroccan",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Kazuo Yagi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Takao Yamada",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harumi Yamaguchi",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Takeo Yamaguchi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Yoji Yamamoto",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ryuichi Yamashiro",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tad Yamashiro",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sori Yanagi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "John Yang",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kō Yanome",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Max Yavno",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Yayanagi Tsuyoshi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Manoucher Yektai",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Albert E. Yersin",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "George Ygfors",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ylla",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1911,
        "endYear": 1955,
        "count": 0
    },
    {
        "name": "Alejandro Mario Yllanes",
        "nationality": "Bolivian",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1960,
        "count": 0
    },
    {
        "name": "Tadanori Yokoo",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chizuko Yoshida",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hodaka Yoshida",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1926,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Masaji Yoshida",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Hideo Yoshihara",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Naoki Yoshimoto",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Masunobu Yoshimura",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Senzo Yoshioka",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Arthur Young",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "La Monte Young",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Michael Young",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Peter Young",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Thomas Young",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jack Youngerman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2020,
        "count": 1
    },
    {
        "name": "Mario Yrisarry",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yvaral (Jean Pierre Vasarely)",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Teresa Zabala",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Melvin Zabarsky",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Adolf Zabransky",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Julio Augusto Zachrisson",
        "nationality": "Panamanian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stanislaw Zagorski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul A. Zahl",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Jack Zajac",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Alfredo Zalce",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Wojciech Zamecznik",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1967,
        "count": 0
    },
    {
        "name": "Norman Zammitt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Enrique Zañartu",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Tono Zancanaro",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1985,
        "count": 2
    },
    {
        "name": "Michael S. Zane",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marco Zanuso",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Zao Wou-ki",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2013,
        "count": 1
    },
    {
        "name": "Bronislaw Zapolski",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Walter Zapp",
        "nationality": "Latvian",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Zarina",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 2020,
        "count": 2
    },
    {
        "name": "Marian Zazeela",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Bruno Zehnder",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Walter Zehringer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Walter Zeischegg",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Eva Zeisel",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1906,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Bronislaw Zelek",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles Hossein Zenderoudi",
        "nationality": "Iranian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Karl Zerbe",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1972,
        "count": 0
    },
    {
        "name": "J. Thomas Zetterstrom",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Laura Ziegler",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lolita Zikmane",
        "nationality": "Latvian",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George Zimbel",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Murray Zimiles",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mac Zimmerman",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1995,
        "count": 1
    },
    {
        "name": "Tom Zimmermann",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jacques Zimmerman",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alfred Karl Zlevor",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Zoellner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Nicos Zographos",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gilberto Zorio",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Marco Zotta",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Larry Zox",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Joe Zucker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Francisco Zúñiga",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1998,
        "count": 1
    },
    {
        "name": "Zush (Alberto Porta)",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Anatoly Timofeevich Zverev",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 1986,
        "count": 6
    },
    {
        "name": "Toots Zynsky",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jack Barth",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eric Erickson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Donald C. Fritz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sean Kalish",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Berndt Kerkin",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joseph Norman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lorna Simpson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jan Vercruysse",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "John Button",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1982,
        "count": 2
    },
    {
        "name": "Tadeusz Trepkowski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1954,
        "count": 0
    },
    {
        "name": "Susi Berger",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ueli Berger",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Jack Daly",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christopher Loew",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jim Gentes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sam Lucente",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Antti Nurmesniemi",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "William L. Tolan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lois Conner",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ulrich Rückriem",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Robert Andrew Parker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Tone Vigeland",
        "nationality": "Norwegian",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Armleder",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jeff Koons",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "David Wojnarowicz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 1992,
        "count": 3
    },
    {
        "name": "Michael Corris",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Gordy",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Margo Humphrey",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hermann Pitz",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bern Porter",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Rémy Zaugg",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Glenn Goldberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ilya Kabakov",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Mimi Smith",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jac Leirner",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gordon Matta-Clark",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 1978,
        "count": 11
    },
    {
        "name": "Paul Thek",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 1988,
        "count": 10
    },
    {
        "name": "Victor Burgin",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julio Larraz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jane Hammond",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Raffaele Leomporri",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1974,
        "count": 0
    },
    {
        "name": "Art Spiegelman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rafael Alberti",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Roberto Barni",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pablo Palazuelo",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Vicente Rojo",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vinicio Berti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Paolo Favi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fabrizio Gori",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Emilio Malenotti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Philip Taaffe",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sophie Calle",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joy Jacobs",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1932,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Annette Messager",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rosalind Bengelsdorf",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1916,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Harry Bowden",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1965,
        "count": 0
    },
    {
        "name": "Herzl Emanuel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Hananiah Harari",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Agnes Lyall",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1908,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Ralph M. Rosenborg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Esphyr Slobodkina",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1908,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Albert Swinden",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1961,
        "count": 0
    },
    {
        "name": "Fredrick J. Whiteman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Wilfred M. Zogbaum",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1965,
        "count": 0
    },
    {
        "name": "Willson Y. Stamper",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Myer Abel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1946,
        "count": 0
    },
    {
        "name": "Mabelle Richardson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1907,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Greg Curnoe",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Joyce Wieland",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Horst B. Baerenz",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pedro Alcantara",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Enrique Grau",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Phanor Leon",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Rafael Coronel",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Francisco Corzas",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Roberto Donis",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Helen Escobedo",
        "nationality": "Mexican",
        "gender": "Female",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fernando Garcia Ponce",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 1987,
        "count": 0
    },
    {
        "name": "Manuel Espinoza",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luis Guevara Moreno",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Mercedes Pardo",
        "nationality": "Venezuelan",
        "gender": "Female",
        "startYear": 1922,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Alirio Palacios",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Daniel Argimón Granell",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Penelope Bennett",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ramón Ferrán Pagés",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Jordi Secall Roure",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Fernando Grillón",
        "nationality": "Paraguayan",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hernando Tejada",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Miguel Angel Rojas",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Maria Teresa Cuéllar Teyé",
        "nationality": "Colombian",
        "gender": "Female",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ned Truss",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Juan Cárdenas",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alfredo Guerrero",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jacobo Borges",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William Stone Reverón",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Lange",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hugo Arapé",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yutaka Toyota",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Raúl Cattelani",
        "nationality": "Uruguayan",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dan Dailey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Laurie Anderson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Ashbery",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Bern Boyle",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Lucinda Childs",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jane Comfort",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "R. Crumb",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 12
    },
    {
        "name": "Harvey Pekar",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jimmy DeSana",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Evergon",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jayme Odgers",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Martha Holt",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Hong",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Betsey Johnson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sonia Katchian",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephanie Brody Lederman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jacqueline Livingston",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joan Livingstone",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Lusby",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joseph Masheck",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Judith McWillie",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joan Nelsen",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Olson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kingsley Parker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Don Rodan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Martha Rosler",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 0,
        "count": 18
    },
    {
        "name": "Michael Sorkin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lee Ufan",
        "nationality": "Korean",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dondi (Donald Joseph White)",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 1998,
        "count": 2
    },
    {
        "name": "Zephyr (Andrew Witten)",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Stanley Stellar",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Benedict Tisa",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Curtis Van Buren",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Philip Warner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wenda Von Weise",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harry Anderson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul R. Meltsner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1959,
        "count": 0
    },
    {
        "name": "Gretchen Bender",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Edward Dwurnik",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stanislaw Fijalkowski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Izabella Gustowska",
        "nationality": "Polish",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrzej Kalina",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Marcus",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jaume Plensa",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anna Sobol",
        "nationality": "Polish",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Stackhouse",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jan Tarasin",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Else Alfelt",
        "nationality": "Danish",
        "gender": "Female",
        "startYear": 1910,
        "endYear": 1974,
        "count": 0
    },
    {
        "name": "Ejler Bille",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Jacques Doucet",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Sonja Ferlov",
        "nationality": "Danish",
        "gender": "Female",
        "startYear": 1911,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephen Gilbert",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chin Hsiao",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Bierut",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Victor Schrager",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Victor Skrebneski",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Miro Svolik",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zofia Rydet",
        "nationality": "Polish",
        "gender": "Female",
        "startYear": 1911,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Eva Lipman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Casebere",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mariana Cook",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marcus Kutter",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pierre Neumann",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Glenn Ligon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 13
    },
    {
        "name": "Lesley Dill",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Damien Hirst",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Silvia Kolbowski",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tadaaki Kuwayama",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marc Quinn",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gavin Turk",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rachel Whiteread",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 15
    },
    {
        "name": "Maya Deren",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1917,
        "endYear": 1961,
        "count": 0
    },
    {
        "name": "Darrel Ellis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Bruce Gilden",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eberhard Grames",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kati Horna",
        "nationality": "Mexican",
        "gender": "Female",
        "startYear": 1912,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Abelardo Morell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vincent Alan W",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Boris Yaro",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Keith Coventry",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Steven Pippin",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carl Pope",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Shambroom",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Patrick Bailly-Maitre-Grand",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ellen Brooks",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul D'Amato",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Philip Elliott",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Priscilla T. Forthman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roberto Burle Marx",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Maria Benktzon",
        "nationality": "Swedish",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Bush",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Brian Lepine",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Zimmerman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hiroaki Kozu",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Lewis",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Steve McGugan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Cook",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elizabeth Diller",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ricardo Scofidio",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zaha Hadid",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 2016,
        "count": 1
    },
    {
        "name": "Fumihiko Maki",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rem Koolhaas",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Madelon Vriesendorp",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephen Armellino",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edward Larrabee Barnes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Kisho Kurokawa",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Santiago Calatrava",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Eisenman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ari 't Hart",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Juan Hidalgo",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Deborah Kass",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Via Lewandowsky",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bernhard Luginbühl",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Ronald Jones",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Markéta Luskacová",
        "nationality": "Czech",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Ruff",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leslie Starobin",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jindrich Streit",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Aller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Brown",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Dawson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dennis Farber",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paola Ferrario",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Kozma",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gundula Schulze-Eldowy",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Laura Volkerding",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Jules Allen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bruce Bennet",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Francois Deschamps",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Humphrey Jennings",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1950,
        "count": 0
    },
    {
        "name": "Markus Oehlen",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leon Kossoff",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2019,
        "count": 1
    },
    {
        "name": "Matthew Barney",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Suzanne McClelland",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Cildo Meireles",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jean Pierre Pincemin",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Roberto Ciaccio",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Levinthal",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Esther Parada",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Tofik Shakhverdiev",
        "nationality": "Azerbaijani",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Valery Shchekoldin",
        "nationality": "Russian",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Laurie Simmons",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Penelope Umbrico",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Adrian Piper",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 59
    },
    {
        "name": "Andres Serrano",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rudolph Von Ripper",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1960,
        "count": 0
    },
    {
        "name": "Sebastião Salgado",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carl Chiarenza",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gordon Coster",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Philip-Lorca diCorcia",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Flynt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Connie Imboden",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Karen Klugman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hermann Forsterling",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William K. Greiner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gregory Crewdson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barbara Jaffe",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michal Rovner",
        "nationality": "Israeli",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ken Schles",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barbel Scianghetti",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Madoka Takagi",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Ruud Jan Kokke",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Junichi Arai",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Reiko Sudo",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Cohen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christian Leborg",
        "nationality": "Norwegian",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles Ray",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Nikos Hadjikyriakos-Ghika",
        "nationality": "Greek",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Betty Woodman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 2018,
        "count": 2
    },
    {
        "name": "Dawoud Bey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vincent Borrelli",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tadao Ando",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Willie Cole",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Tony Fitzpatrick",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Margaret Lanzetta",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Karpf",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rolf Kothrade",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kyoko Kumai",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Faith Ringgold",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Alison Saar",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Juan Sánchez",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paula Scher",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gary Essert",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Ekkeland Götze",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Heribert Ottersbach",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Therrien",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 2019,
        "count": 2
    },
    {
        "name": "Olaf Wegewitz",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anton van Dalen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Castrucci",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Fekner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eric Drooker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stash Two (Josh Franklin)",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tom McGlynn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dennis Thomas",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Day Gleeson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nadia Coën",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Martin Wong",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 1999,
        "count": 1
    },
    {
        "name": "Andrew Castrucci",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Betzaida Concepción",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Seth Tobocman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sabrina Jones",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Red David Rodriguez",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lee Quinones",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sebastian Schroder",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Walter Sipser",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bruce Witsiepe",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Will Sales",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Avram Finkelstein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tony Bevan",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Judith Francisca Baca",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mel Chin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Gonzalo Diaz",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mike Glier",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alexander Kosolopov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Guillermo Kuitca",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Otto Fried",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Keith Morrow Martin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1983,
        "count": 17
    },
    {
        "name": "Houston Conwill",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Dan Stoenescu",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hin Bredendieck",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Karin Sander",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Guy Teschmacher",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Welch",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Manfred Malzacher",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jack Stringer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carl Norby",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Javier Carvajal",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Dumas-Hermes",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas V. Paulson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Reinhold Weiss",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Frank Heacox",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Flemming Bo Hansen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bill Stewart",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Moshe Zabari",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yong Soon Min",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Patricia Dalzell",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pierangela d'Aniello",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Perry King",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Hujar",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 1987,
        "count": 0
    },
    {
        "name": "Geoffrey James",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard N. Naylor",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Corrado Aroldi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cesare Leonardi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dante Bandini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Livio Castiglioni",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Tony Esposito",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Siegel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John E. Mullin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eric Paddock",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Pinderhughes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Toshio Shibata",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Craig Schuster",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carrie Mae Weems",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Popovi Da",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Peter Hiort-Loerenzen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Antonio Macchi Cassia",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hancock Laverne",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Allen John Bootroyd",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul D. Miller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harry V. Cremonese",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Alan Cousins",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eric Rivkin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Torsten Thorup",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Herbert H. Schultes",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Norbert Schlagheck",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hap Sakwa",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hosowo Tomohiro",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephen Hobson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Glenn Edens",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giotto Stoppino",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shiro Kuramata",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Koen De Winter",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roberto Ubaldi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Umberto Orsoni",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gianni Pareschi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gianfranco Facchetti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter L. Zane",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bengt Palmgren",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kevan Laycraft",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Lonczak",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roald Steen Hansen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mary Kocol",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Davide Mercatali",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carmen Basile",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rafael Viñoly",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William de Vries",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1969,
        "count": 0
    },
    {
        "name": "Earl Childress",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephen Peart",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Steven T. Kaneko",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert J. King",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kari Virtanen",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giuseppe Pensotti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paulus van Leeuwen",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alexander Blomberg",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alice von Pechmann",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1906,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Barnard",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alberto Meda",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Franco Raggi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Denis Santachiara",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tim Parsey",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark Sanders",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bashir Zivari",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Masuteru Aoba",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles O. Perry",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Paolo Lomazzi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Donato D'Urbino",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ugo La Pietra",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lynn Alexander",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1920,
        "endYear": 1963,
        "count": 0
    },
    {
        "name": "Emily Belding",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1904,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Alexander Girard",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Eszter Haraszty",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1920,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Jack Lenor Larsen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Astrid Sampe",
        "nationality": "Swedish",
        "gender": "Female",
        "startYear": 1909,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Joel Robinson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Ada Louise Huxtable",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1921,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Lodovico Meneghetti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Heinz Rasch",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Sigurd Persson",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Angelo Testa",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Basil Spence",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Vera (Vera Neumann)",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1909,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Gretl Wollner",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1920,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Roger Tallon",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Bertel Gardberg",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Warren Platner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Matthew Abbott",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Petah Coyne",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Moustapha Dimé",
        "nationality": "Senegalese",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Marisa Merz",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1926,
        "endYear": 2019,
        "count": 2
    },
    {
        "name": "Donald T. Chadwick",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William Stumpf",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Werner Büttner",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Marc van de Loo",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Steve Jobs",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Walter Ballmer",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peggy Diggs",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tadeusz Myslowski",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jaune Quick-to-see Smith",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ann Hamilton",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Miguel Angel Ríos",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gary Hill",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chuck Hoberman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Scott Marble",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yoshito Takahashi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jacques Herzog",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pierre de Meuron",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Karen Fairbanks",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hans Gantert",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "José Bedia",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Vijay Kumar",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Donald Moffett",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Marlene McCarty",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Marco Ferreri",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kazuo Kawasaki",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ross Lovegrove",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lella Vignelli",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1934,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "David Law",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Moira Dryer",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Mona Hatoum",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Rodolfo Bretzel",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Eriksson",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean Nouvel",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vincent de Rijk",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Art Chantry",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Simon Leung",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gabriel Orozco",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 17
    },
    {
        "name": "Kim Jones",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Sara Sosnowy",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Elyn Zimmerman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Cliff Chi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christian de Portzamparc",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hélène Delprat",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gordon Cook",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Grenville Davey",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "General Idea",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 1994,
        "count": 1
    },
    {
        "name": "Federico Guzmán",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alfredo Jaar",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Mock",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rirkrit Tiravanija",
        "nationality": "Thai",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Enrique Chagoya",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Daniel Lang",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2013,
        "count": 1
    },
    {
        "name": "James Kearns",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Luis Alberto Wells",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Tom Friedman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Doris Salcedo",
        "nationality": "Colombian",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tony Oursler",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Kcho (Alexis Leyva Machado)",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Simon Patterson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kay Rosen",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alexis Smith",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Sue Williams",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Giulio Paolini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Douglas Blau",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Raymond Pettibon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 75
    },
    {
        "name": "Toba Khedoori",
        "nationality": "Australian",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Rebecca Horn",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roni Horn",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 0,
        "count": 18
    },
    {
        "name": "Phyllis Bramson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Koichi Yoshimura",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eiji Miyamoto",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ritva Puotila",
        "nationality": "Finnish",
        "gender": "Female",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tomoyuki Sugiyama",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sir William Lyons",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Malcolm Sayer",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1970,
        "count": 0
    },
    {
        "name": "Jennifer Morla",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luc Tuymans",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 15
    },
    {
        "name": "Marlene Dumas",
        "nationality": "South African",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 17
    },
    {
        "name": "Nobuyoshi Araki",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Willie Birch",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrea Zittel",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 10
    },
    {
        "name": "Toyo Ito",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Daniel Libeskind",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Cesar Pelli",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ben van Berkel",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mehrdad Yazdani",
        "nationality": "Iranian",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bernhard Fuchs",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nan Goldin",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean-Luc Moulène",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julião Sarmento",
        "nationality": "Portuguese",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "Christian Schumann",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mikko Heikkinen",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Henry Bond",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Markku Komonen",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stuart Brisley",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Don Brown",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Helen Chadwick",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Tracey Emin",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Angus Fairhurst",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 2008,
        "count": 5
    },
    {
        "name": "Liam Gillick",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Gary Hume",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Sam Taylor-Wood",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Boyd Webb",
        "nationality": "New Zealander",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Biggers",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Franz West",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 2012,
        "count": 24
    },
    {
        "name": "Elia Zenghelis",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zoe Zenghelis",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Laurids Ortner",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Manfred Ortner",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marc Tsurumaki",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jonathan Crary",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joel Sanders",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Blossfeldt",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Norman Foster",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Wines",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harry C. Wolf",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wolf D. Prix",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Helmut Swiczinsky",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Winy Maas",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elizabeth Alford",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ray Maggiore",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vince Scirano",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ron Steiner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Xaveer de Geyter",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rody Graumans",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hella Jongerius",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tejo Remy",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Arian Brekveld",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jos van der Meulen",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marcel Wanders",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paolo Rizzatto",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Maurizio Ficcadenti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Moreno",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 26
    },
    {
        "name": "Florence Knoll",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1917,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Patti Smith",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Bent Hedegaard Nobert",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Montse Padrós i Marqués",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carlos Riart",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ben Faydherbe",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wout De Vringer",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ton Homburg",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert A. M. Stern",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephen Cassell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pieter Laurens Mol",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Waltercio Caldas",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Lari Pittman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Nancy Rubins",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Nicola Tyson",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Lex Reitsma",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Will De L'ecluse",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jan van Toorn",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Patrick Ireland",
        "nationality": "Irish",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 2008,
        "count": 1
    },
    {
        "name": "Rudolf Stingel",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Carol Hepper",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yasumasa Morimura",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lies Ros",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rob Schroder",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul McCarthy",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 13
    },
    {
        "name": "Roger Ballen",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ellen Gallagher",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Steve Wolfe",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 2016,
        "count": 3
    },
    {
        "name": "Yvonne Droge-Wendel",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elaine Lustig Cohen",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1927,
        "endYear": 2016,
        "count": 1
    },
    {
        "name": "Frank Paulin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Dieter Appelt",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shimon Attie",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Per Bernsten",
        "nationality": "Norwegian",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bruce Charlesworth",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Goldes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Geoffrey Biddle",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rudy Burckhardt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Carl Martin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Floris M. Neusüss",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Melissa Ann Pinney",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gabrielle Russomagno",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shelly Rusten",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christer Strömholm",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Aldo Rossi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Franz Erhard Walther",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 85
    },
    {
        "name": "Joel Leivick",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eduardo Arroyo",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Horst P. Horst",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Fred Tomaselli",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Sylvie Fleury",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Antonio Muntadas",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alyson Shotz",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Martin Walde",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kara Walker",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 21
    },
    {
        "name": "Michael Joaquin Grey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Equipo Crónica",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Peter Nadin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rafael Solbes",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Barbara Blondeau",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 1974,
        "count": 0
    },
    {
        "name": "Adam Bartos",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yukinori Yanagi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kevin Bubriski",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Terry Evans",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jay DeFeo",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 1989,
        "count": 5
    },
    {
        "name": "Nicholas Grimshaw",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eric Rondepierre",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Spear",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "François Kollar",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Herbert List",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "John Milisenda",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Margaret Moulton",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Susan Weil",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Frank Sellitto",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wojciech Prazmowski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mike and Doug Starn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jay Wolke",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jerry Schatzberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carolee Schneemann",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 2019,
        "count": 4
    },
    {
        "name": "Graham Smith",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hélio Oiticica",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 1980,
        "count": 8
    },
    {
        "name": "Paul Vanderbilt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Catherine Wagner",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mogens Balle",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Sven Dalsgaard",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Georg Meistermann",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Emil Kiess",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hubertus von Pilgrim",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rigaud Benoit",
        "nationality": "Haitian",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Dieudonne Cedor",
        "nationality": "Haitian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Gabriel Alix",
        "nationality": "Haitian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Gabriel Lévêque",
        "nationality": "Haitian",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jacob Burck",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Russell Limbach",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Robert Walters",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Frederick Franck",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 2006,
        "count": 1
    },
    {
        "name": "Stephan Balkenhol",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Douglas Gordon",
        "nationality": "Scottish",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Andreas Gursky",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jerry Cooke",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Imi Knoebel",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Sarah Lucas",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1962,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Juan Muñoz",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 2001,
        "count": 4
    },
    {
        "name": "Jean-Luc Mylayne",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "VALIE EXPORT",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Cady Noland",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 39
    },
    {
        "name": "Vik Muniz",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kirill Rozhdestvenskii",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alexander Slussarev",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Franco Zecchin",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roman Signer",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Struth",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jeff Wall",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Uta Barth",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tria Giovan",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kurt Kranz",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Gerd Bonfert",
        "nationality": "Romanian",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Manuel Valdes",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rose Mandel",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1910,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Peter Klasen",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Stampfli",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ava Gerber",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sean Mellyn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jonathan Seliger",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Katy Schimert",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Diana Michener",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bernard Rancillac",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Raphael Montañez Ortiz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dörte Eissfeldt",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sharon Harper",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sanjeev Saith",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bastienne Schmidt",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Colette Alvarez Urbajtel",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nicholas Dean",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rachel Harrison",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Herb Snitzer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ilkka Laine",
        "nationality": "Finnish",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Seiichi Furuya",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vladimir Syomin",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Amy Arbus",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Accra Shepp",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bertien van Manen",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Power Boothe",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Naomi Boretz",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marvin Brown",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ruth Eckstein",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1916,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Ward Jackson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2004,
        "count": 1
    },
    {
        "name": "Jane Logemann",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hiroshi Murata",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Beatrice Riese",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1917,
        "endYear": 2004,
        "count": 6
    },
    {
        "name": "Ce Roser",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Teiji Furuhashi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Bill Viola",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Maya Ying Lin",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tom Slaughter",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "An-My Lê",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kenzo Okada",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1982,
        "count": 1
    },
    {
        "name": "René Ravo",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Vivian Cherry",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1920,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Valdir Cruz",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rudolf Schwarzkogler",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 1969,
        "count": 2
    },
    {
        "name": "Charles Long",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mikhail Razulevich",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1993,
        "count": 1
    },
    {
        "name": "Werner Rohde",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Ian Wallace",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mariana Yampolsky",
        "nationality": "Mexican",
        "gender": "Female",
        "startYear": 1925,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Not Vital",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jill Baroff",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "William Kentridge",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 19
    },
    {
        "name": "Maria Serebriakova",
        "nationality": "Russian",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Lola Alvarez Bravo",
        "nationality": "Mexican",
        "gender": "Female",
        "startYear": 1907,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Roger Cutforth",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hope Sandrow",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bogdan Achimescu",
        "nationality": "Romanian",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Deborah Oropallo",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Terry Adkins",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Rona Pondick",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Louise Lawler",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joseph Grigely",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jonas Mekas",
        "nationality": "Lithuanian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2019,
        "count": 1
    },
    {
        "name": "Luca Buvoli",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Neil M. Denari",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Werner Aisslinger",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wendell Castle",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Tom Dixon",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jacques Lowe",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles Moore",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Lew Thomas",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "José Leonilson",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 1993,
        "count": 5
    },
    {
        "name": "Jean-Michel Othoniel",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elaine Reichek",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mira Schendel",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1919,
        "endYear": 1988,
        "count": 47
    },
    {
        "name": "Sarah Sze",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dennis Hopper",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Lisa Yuskavage",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1962,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Chris Ofili",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 14
    },
    {
        "name": "Jim Shaw",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 27
    },
    {
        "name": "Gary Simmons",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Katharina Bosse",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ernest Cole",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Yevgeny Khaldei",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Ivan Shagin",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Aleksandr Ustinov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Georgi Zelma",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "interware SARL",
        "nationality": "French",
        "gender": "Male",
        "startYear": 2000,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jasper Morrison",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Serge Mouille",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Jean Prouvé",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Bas Oudt",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cedric Price",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Michael Webb",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mathias Ungers",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Franco Albini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1977,
        "count": 0
    },
    {
        "name": "Simon Ungers",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Constantin Boym",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Laurene Leon Boym",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Antonio Citterio",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Glen Oliver Löw",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lyn Godley",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marc Newson",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Johannes Potente",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1987,
        "count": 0
    },
    {
        "name": "Paul Schudel",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hans Maier-Aichen",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sebastian Bergne",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Santina Bonini",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Claudio Cesar",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bob Evans",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gordon Randall Perry",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joseph Forakis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eric Goetz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lawrence Lam",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lloyd Schwan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Ernesto Spicciolato",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Takeshi Ishiguro",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Doug Olson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christopher Connell",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Noriaki Okabe",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sanford Redmond",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Scott Riegleman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marc Sadler",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Belkis Ayón",
        "nationality": "Cuban",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Fiona Banner",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Karen Kilimnik",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Wayman R. Spence",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giorgio Gurioli",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Francesco Scansetti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bradford Bissell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles S. Anderson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tracey Moffatt",
        "nationality": "Australian",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elizabeth Peyton",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 15
    },
    {
        "name": "Ugo Rondinone",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Wolfgang Tillmans",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hans-Peter Feldmann",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luba Lukova",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ralph Schraivogel",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marlene Streeruwitz",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wiel Arets",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dominique Perrault",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yoshio Taniguchi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Osvaldo Borsani",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Hans Coray",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Arnold Crane",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jorge Ribalta",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Oliver Wasow",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cai Guo-Qiang",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jitka Hanzlová",
        "nationality": "Czech",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Otto Muehl",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Fritz Schleifer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1977,
        "count": 1
    },
    {
        "name": "Gerhard Rühm",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Merry Alpern",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Geismar",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bruce Blackburn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "Thomas Mohr",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gordon Parks",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "John Szarkowski",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Gosbert Adler",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Doig",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 13
    },
    {
        "name": "Bing Wright",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christopher Bucklow",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Miyako Ishiuchi",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julia Fish",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Suzan Frecon",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 12
    },
    {
        "name": "Marcia Hafif",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 2018,
        "count": 3
    },
    {
        "name": "Marco Breuer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hilla Becher",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1934,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Jeanne Dunning",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eve Andrée Laramée",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Osamu Shiihara",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1974,
        "count": 0
    },
    {
        "name": "Paolo Soleri",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2013,
        "count": 2
    },
    {
        "name": "Oscar Niemeyer",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Sugiura Kohei",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrea Branzi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yona Friedman",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Ron Herron",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Rodolfo Machado",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Max Peintner",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark Bennett",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Ray Charles",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julia Jacquette",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fabian Marcaccio",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mariko Mori",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Massimo Scolari",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julian Opie",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jorge Silvetti",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gianni Braghieri",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jorge Pardo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 12
    },
    {
        "name": "Cornelia Parker",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Shoji Sadao",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles Spurrier",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Allyson Strafella",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Rineke Dijkstra",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Goin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wilhelm Ebentreich",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Detlef Fiedler",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Daniela Haufe",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Siegfried Jablonsky",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sophie Alex",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Guglielmo Berchicci",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Konstantin Grcic",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Thrall Soby",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Kunié Sugiura",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vera Lutter",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stan Douglas",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andreas Slominski",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wijnanda Deroo",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Beat Streuli",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stefano Giovannoni",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Asher",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 2012,
        "count": 1
    },
    {
        "name": "Boris Mikhailov",
        "nationality": "Ukrainian",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gordon Bunshaft",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Marty Kohn",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jeroen Thomas",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Garciella Torre",
        "nationality": "Spanish",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Willem Jan Neutelings",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Brigitte Kochta",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luc Reuse",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eleanor Antin",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1935,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Micha Bar-Am",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Laurenz Berges",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "E. V. Day",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Guy Bourdin",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Catherine Opie",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Inka Essenhigh",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Eberhard Havekost",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 2019,
        "count": 1
    },
    {
        "name": "James Welling",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Wesely",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lygia Pape",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1927,
        "endYear": 2004,
        "count": 2
    },
    {
        "name": "Shahzia Sikander",
        "nationality": "Pakistani",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 10
    },
    {
        "name": "Do Ho Suh",
        "nationality": "Korean",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lynne Yamamoto",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Byrne",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nari Ward",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kevin Appel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Gabriele Nothhelfer",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Helmut Nothhelfer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giovanni Anselmo",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Pino Pascali",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 1968,
        "count": 0
    },
    {
        "name": "John Rauch",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Denise Scott Brown",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thom Mayne",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alessandro Magris",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roberto Magris",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gian Piero Frassinelli",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Adolfo Natalini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cristiano Toraldo di Francia",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michelle Stone",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Emilio Sousa",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alessandro Poli",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hajo Rose",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Anton Stankowski",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Richard Gangle",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ken Grant",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paula Horn Kotis",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1922,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Martin Manz",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alessandra Sanguinetti",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Susan Unterberg",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harry Shunk",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "František Kalivoda",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Mac Adams",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zdeněk Rossmann",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Solomon Telingater",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1969,
        "count": 4
    },
    {
        "name": "Armando Testa",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Doug Aitken",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Vanessa Beecroft",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yves José Zimmerman",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gérard Miedinger",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wes Wilson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Jake Chapman",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Dinos Chapman",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Klaus Staeck",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eryk Lipinski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Jacek Neugebauer",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edward Fella",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Walter Diethelm",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Josef Flejšar",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Zdenĕk Ziegler",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nicola Costantino",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Hirschhorn",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 33
    },
    {
        "name": "Horst Hoheisel",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Joo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dan Olson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kerry James Marshall",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 20
    },
    {
        "name": "Jason Rhoades",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 2006,
        "count": 1
    },
    {
        "name": "Lisa Ruyter",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Billingham",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Janine Antoni",
        "nationality": "Bahamian",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Peter Bradley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jose Antonio Fernández-Muro",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Henry Markowitz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Pipilotti Rist",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ken Miller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Mantel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leslie Sweet",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Frank Benedict",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marco Castillo",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Alexandre Arrechea",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Dagoberto Rodriguez",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Eberhard Marhold",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christa Ehrlich",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1903,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Esteban Francés",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1976,
        "count": 1
    },
    {
        "name": "Remedios Varo",
        "nationality": "Spanish",
        "gender": "Female",
        "startYear": 1908,
        "endYear": 1963,
        "count": 1
    },
    {
        "name": "Fred Mayer",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Morris",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Emmanuel Yevzerikhin",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Georgy Khomzor",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Renate Grindler",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Van Hamersveld",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "José Toirac",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Polly Apfelbaum",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Maurizio Cattelan",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Diana Thater",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Overby",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 1993,
        "count": 68
    },
    {
        "name": "Victor Ancona",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Leo Gantenbein",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Winfried Holtz",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Siegfried Himmer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hans Buschfeld",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Heinz Lippert",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Coort von Mannstein",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hans Michel",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Brian Tattersfield",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shirin Neshat",
        "nationality": "Iranian",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nicolaus Ott",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alfred Bernard Stein",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kilian Breier",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eleanore Bujatti",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1919,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Miroslaw Balka",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Betty Goodwin",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1923,
        "endYear": 2008,
        "count": 2
    },
    {
        "name": "Éva Kémeny",
        "nationality": "Hungarian",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Billy Sullivan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Efim Mezhbarg",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1937,
        "count": 0
    },
    {
        "name": "Lorenzo Goñi",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Juan Antonio Morales",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Francis Alÿs",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 86
    },
    {
        "name": "Pierre Fix-Masseau",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Josep Subirats Samora",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Marek Freudenreich",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hans Hartmann",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bonnie Maclean",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Alton Kelley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Emil Ruder",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1970,
        "count": 0
    },
    {
        "name": "Werner Zryd",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pierre Gauchat",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1956,
        "count": 0
    },
    {
        "name": "Maciej Zbikowski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anibal Gil",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Makoto Saito",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kevin Dwyer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Günter Zamp Kelp",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Klaus Pinter",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carl Jacobs",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giovanni Patrone",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1963,
        "count": 0
    },
    {
        "name": "Harri Koskinen",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Philippe Starck",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Akihiro Kaneko",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yoshihiro Kimura",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Keiji Otani",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bruce Ancona",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Louis Henry",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ron Arad",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marc Berthier",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cesare Casati",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Art Fry",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Grete Jalk",
        "nationality": "Danish",
        "gender": "Female",
        "startYear": 1920,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Andries Van Onck",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shichiro Imatake",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "John Tang",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Cazalet",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Russell Crotty",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Merce Cunningham",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2009,
        "count": 4
    },
    {
        "name": "John Currin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 11
    },
    {
        "name": "Margherita Manzelli",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Jodiah Jacobs",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dieter Rams",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jockum Nordström",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Mikael Björnstjerna",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fernando Campana",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Humberto Campana",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Godtfred Kirk Christiansen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Louis Maslow",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1973,
        "count": 0
    },
    {
        "name": "Hércules Barsotti",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2010,
        "count": 16
    },
    {
        "name": "David Bates",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Estella Conwill Majozo",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Bock",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 15
    },
    {
        "name": "Christopher Brown",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cecily Brown",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Janet Cardiff",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Willys de Castro",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1988,
        "count": 7
    },
    {
        "name": "Varvara Rodchenko",
        "nationality": "Russian",
        "gender": "Female",
        "startYear": 1925,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Anna Maria Maiolino",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 30
    },
    {
        "name": "Christian Marclay",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Beatriz Milhazes",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Takashi Murakami",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Gillian Wearing",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pae White",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Richard Hell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nedko Solakov",
        "nationality": "Bulgarian",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Dan Walsh",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rudolf Bonvie",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christopher Giglio",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eddie Adams / Associated Press",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Dominique Berretty/Black Star Publishing Co.",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1925,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "Neal Boenzi/The New York Times",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rene Portocarrero",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1985,
        "count": 27
    },
    {
        "name": "Arthur Brower/The New York Times",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1974,
        "count": 0
    },
    {
        "name": "Brown Brothers",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pat Burns/The New York Times",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1973,
        "count": 0
    },
    {
        "name": "Chuck Burton/Associated Press",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anthony Camerano/Associated Press",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1973,
        "count": 0
    },
    {
        "name": "Don Hogan Charles/The New York Times",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alan Chin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bill Cunningham/The New York Times",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Francesca Woodman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Vic Delucia/The New York Times",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Fehim Demir/Agence France-Presse",
        "nationality": "Bosnian",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Drew/Associated Press",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sam Falk/The New York Times",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "John Filo/Valley Daily News via Associated Press",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stanley J. Forman/Boston Herald via Associated Press",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carl T. Gosset Jr./The New York Times",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Art Greenspon/Associated Press",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Horst Faas",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Susan Harris/The New York Times",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eddie Hausner/The New York Times",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bruce Hoertel/The New York Times",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Hubbard/Black Star Publishing Company",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barney Ingoglia/The New York Times",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bob Jackson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edward Keating/The New York Times",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "Seydou Keïta",
        "nationality": "Malian",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Mike Lien/The New York Times from CBS News",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 1977,
        "count": 0
    },
    {
        "name": "Jay King",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Meyer Liebowitz/The New York Times",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Herbert Krenchel",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Pelzel",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Larry C. Morris/The New York Times",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1987,
        "count": 0
    },
    {
        "name": "Richard Rothman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Günther Ssymmank",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Martine Barrat",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter G. Kayafas",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Keith Pannel/Mail on Sunday",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Maria Martinez-Cañas",
        "nationality": "Cuban",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Glen Pettit/The New York Times",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Igor Moukhin",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Douglas C. Pizac/Associated Press",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kent Porter/The Press Democrat via Assocated Press",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Riger",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Osamu Kanemura",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Librado Romero/Associated Press",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Demand",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joe Rosenthal/Times World Wide Photos",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William E. Sauro/The New York Times",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Cliff Schiappa/Associated Press",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sammy Schulman/International News Photo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "Barton Silverman/The New York Times",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ernie Sisto/The New York Times",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Captain Cecil W. Stoughton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Ray Stubblebine/Associated Press",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kryn Taconis/Magnum Photos Co.",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George Tames/The New York Times",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Glenn Rudolph",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Caroline Feyt",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mario Cravo Neto",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Jean Christian Bourcart",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Walker/The New York Times",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Marilynn K. Yee/The New York Times",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bert Six",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1967,
        "count": 0
    },
    {
        "name": "Freda Diamond",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1905,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Heinreich Löffelhardt",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Anne Marie Fishbein",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Matthias Hoch",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Curt Richter",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carlo Mazzeri",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lutz Dille",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Georgina Starr",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Katherine Turczan",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tom Wood",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Raymond Riley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Shen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ralph Lysell",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hans Gösta Thames",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dario Montagni",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sergio Berizzi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Cesare Butté",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jens H. Quistgaard",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Michio Arai",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William Campbell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giovanni Bassi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Saul Nesbitt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Franca Stagi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kikukawa Katsuyoshi",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Martijn Wegman",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Mascheroni",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gianni Passini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ernst Lieser",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William M. Heynes",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Juan Herreros",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Iñaki Ábalos",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hugo Herdeg",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1953,
        "count": 0
    },
    {
        "name": "Terence Riley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yushi Uehara",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kazuyo Sejima",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Renzo Piano",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alfred Roth",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Alfred H. Barr, Jr.",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Hilde Hubbuch",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1905,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Arturo Schwarz",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alvin Lustig",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1955,
        "count": 0
    },
    {
        "name": "Gerard van de Groenekan",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "René d'Harnoncourt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1968,
        "count": 7
    },
    {
        "name": "Jan Lewitt",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Wolf Zimmerman",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anthon Beeke",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tapio Salmelainen",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alastair Reid",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Frank O'Hara",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1966,
        "count": 0
    },
    {
        "name": "Niele Toroni",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Liz Larner",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Guido Ballo",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "César Moro (Alfredo Quíspez Asín)",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1956,
        "count": 1
    },
    {
        "name": "Jean-Clarence Lambert",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hans Sahl",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "André Verdet",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Thomas Merton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1968,
        "count": 0
    },
    {
        "name": "Morris Weisenthal",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Harold Norse",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Peter Viereck",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Theodore Roethke",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1963,
        "count": 0
    },
    {
        "name": "David Ignatow",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Harold Rosenberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Dylan Thomas",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1953,
        "count": 0
    },
    {
        "name": "Richard Wilbur",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Peter Schjeldahl",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Godfrey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Boris Zemenkov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1963,
        "count": 0
    },
    {
        "name": "Bradbury Thompson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Edwin Morgan",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "A. K. Barutchev",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "William S. Burroughs",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Billy Klüver",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2004,
        "count": 1
    },
    {
        "name": "Russell Lynes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Shuzo Takiguchi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Ben Langlands",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nikki Bell",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael McClure",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michel Seuphor",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Kiki (Kiki O. K.) Kogelnik",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1935,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Anne Waldman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Janice Guy",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Irene Delano",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1919,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Nancy Holt",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 2014,
        "count": 3
    },
    {
        "name": "Shirley C. Burden",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Jon Hendricks",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wayne Miller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Richard Benson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Robert Storr",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul A. McDonough",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert F. Sheehan, 3rd",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1969,
        "count": 0
    },
    {
        "name": "Earle Brown",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Hannah Wilke",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 1993,
        "count": 18
    },
    {
        "name": "Bernardo Bertolucci",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Diane von Furstenberg",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "K. H. Hödicke",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Arthur Köpcke",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 1977,
        "count": 22
    },
    {
        "name": "Ernst Caramelle",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "John Wilson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Jürgen Klauke",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rudi Molacek",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Krebber",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Hans Danuser",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tim Rollins",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Forrest Myers",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Winston Roeth",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Nicole Eisenman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Annette Rosado",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "José Parissi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George Garces",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Angel Abreu",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joseph De Pace",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Shrigley",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Hans Weigand",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hans-Ulrich Obrist",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Blackburn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Alfredo Boulton",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Larry Mantello",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Paul E. Hultberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "René Block",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "AA Bronson",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Baldwin",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George Maciunas",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 1978,
        "count": 205
    },
    {
        "name": "Issey Miyake",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jack Pierson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 12
    },
    {
        "name": "Jim Hodges",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Kazuo Nakamura",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Masayuki Nagare",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Natsuyuki Nakanishi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Ennio Chiggio",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Manfredo Massironi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Roger Riche",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Brian Alexander",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Teppo Asikainen",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luigi Baroli",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ayse Birsel",
        "nationality": "Turkish",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nick Butler",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "matali crasset",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Markus Nevalainen",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rick Galezowski",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kari Sivonen",
        "nationality": "Finnish",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jens Risom",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Fritz Haller",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sam Hecht",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chris Chapman",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Sandbach",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jay Kyu Lee",
        "nationality": "Korean",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hajime Sorayama",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Albert Nagele",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kimberly J. Holden",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gregg A. Pasquarelli",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Maarten Van Severen",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Christopher R. Sharples",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hani Rashid",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lise Anne Couture",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marvin E. Newman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hiroji Yamada",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Franz Ackermann",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Ingrid Calame",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Hendrik Van Keppel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Taylor Green",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Iran do Espirito Santo",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 20
    },
    {
        "name": "Brad Kahlhamer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Olga De Amaral",
        "nationality": "Colombian",
        "gender": "Female",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Laura Owens",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 9
    },
    {
        "name": "Manfred Pernice",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 15
    },
    {
        "name": "Alessandro Pessoli",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Shirazeh Houshiary",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Dan Asher",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 2010,
        "count": 2
    },
    {
        "name": "Nancy Haynes",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "David Jeffrey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Linda Lynch",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Bruno Rousselot",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Mark Sheinkman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Juul Kraijer",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Chris Finley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Art Zaaijer",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Xaveer de Geijter",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Georges Heinz",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Heike Lohmann",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alex Wall",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christophe Cornubert",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ramon Klein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gro Bonesmo",
        "nationality": "Norwegian",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julien Monfort",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yo Yamagata",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jeanne Gang",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chris Dondorp",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark Wamble",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrew Zago",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lúcio Costa",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Carlos Leão",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Jorge Machado Moreira",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Affonso Eduardo Reidy",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1964,
        "count": 0
    },
    {
        "name": "Ernâni Vasconcelos",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Kevin Roche",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "John Dinkeloo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Paul Schweikher",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Mark Mills",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Matthew Ritchie",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 9
    },
    {
        "name": "Darren Almond",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jørn Utzon",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Jim Lambie",
        "nationality": "Scottish",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Acharya Vyakul",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2000,
        "count": 4
    },
    {
        "name": "William Sharples",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Max Lenz",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George Krikorian",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1977,
        "count": 0
    },
    {
        "name": "David Walter Beck",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Laylah Ali",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Davor Vrankic",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 33
    },
    {
        "name": "Klaus Oberer",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark Lombardi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 2000,
        "count": 20
    },
    {
        "name": "Tacita Dean",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "William H. Johnson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1970,
        "count": 1
    },
    {
        "name": "Ibrahim Miranda",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sarah Morris",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Siena",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 18
    },
    {
        "name": "Jessica Stockholder",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Ken Lum",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barbara Ambrosz",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tibor Kalman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Anthony Maglica",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carl Hugo Pott",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Thomas Leeser",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Preston Scott Cohen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Maltzan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Caroline Bos",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ronan Bouroullec",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Erwan Bouroullec",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Claudy Jongstra",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yuh Okano",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Henk Stallinga",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Spencer Silver",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Per-Olof Nyström",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Karin Apollonia Müller",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Craigie Horsfield",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jose Burges",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Delgado",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Lulo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Nelson Montes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carlos Rivera",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christina Ramberg",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 1995,
        "count": 4
    },
    {
        "name": "Elsa Fischer-Treyden",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1901,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Margret Hildebrand",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1917,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Il'ia Chashnik",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1929,
        "count": 3
    },
    {
        "name": "Viola Gråsten",
        "nationality": "Swedish",
        "gender": "Female",
        "startYear": 1910,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Nikolai Sokolov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Ivan Leonidov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1959,
        "count": 0
    },
    {
        "name": "Marianne Breslauer",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1909,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Lotte Beese",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1903,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Jacques-André Boiffard",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1961,
        "count": 0
    },
    {
        "name": "Edmund Collein",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Heinz Loew",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1981,
        "count": 1
    },
    {
        "name": "Erich Comeriner",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1978,
        "count": 1
    },
    {
        "name": "Horacio Coppola",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Wenda Gu",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Semyon Fridlyand",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1964,
        "count": 0
    },
    {
        "name": "Jaroslava Hatláková",
        "nationality": "Czech",
        "gender": "Female",
        "startYear": 1904,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Irene Hoffmann",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1903,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Roman Karmen",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Imre Kinszki",
        "nationality": "Hungarian",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1945,
        "count": 0
    },
    {
        "name": "Fred Korth",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Jiří Lehovec",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Eli Lotar",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1969,
        "count": 0
    },
    {
        "name": "Werner Mantz",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Lee Miller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1977,
        "count": 0
    },
    {
        "name": "Jean Moral",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Gregor Schneider",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stefan Themerson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "František Vobecký",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Wanda Wulz",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1903,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Willy Zielke",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Gertrud Arndt",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1903,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Lore Feininger",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1901,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Andreas Walser",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1930,
        "count": 0
    },
    {
        "name": "Jean Painlevé",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Jürgen Mayer H.",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sebastian Finckh",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tokujin Yoshioka",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giulio Iacchetti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Matteo Ragni",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Miriam Bäckström",
        "nationality": "Swedish",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Oliver Boberg",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edward Burtynsky",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Maria Magdalena Campos-Pons",
        "nationality": "Cuban",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Paul Etienne Lincoln",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Elinor Carucci",
        "nationality": "Israeli",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yinka Shonibare",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles Corte",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1961,
        "count": 0
    },
    {
        "name": "John Waters",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Porfiry Krylov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Michaël Borremans",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Mark Manders",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Ernesto Neto",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Chloe Piene",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Reinhard Mucha",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chéri Samba",
        "nationality": "Congolese",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ryan McGinness",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julie Mehretu",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Roxy Paine",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jason Middlebrook",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Huang Yong Ping",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 2019,
        "count": 1
    },
    {
        "name": "Daniel Zeller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Joey Kötting",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jerome Neuner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Yoshitomo Nara",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 143
    },
    {
        "name": "Gilbert Proesch",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Robert Beck",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Jonathan Hammer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Arturo Herrera",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 14
    },
    {
        "name": "Denise Green",
        "nationality": "Australian",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 10
    },
    {
        "name": "Jonathan Ive",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sergius Ruegenberg",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Enrique Montenegro",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Marlon Castellanos",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sandra Ramos",
        "nationality": "Cuban",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John F. Simon, Jr.",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ruth Root",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Paul Noble",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Jessica Diamond",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Olafur Eliasson",
        "nationality": "Icelandic",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Rodney Graham",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 2022,
        "count": 1
    },
    {
        "name": "Alex Gama",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yun-Fei Ji",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Kim Young Jin",
        "nationality": "Korean",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Markus Freitag",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Daniel Freitag",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Scheibitz",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 12
    },
    {
        "name": "Konstanty Gutschow",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Robert James Leonetti",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Dyson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fernando Bryce",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Wolfram Peters",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Sluijter",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Camille van den Brande",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Groeneveld",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ginny Bishton",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Kai Althoff",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 13
    },
    {
        "name": "Charles LeDray",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Susan Hambleton",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sang-Ah Choi",
        "nationality": "Korean",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Benjamin Edwards",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Abel Barroso",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pierre Huyghe",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Michael Raedecker",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Angela Bulloch",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lebbeus Woods",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Sigríður Björnsdóttir",
        "nationality": "Icelandic",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robin Page",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Kalil",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "John Wood",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Jesse Reiser",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mako Idemitsu",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Li Yongbin",
        "nationality": "Chinese",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kristin Lucas",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zhang Peili",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Philip Vincent",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Phil Irving",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Alex Moulton",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Marco Maggi",
        "nationality": "Uruguayan",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Greg Lynn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Critical Art Ensemble",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1987,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Louise Wilson",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jane Wilson",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marina Abramović",
        "nationality": "Serbian",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Seoungho Cho",
        "nationality": "Korean",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Pfeiffer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Piotr Wyrzykowski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cedric Buchet",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Simone Nieweg",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Josiah McElheny",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alejandro Ruiz",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Valérie Belin",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Collier Schorr",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Childs",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Francesco Binfaré",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Claude Closky",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Polidori",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cheryl Donegan",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1962,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Peter Callas",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Pilson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Melinda Morey",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Malick Sidibé",
        "nationality": "Malian",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Bob Olodort",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bodo Rasch",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Fred Wilson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Rainer Werner Fassbinder",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Leandro Katz",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 9
    },
    {
        "name": "Álvaro Barrios",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Claudio Perna",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Regina Silveira",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Federico Fellini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Fabrice Hybert",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Glen Luchford",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vincente Minnelli",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Agnès Varda",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1928,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Orson Welles",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Moshekwa Langa",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Verne Dawson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Sid Kaplan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Todd Greene",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julio Plaza",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Dinh Q. Lê",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "José Antonio Suárez Londoño",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 16
    },
    {
        "name": "Alan Ritchie",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Franco Annoni",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Fang Lijun",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Boris Berlin",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carme Pinos",
        "nationality": "Spanish",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Adriaan Geuze",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chris Johanson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Chan",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Lars Tunbjörk",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Hong Hao",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Murray Broom",
        "nationality": "New Zealander",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nick Schade",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andy Diaz Hope",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alfred W. Fielding",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Enric Bernat i Fontlladosa",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Otto Wichterle",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Walter Frederick Morrison",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Warren Franscioni",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1974,
        "count": 0
    },
    {
        "name": "Noel Zeller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Herman Goelitz Rowland",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kenji Ekuan",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Forrest Mars",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Richard James",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Betty James",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1918,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Erik Wallenberg",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Daniel F. Cudzik",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eve Aschheim",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Brad Brown",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Hideo Kanbara",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kenneth Capps",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Anne Chu",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 2016,
        "count": 3
    },
    {
        "name": "Max Cole",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Elena del Rivero",
        "nationality": "Spanish",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Lee Etheredge IV",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Kendra Ferguson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Cheryl Goldsleger",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Teo González",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Christine Hiebert",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Ann Ledy",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Julia Mangold",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Stefana McClure",
        "nationality": "Irish",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Stephen Metts",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Tatsuo Miyajima",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Deborah Gottheil Nehmad",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Gloria Ortiz-Hernández",
        "nationality": "Colombian",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Laurie Reid",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Erik Saxon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Susan Schwalb",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zwelethu Mthethwa",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joan Waltemath",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "William E. Massie",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Victoria Sambunaris",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Erwin Pfrang",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 9
    },
    {
        "name": "D-L Alvarez",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Lindy Roy",
        "nationality": "South African",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marc Räder",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Enrique Metinides",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2022,
        "count": 0
    },
    {
        "name": "Johan Niegeman",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1977,
        "count": 0
    },
    {
        "name": "Kumi Machida",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Teodoro González de León",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Walton Ford",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark Dion",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 14
    },
    {
        "name": "Neo Rauch",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Peter Frankfurt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mikon van Gastel",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kevin Kennon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Farshid Moussavi",
        "nationality": "Iranian",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alejandro Zaera-Polo",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lygia Clark",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1920,
        "endYear": 1988,
        "count": 10
    },
    {
        "name": "Gyula Kosice (Fernando Fallik)",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Alfredo Barbini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Tord Boontje",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Emma Woffenden",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Haresh Lalvani",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ryue Nishizawa",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ole Scheeren",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Landy",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Jeanne Moutoussamy-Ashe",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Valentina Kulagina",
        "nationality": "Russian",
        "gender": "Female",
        "startYear": 1902,
        "endYear": 1987,
        "count": 1
    },
    {
        "name": "Alan Herman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mac Gramlich",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Guy Nordenson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Victoria Gil",
        "nationality": "Spanish",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kutlug Ataman",
        "nationality": "Turkish",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Solange Fabião",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Heike Baranowsky",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Graham Little",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jennifer Pastor",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Barry McGee",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Marcel Dzama",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 26
    },
    {
        "name": "Trenton Doyle Hancock",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kosuke Tsumura",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cindy van den Bremen",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gerhard Heufler",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jens Martin Skibsted",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roelf Mulder",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Karim Rashid",
        "nationality": "Egyptian",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Matthias Megyeri",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mathieu Lehanneur",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marcus Willcocks",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carolien Vlieger",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hein van Dam",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anthony Dunne",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fiona Raby",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Rakowitz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Martín Ruiz de Azúa",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roy Arden",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tim Lee",
        "nationality": "Korean",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robin Rhode",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Melanie Smith",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kazuhiro Yamanaka",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gray Foy",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2012,
        "count": 1
    },
    {
        "name": "Hill Jephson Robb",
        "nationality": "Scottish",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Kirps",
        "nationality": "Luxembourger",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ralph Borland",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carl Mertens",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Naoto Fukasawa",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Olivier Peyricot",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Duncan Turner",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elizabeth A. Wilkes",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nayland Blake",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Rivane Neuenschwander",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Deborah Adler",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Craig Grimes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Masamichi Udagawa",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sigi Moeslinger",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barry Frydlender",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tomoko Sawada",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vernon Pascoe",
        "nationality": "New Zealander",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Jakob Wagner",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephan Augustin",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wangechi Mutu",
        "nationality": "Kenyan",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Mark Grotjahn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 14
    },
    {
        "name": "Dave Muller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Galya Rosenfeld",
        "nationality": "Israeli",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Johannes Kahrs",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Rainald Goetz",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 9
    },
    {
        "name": "Richard Dupont",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Todd MacAllen",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephanie Forsythe",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Enric Miralles",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Lionel Theodore Dean",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ward Shelley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Edmunds",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lisa Fromartz",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Philippe Parreno",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Paul-Armand Gette",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Christiane Baumgartner",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sandile Goje",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Norman Catherine",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Hodgins",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Diane Victor",
        "nationality": "South African",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dan Rakgoathe",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Abel Auer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dorota Jurczak",
        "nationality": "Polish",
        "gender": "Female",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Armin Krämer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ted Muehling",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nomathemba Tana",
        "nationality": "South African",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Claudette Schreuders",
        "nationality": "South African",
        "gender": "Female",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sue Williamson",
        "nationality": "South African",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jo Ractliffe",
        "nationality": "South African",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Grayson Perry",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Naúl Ojeda",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Robert Siwangaza",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bob Noorda",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Benjamin Rivera",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Helen Marden",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harvey Tulcensky",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Caleb Crye",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eric Fehlberg",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gregg Thompson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Twan Verdonck",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Braco Dimitrijević",
        "nationality": "Bosnian",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andreas Vogler",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Arturo Vittori",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrew Ive",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Geraldo de Barros",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Emily Jacir",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Shirana Shahbazi",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Raqib Shaw",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Martin Szekely",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carlos Garaicoa",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mary Weatherford",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Phillip Pisciotta",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Einar Hareide",
        "nationality": "Norwegian",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Amanda Knox Sather",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kim Hoffmann",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sara Shaughnessy",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Martin Creed",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Tomma Abts",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Scoli Acosta",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Lucas Ajemian",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Ricci Albenda",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Hurvin Anderson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Reed Anderson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Siegfried Anzinger",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Ryoko Aoki",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1973,
        "endYear": 0,
        "count": 18
    },
    {
        "name": "Philip Argent",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Carla Arocha",
        "nationality": "Venezuelan",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Martin Assig",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Nina Lola Bachhuber",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Monika Baer",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Chris Ballantyne",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Joan Banach",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Claire Barclay",
        "nationality": "Scottish",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Hernan Bas",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Gary Batty",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Michael Gerald Bauer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1982,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Jarrod Beck",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Julie Becker",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 2016,
        "count": 3
    },
    {
        "name": "Dirk Bell",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Matthew Benedict",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Ellen Berkenblit",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Rebecca Bird",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1973,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Norbert Bisky",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 14
    },
    {
        "name": "Cosima von Bonin",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1962,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Monica Bonvicini",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 12
    },
    {
        "name": "Carol Bove",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Andrea Bowers",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Marc Brandenburg",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 17
    },
    {
        "name": "Sebastiaan Bremer",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Beth Brideau",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Alex Brown",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Maria Brunner",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1962,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Berlinde De Bruyckere",
        "nationality": "Belgian",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Fritz Bultman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1985,
        "count": 1
    },
    {
        "name": "A.K. Burns",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Kathy Butterly",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Peter Cain",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 1997,
        "count": 2
    },
    {
        "name": "Ernesto Caivano",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Jonathan Callan",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Beth Campbell",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Davide Cantoni",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Robert Chambers",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Chivas Clem",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Dawn Clements",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 2018,
        "count": 2
    },
    {
        "name": "Michael Cline",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 9
    },
    {
        "name": "Prince Toffee",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Nigel Cooke",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Bjorn Copeland",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Marti Cormand",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Benjamin Cottam",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 10
    },
    {
        "name": "Ann Craven",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Amy Cutler",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1974,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Björn Dahlem",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "José Damasceno",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Meredith Danluck",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Ele D'Artagnan (Michele Lombardi-Toscanini)",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1987,
        "count": 5
    },
    {
        "name": "Jaime Davidovich",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2016,
        "count": 4
    },
    {
        "name": "Xiomara De Oliver",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Peter Dean",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 1993,
        "count": 3
    },
    {
        "name": "Benjamin Degen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Brian DeGraw",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 12
    },
    {
        "name": "Kirsten Deirup",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1980,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Damien Deroubaix",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Steve DiBenedetto",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Don Doe",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Dr. Lakra (Jerónimo López Ramírez)",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 12
    },
    {
        "name": "Jim Drain",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Martin Eder",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Marcel van Eeden",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 9
    },
    {
        "name": "Thomas Eggerer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Lori Ellison",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Neil Farber",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 11
    },
    {
        "name": "Rachel Feinstein",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Chad Ferber",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Dan Fischer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Urs Fischer",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Christian Flamm",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Caio Fonseca",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Sideo Fromboluti",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2014,
        "count": 2
    },
    {
        "name": "Barnaby Furnas",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Franziska Furter",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Ingeborg Gabriel",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 1996,
        "count": 10
    },
    {
        "name": "Tim Gardner",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Amy Gartrell",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1974,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Anna Gaskell",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Tamara Gayer",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Isa Genzken",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Steve Gianakos",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Ewan Gibbs",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Graham Gillmore",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Bruno Gironcoli",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2010,
        "count": 1
    },
    {
        "name": "Victoria Gitman",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Judith Godwin",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 2021,
        "count": 2
    },
    {
        "name": "Anthony Goicolea",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Greg Goldberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Delia R. Gonzalez",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 11
    },
    {
        "name": "Dryden Goodwin",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Sam Gordon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Matt Greene",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Alex Grey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Kojo Griffin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Karl Haendel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Chris Hammerlein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Debra Hampton",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Richard Hawkins",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Gerald Hayes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Werner Haypeter",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Karen Heagle",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1974,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Lothar Hempel",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jonathan Herder",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Jörg Herold",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Christine Hill",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Roger Hiorns",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "John Hodany",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Julian Hoeber",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Christian Holstad",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 39
    },
    {
        "name": "Louise Hopkins",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Violet Hopkins",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1973,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Jonathan Horowitz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Shelby Hughes",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1981,
        "endYear": 2014,
        "count": 6
    },
    {
        "name": "Jim Isermann",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Jun Iseyama",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jason Jägel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Gareth James",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Aaron Johnson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Jane Kaplowitz",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Kerstin Kartscher",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Axel Kasseböhmer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Kurt Kauper",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Martin Kersels",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Raoul De Keyser",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2012,
        "count": 1
    },
    {
        "name": "Thomas Kiesewetter",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "John Kleckner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Tom Knechtel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Philip Knoll",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Takehito Koganezawa",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Terence Koh",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "David Korty",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Uwe Kowski",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Friedrich Kunath",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Peter Land",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Wes Lang",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "William Leavitt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 11
    },
    {
        "name": "Marie Luise Lebschik",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Ghariokwu Lemi",
        "nationality": "Nigerian",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Charlene Liu",
        "nationality": "Taiwanese",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Andrew Lord",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Nate Lowman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Cotter Luppi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "John Lurie",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Michael Magnan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Frank Magnotta",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Ati Maier",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1962,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Monica Majoli",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Ján Mančuška",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 2011,
        "count": 3
    },
    {
        "name": "Marepe",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Knox Martin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Alfredo Martinez",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Yuri Masnyj",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Nick Mauss",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1980,
        "endYear": 0,
        "count": 41
    },
    {
        "name": "Carey Maxon",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1978,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Keith Mayerson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Dan McCarthy",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Kim McCarty",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Keegan McHargue",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1982,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Lucy McKenzie",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Kelly McLane",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Roy McMakin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Jason Meadows",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jonathan Meese",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Birgit Megerle",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Bjarne Melgaard",
        "nationality": "Norwegian",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Matthew Monahan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Jonathan Monk",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Meredith Monk",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Teresa Moro",
        "nationality": "Spanish",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Paul Morrison",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Aaron Morse",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Sandeep Mukherjee",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "J.P. Munro",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Scott Myles",
        "nationality": "Scottish",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Rei Naito",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Tracy Nakayama",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1974,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Graham Nickson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Carsten Nicolai",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Frank Nitsche",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "David Noonan",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "John J. O'Connor",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Marcel Odenbach",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 10
    },
    {
        "name": "Paulina Olowska",
        "nationality": "Polish",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "David Opdyke",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Renato Orara",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Christopher Orr",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Sally Osborn",
        "nationality": "Scottish",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Paul P.",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 27
    },
    {
        "name": "Gina Pane",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 1990,
        "count": 1
    },
    {
        "name": "Erik Parker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Ester Partegàs",
        "nationality": "Spanish",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Seb Patane",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Toby Paterson",
        "nationality": "Scottish",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Joyce Pensato",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 2019,
        "count": 3
    },
    {
        "name": "Simon Periton",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Jenny Perlin",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Philippe Perrot",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Ara Peterson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Kembra Pfahler",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Jerry Phillips",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Richard Phillips",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Gabriele Picco",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 17
    },
    {
        "name": "Jody Pinto",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jane Piper",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1916,
        "endYear": 1991,
        "count": 1
    },
    {
        "name": "Magnus Plessen",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Marjetica Potrč",
        "nationality": "Slovenian",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Peggy Preheim",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Tobias Putrih",
        "nationality": "Slovenian",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Alessandro Raho",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Tyson Reeder",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Daniel Richter",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Michael S. Riedel",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Michael Rodriguez",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Kirstine Roepstorff",
        "nationality": "Danish",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Clare Rojas",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Matthew Ronay",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Alexander Ross",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Eva Rothschild",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Gavin R. Russom",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Riiko Sakkinen",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 19
    },
    {
        "name": "Hiroe Saeki",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1978,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Mamoru Sakagawa",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Sam Salisbury",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Wilhelm Sasnal",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 13
    },
    {
        "name": "Yehudit Sasportas",
        "nationality": "Israeli",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Shinako Sato",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Matt Saunders",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 9
    },
    {
        "name": "David Scher",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Jörg Schlick",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 2005,
        "count": 3
    },
    {
        "name": "Mathias Schmied",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Lara Schnitger",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Dana Schutz",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Michael Scoggins",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Rachel Selekman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Randall Sellers",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Bettina Sellmann",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "SEO (Seo Soo-Kyoung)",
        "nationality": "Korean",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "David Shaw",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Simone Shubuck",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Andreas Siekmann",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Paul Sietsema",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Amy Sillman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Elizabeth Simonson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Daniel Sinsel",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Sal Sirugo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Lucy Skaer",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Torsten Slama",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Zak Smith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Stephen Sollins",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Glenn Sorensen",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 10
    },
    {
        "name": "Nora Speyer",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1923,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Joseph Stalnaker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Frances Stark",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Lyle Starr",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 9
    },
    {
        "name": "Peter Stauss",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 30
    },
    {
        "name": "Clare Stephenson",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Xenon Straub",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Katja Strunz",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Peter Sullivan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Tony Swain",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Ricky Swallow",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Ena Swansea",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Spencer Sweeney",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 15
    },
    {
        "name": "Josephine Taylor",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Yuken Teruya",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Cheyney Thompson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 17
    },
    {
        "name": "Tom of Finland",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1991,
        "count": 5
    },
    {
        "name": "Hayley Tompkins",
        "nationality": "Scottish",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Tam Van Tran",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Lane Twitchell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Donald Urquhart",
        "nationality": "Scottish",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Lesley Vance",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Chris Vasell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 11
    },
    {
        "name": "Nicolau Vergueiro",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jean-Luc Verna",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Banks Violette",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Reinhard Voigt",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Charline von Heyl",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Kelley Walker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Sarah Walker",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Sam Watters",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Peter Wegner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Paloma Varga Weisz",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Andro Wekua",
        "nationality": "Georgian",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Olav Westphalen",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Benji Whalen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "John Cerasulo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jon Widman",
        "nationality": "Korean",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "T.J. Wilcox",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Christopher Williams",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Johannes Wohnseifer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "William Wood",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Lynne Woods Turner",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Richard Wright",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Katharina Wulff",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Amelie von Wulffen",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Erwin Wurm",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Ralf Ziervogel",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Chris Hanson",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Hendrika Sonnenberg",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Claudia Müller",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Julia Müller",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Dennis Loesch",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Wade Guyton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Chris Marker",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Katerina Lanfranco",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1978,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Jon Pylypchuk",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 14
    },
    {
        "name": "Christian Schwarzwald",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 11
    },
    {
        "name": "Michelle Segre",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Dannielle Tegeder",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Jay D. Sorensen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stefan Kürten",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dasha Shishkin",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Klaus Rosburg",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ed Kilduff",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Manuel Saez",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mika Rottenberg",
        "nationality": "Israeli",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Oliver Payne",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nick Relph",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cristina Iglesias",
        "nationality": "Spanish",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carole Benzaken",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Steve McQueen",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Keith Tyson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Webster",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Swoon",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Orly Genger",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Aaron Young",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tom Wiscombe",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eija-Liisa Ahtila",
        "nationality": "Finnish",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Anastassiades",
        "nationality": "Cypriot",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barnabas Wetton",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elle-Mie Ejdrup Hansen",
        "nationality": "Danish",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Frederiksen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lorraine Gamman",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sture Johannesson",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hiroyuki Tazawa",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Al Heenan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Sigurd Melin",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark Williams",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Maria Jauhiainen",
        "nationality": "Finnish",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Milan Knížák",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 33
    },
    {
        "name": "Peter Kogler",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chad McCail",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dan Perjovschi",
        "nationality": "Romanian",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Leonid Tishkov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Herman de Vries",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Mangelos (Dimitrije Bašičević)",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1987,
        "count": 7
    },
    {
        "name": "Eli Marmar",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrew Burroughs",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dickon Isaacs",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stacy Benjamin",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dick Grant",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Grimley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jerry O'Leary",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anton Schubert",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Amy Schwartz",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul South",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eric Sugalski",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Kravitz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Douglas Schein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Brassil",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shigekazu Tanaka",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chikara Ohtagaki",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Niels Due Jensen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kanji Hongu",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ken Solomon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Marsha Cottrell",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Douglas Kolk",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Bernhard Martin",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Rosalind Nashashibi",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1973,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Susan Turcot",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Hanneline Visnes",
        "nationality": "Norwegian",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Elliott Hundley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Thomas Locher",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Winka Dubbeldam",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Matthew Buckingham",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anri Sala",
        "nationality": "Albanian",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leonor Caraballo",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jen DeNike",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Oliver Michaels",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Patricia Urquiola",
        "nationality": "Spanish",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eliana Gerotto",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Abou Farman",
        "nationality": "Iranian",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fabio Lenci",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Lees",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jeanne Silverthorne",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Daisy Youngblood",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Francesca Gabbiani",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Clifford Ross",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Juan Domingo Santos",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fernando Martín Menis",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Francisco Leiva Ivorra",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luis M. Mansilla",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Emilio Tuñón",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Enric Ruiz-Geli",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nicola López",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Álvaro Siza",
        "nationality": "Portuguese",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fujiwara Dai",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yukiyo Matsushita",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jeff Davis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Xu Bing",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michel Majerus",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Ulli Maier",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Resika",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Coosje van Bruggen",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 2009,
        "count": 1
    },
    {
        "name": "Noriko Furunishi",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jim Campbell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Slater Bradley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kota Ezawa",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stanley Brouwn",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2017,
        "count": 23
    },
    {
        "name": "Scott McFarland",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jonathan Barnbrook",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Markus Muntean",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Adi Rosenblum",
        "nationality": "Israeli",
        "gender": "Female",
        "startYear": 1962,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Carmen Herrera",
        "nationality": "Cuban",
        "gender": "Female",
        "startYear": 1915,
        "endYear": 2022,
        "count": 0
    },
    {
        "name": "Tim Bavington",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Adam Dant",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gerard Petrus Fieret",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Matthew Brannon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bernard Frize",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Katharina Grosse",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Heinrich Riebesehl",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Guy Tillim",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Janos Frecot",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jeremy Deller",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rudy VanderLans",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zuzana Licko",
        "nationality": "Slovak",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yaron Leshem",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ruy Ohtake",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nicolas Lampert",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Scott King",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark Fox",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Sze Tsung Leong",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Olaf Nicolai",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sérgio Camargo",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "John Maeda",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Heimo Zobernig",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ivana Keser",
        "nationality": "Croatian",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mikhael Subotzky",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bob Kane",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1998,
        "count": 2
    },
    {
        "name": "Julije Knifer",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2004,
        "count": 4
    },
    {
        "name": "Anders Petersen",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gert Tobias",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Uwe Tobias",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Rafael Lozano-Hemmer",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barbara Probst",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jules Spinatsch",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chris Miner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carlos Amorales",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Adrian Paci",
        "nationality": "Albanian",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Clemens von Wedemeyer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ernie Gehr",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Al Hansen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1995,
        "count": 3
    },
    {
        "name": "Runa Islam",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thiago Rocha Pitta",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Artur Zmijewski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Patrick Norguet",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stefan Sagmeister",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Patrick Jouin",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luisa Cevese Riedizioni",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Glenn Brown",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Amanda Levete",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Janne Kyttanen",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joachim Schönfeldt",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Conrad Botes",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Reynold Reynolds",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Patrick Jolley",
        "nationality": "Irish",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Anton Kannemeyer",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gastone Rinaldi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fred W. McDarrah",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Uli Aigner",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hüseyin Bahri Alptekin",
        "nationality": "Turkish",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Pawel Althamer",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ruth Anderwald",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leonhard Grond",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fareed Armaly",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Isabelle Arthuis",
        "nationality": "Belgian",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sule Attems",
        "nationality": "Turkish",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christy Astuy",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fatih Aydogdu",
        "nationality": "Turkish",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zeigam Azizov",
        "nationality": "Azerbaijani",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ricardo Basbaum",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Baumann",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Erick Beltran",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bodo Hell",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Blum",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Max Boehme",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ecke Bonk",
        "nationality": "Egyptian",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luchezar Boyadjiev",
        "nationality": "Bulgarian",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tim Brennan",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rosa Brückl",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gregor Schmoll",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Frédéric Bruly Bouabré",
        "nationality": "Ivorian",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2014,
        "count": 481
    },
    {
        "name": "Pavel Büchler",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mircea Cantor",
        "nationality": "Romanian",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Georg Chaimowicz",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Hoy Cheong",
        "nationality": "Malaysian",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Clegg and Guttmann",
        "nationality": "Irish",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nathan Coley",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Patrick Corillon",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Oliver Croy",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Minerva Cuevas",
        "nationality": "Mexican",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roberto Cuoghi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julius Deutschbauer",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gerhard Spring",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ingar Dragset",
        "nationality": "Norwegian",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Elmgreen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Heinrich Dunst",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jimmie Durham",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 2021,
        "count": 1
    },
    {
        "name": "Daniel Egg",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Memed Erdener",
        "nationality": "Turkish",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ayse Erkmen",
        "nationality": "Turkish",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harun Farocki",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Gyula Fodor",
        "nationality": "Hungarian",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrea Fraser",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ali Gedik",
        "nationality": "Turkish",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marcus Geiger",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jochen Gerz",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jef Geys",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Simryn Gill",
        "nationality": "Malaysian",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Dominique Gonzalez-Foerster",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Heribert Schiedel",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Franz Graf",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dejan Grba",
        "nationality": "Serbian",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Renée Green",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fritz Grohs",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Elisabeth Grübl",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mauricio Guillen",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jens Haaning",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Maria Hahnenkamp",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mary Heilmann",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Hil de Gard",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Susan Hiller",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Noritoshi Hirakawa",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Hoeck",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "C. & I. Hohenbüchler",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carsten Höller",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edgar Honetschläger",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Aleksandar Battista Ilic",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sanja Iveković",
        "nationality": "Croatian",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cameron Jamie",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Anna Jermolaewa",
        "nationality": "Russian",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Birgit Jürgenssen",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 2003,
        "count": 2
    },
    {
        "name": "Sejla Kameric",
        "nationality": "Bosnian",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Helmut Kandl",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Johanna Kandl",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Herwig Kempinger",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alexander Kluge",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jan Knap",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jakob Kolding",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Reed Kram",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elke Krystufek",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hans Küng",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gabriel Kuri",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gerhard Lang",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vincent Leow",
        "nationality": "Singaporean",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Erik van Lieshout",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Hubert Lobnig",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marko Lulic",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dolores Zinny",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Boris Manner",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dorit Margreiter",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michaela Math",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephen Mathewson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leonore Mau",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1916,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lloyd de Mause",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Milin",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "J. Abbott Miller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Otto Mühl",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Christian Philipp Müller",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Oswald Oberhuber",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Walter Obholzer",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Melik Ohanian",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edith Payer",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paola Pivi",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Martin Prinzhorn",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "August Ruhs",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Florian Pumhösl",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Doron Rabinovici",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "L.A. Raeven",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kerim Ragimov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Navin Rawanchaikul",
        "nationality": "Thai",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jacques Le Rider",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jeroen de Rijke",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Willem de Rooij",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Rock",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Susan Sellers",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Viktor Rogy",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Naomi Tereza Salmon",
        "nationality": "Israeli",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Sandbichler",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bülent Sangar",
        "nationality": "Turkish",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eran Schaerf",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hans Scheugl",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Markus Schinwald",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hubert Schmalix",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ferdinand Schmatz",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Walter Schmögner",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Allan Sekula",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Jeffrey Shaw",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wolf Singer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sean Snyder",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kim Sooja",
        "nationality": "Korean",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dr. Gerfried Sperl",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dominik Steiger",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bruce Sterling",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kamen Stoyanov",
        "nationality": "Bulgarian",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ingeborg Strobl",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marius Watz",
        "nationality": "Norwegian",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Weibel",
        "nationality": "Ukrainian",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Krzysztof Wodiczko",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Michel Würthle",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cerith Wyn Evans",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jun Yang",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tiehai Zhou",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Beatrix Zobl",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andreas Zybach",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cornel Windlin",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles Atlas",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bill Morrison",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Monika Sosnowska",
        "nationality": "Polish",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Thorne",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pierre Bourdieu",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Jurgen Habermas",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harald Szeemann",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Paul Virilio",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Koo Jeong-a",
        "nationality": "Korean",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Daniel Suter",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Armand Mevis",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Linda van Deursen",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Neal Ascherson",
        "nationality": "Scottish",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edi Rama",
        "nationality": "Albanian",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christoph Schlingensief",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Daniel Blochwitz",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pavel Humhal",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roman Ondak",
        "nationality": "Slovak",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Miklos Erhardt",
        "nationality": "Hungarian",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stano Filko",
        "nationality": "Slovak",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Julius Koller",
        "nationality": "Slovak",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Allan Currall",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Hvorecky",
        "nationality": "Slovak",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jiri Skala",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alexander Felch",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Markus Hanakam",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roswitha Schuller",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1984,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Johanna Rille",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pirmin Blum",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Karin Aue",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Magda Tothova",
        "nationality": "Slovak",
        "gender": "Female",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Katharina Ivansich",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Manuel Gorkiewicz",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jakob Neulinger",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Johanna Billing",
        "nationality": "Swedish",
        "gender": "Female",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean Baudrillard",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Michel Onfray",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gilles Deleuze",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Deimantas Narkevicius",
        "nationality": "Lithuanian",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Manfred Grübl",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nancy Grossman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Marcel Bovis",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Jeroen Verhoeven",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rob Voerman",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yang Fudong",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Sennhauser",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1978,
        "count": 1
    },
    {
        "name": "Francisco Castro Leñero",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Akihiko Kotani",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tsuguyoshi Sadashima",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hank Bull",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alexander Taylor",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Coren Sharples",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barbara Bosworth",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ursula von Rydingsvard",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michel Parmentier",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "André Cadere",
        "nationality": "Romanian",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Ceal Floyer",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cory Arcangel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nina Bovasso",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gregory Green",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christoph Ruckhäberle",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Santiago Cucullu",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joëlle Tuerlinckx",
        "nationality": "Belgian",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Albert York",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nasreen Mohamedi",
        "nationality": "Indian",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 1990,
        "count": 3
    },
    {
        "name": "Jorge Macchi",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Yutaka Sone",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Louise Campbell",
        "nationality": "Danish",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mamma Andersson",
        "nationality": "Swedish",
        "gender": "Female",
        "startYear": 1962,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Victor Grippo",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Robert Altman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Max Miedinger",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "Philippe Apeloig",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Craig Hodgetts",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Mangurian",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giorgio Ceretti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pietro Derossi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Muafangejo",
        "nationality": "Namibian",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 1987,
        "count": 0
    },
    {
        "name": "Shaun O'Dell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Glen Baldridge",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rhonda Abrams",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ruth Orkin",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1921,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Beth B",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dan Boord",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luis Valdovino",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephen R. Johnson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Shirley Clarke",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1919,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Joseph Chaiken",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Sam Shepard",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jem Cohen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cecelia Condit",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ximena Cuevas",
        "nationality": "Mexican",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roberto Gabetti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Aimaro Oreglia d'Isola",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ed Emshwiller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Hermine Freed",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Masaki Fujihata",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean-Luc Godard",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2022,
        "count": 0
    },
    {
        "name": "Anne-Marie Miéville",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shalom Gorewitz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Faith Hubley",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1924,
        "endYear": 2001,
        "count": 3
    },
    {
        "name": "Jim Jarmusch",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tom Kalin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Kos",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Spike Lee",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mary Lucier",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sidney Lumet",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Linda Montano",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yvonne Rainer",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Susan Raymond",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alan Raymond",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alain Resnais",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marlon Riggs",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Shelly Silver",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lisa Steele",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Sturgeon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rea Tajiri",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stan VanDerBeek",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1984,
        "count": 5
    },
    {
        "name": "Captain Beefheart (Don Van Vliet)",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Steina",
        "nationality": "Icelandic",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Woody Vasulka",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Julie Zando",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Stezaker",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dan Miller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Matthew Carter",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert del Naja",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tony Conrad",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 2016,
        "count": 1
    },
    {
        "name": "Berni Searle",
        "nationality": "South African",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kohei Yoshiyuki",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 2022,
        "count": 0
    },
    {
        "name": "Olav Christopher Jenssen",
        "nationality": "Norwegian",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 48
    },
    {
        "name": "Lin Tianmiao",
        "nationality": "Chinese",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tanyth Berkeley",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leslie Hewitt",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Steven B. Smith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark Lewis",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mathias Bengtsson",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jeremy Blake",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Alex Ku",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark Wallinger",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rolf Harder",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Ernst Roch",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Dara Friedman",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joanne Tatham",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Tom O'Sullivan",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Seth Price",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kate Gilmore",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Natascha Sadr Haghighian",
        "nationality": "Iranian",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Louisiana Bendolph",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mary Lee Bendolph",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rebecca Warren",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jerszy Seymour",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roberto Feo",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rosario Hurtado",
        "nationality": "Spanish",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Judith de Graauw",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Esterio Segura",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Budnitz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tristan Eaton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Frank Kozik",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Deph",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kenzo Minami",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Huck Gee",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chip Lord",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Doug Michels",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Zhang Huan",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bas Jan Ader",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Liz Deschenes",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nikolai Sedelnikov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1994,
        "count": 4
    },
    {
        "name": "Keith Arnatt",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "David Askevold",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Gianfredo Camesi",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Kenichiro Ina",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yutaka Matsuzawa",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2006,
        "count": 23
    },
    {
        "name": "David Robilliard",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 1988,
        "count": 5
    },
    {
        "name": "Jaap Berghuis",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alan Charlton",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nalini Malani",
        "nationality": "Indian",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Santiago Pol",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ron Galella",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Maljković",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 9
    },
    {
        "name": "Eduardo Stupía",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Sandra Gamarra",
        "nationality": "Peruvian",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Julia Lohmann",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ben Fry",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Noam Toran",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Auger",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jimmy Loizeau",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bernd Ribbeck",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Moritz Waldemeyer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Oki Sato",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eyal Burstein",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michele Gauler",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Susana Soares",
        "nationality": "Portuguese",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Philip Worthington",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ammar Eloueini",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joris Laarman",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Neri Oxman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nhem Ein",
        "nationality": "Cambodian",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Samuel Cabot Cochran",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Benjamin Aranda",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chris Lasch",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Oded Ezer",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James King",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tomáš Gabzdil Libertíny",
        "nationality": "Slovak",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Simon Heijdens",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rachel Wingfield",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mathias Gmachl",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barrett Lyon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Matthew Pillsbury",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lisa Strausfeld",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christian Marc Schmidt",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Takaaki Okada",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eben Eliason",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Walter Bender",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marco Pesenti Gritti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jonathan Harris",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sep Kamvar",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fernanda Bertini Viégas",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Martin Wattenberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Evan Roth",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Theo Watson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nicholas Negroponte",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mary Lou Jepsen",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yves Béhar",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tim Edler",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Crispin Jones",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gregor Zivic",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Olaf Breuning",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bernhard Fruehwirth",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jan Ritsema",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Maja Bajevic",
        "nationality": "Bosnian",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Scott Christensen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nicolas Roope",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Laura Kurgan",
        "nationality": "South African",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eric Cadora",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Reinfurt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sarah Williams",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Josh Smith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rebecca Allen",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michail Bletsas",
        "nationality": "Greek",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "V. Michael Bove",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Brian Belott",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paulo Mendes da Rocha",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "Carlo Bimbi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark Bradford",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Joshua Mosley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leo Matiz",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Elmer Bernstein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Leszek Holdanowicz",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Willie Doherty",
        "nationality": "Irish",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lonnie van Brummelen",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Irma Boom",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Cahen",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark Leckey",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paolo Canevari",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Will Duke",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vuyile Voyiya",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Senzeni Marasela",
        "nationality": "South African",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gianni Ferrara",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nilo Gioacchini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles Nkosi",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Aleksandra Mir",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Georgianna Stout",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Geoffrey Mann",
        "nationality": "Scottish",
        "gender": "Male",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Hubley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1977,
        "count": 2
    },
    {
        "name": "Ivan Serpa",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1973,
        "count": 1
    },
    {
        "name": "Jan De Cock",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Trisha Donnelly",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1974,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Huma Bhabha",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alejandro Puente",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2013,
        "count": 5
    },
    {
        "name": "Abraham Palatnik",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Sam Durant",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Andy Goldsworthy",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Sara VanDerBeek",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kurt Schmidt",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Antonio Manuel",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Richard Jost",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jack Strange",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1984,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Steven Parrino",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 2005,
        "count": 1
    },
    {
        "name": "David Bowie",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Alina Szapocznikow",
        "nationality": "Polish",
        "gender": "Female",
        "startYear": 1926,
        "endYear": 1973,
        "count": 12
    },
    {
        "name": "Wieki Somers",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Benjamin Wheeler Howes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Juan Melé",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Teddy Cruz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Hutten",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zhang Dali",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zoe Leonard",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Candida Höfer",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rong Rong",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Huang Yan",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Georg Teltscher",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Jonas Wood",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Sigalit Landau",
        "nationality": "Israeli",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dean Edgington",
        "nationality": "New Zealander",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dara Birnbaum",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yael Bartana",
        "nationality": "Israeli",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gianluigi Banfi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1945,
        "count": 0
    },
    {
        "name": "Lodovico Barbiano di Belgiojoso",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Enrico Peressutti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Ernesto Nathan Rogers",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1969,
        "count": 0
    },
    {
        "name": "Jennifer Allora",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Guillermo Calzadilla",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Sharits",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 1993,
        "count": 11
    },
    {
        "name": "Svetlana Alpers",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marine Hugonnier",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "James Hyde",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ben Rose",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "Katy Grannan",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Xiao Lu",
        "nationality": "Chinese",
        "gender": "Female",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jamie Isenstein",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Guy de Cointet",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 1983,
        "count": 6
    },
    {
        "name": "Isaac Julien",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zeilinger",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Beckermann",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gilbert Bretterbauer",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Martin Bruch",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Endlicher",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Groting",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Haim Steinbach",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charlotte Posenenske",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 1985,
        "count": 4
    },
    {
        "name": "Paolo Deganello",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Massimo Morozzi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dario Bartolini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lucia Bartolini",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sabine Hornig",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anthony McCall",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "El Anatsui",
        "nationality": "Ghanaian",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Miao Xiaochun",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Luna",
        "nationality": "Native American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Julius Shulman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Sterling Ruby",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Diango Hernandez",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Justine Kurland",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ai Weiwei",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hai Bo",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hong Lei",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mary Beth Edelson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1933,
        "endYear": 2021,
        "count": 6
    },
    {
        "name": "Sheng Qi",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Weng Fen",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zheng Guogu",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ercole Brini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Philip Glass",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Tino Sehgal",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jackson Mac Low",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2004,
        "count": 8
    },
    {
        "name": "Wardell Milan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Lida Abdul",
        "nationality": "Afghan",
        "gender": "Female",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Trisha Brown",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1936,
        "endYear": 2017,
        "count": 10
    },
    {
        "name": "Jessica Mein",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leo Villareal",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kalup Linzy",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George Bures Miller",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ulay",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Marc Camille Chaimowicz",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shu Lea Cheang",
        "nationality": "Taiwanese",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stevenson J. Palfi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Joan La Barbara",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Akira Kurosaki",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alex Weil",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Angel Velasco Shaw",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ardele Lister",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Arthur Ginsberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ayumi Shino",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barbara Buckner",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bill Seaman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bob Giraldi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bob Sabiston",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Branda Miller",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Brice Dellsperger",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Claude Beller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Colin Campbell",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Dale Hoyt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Daniel Reeves",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Davidson Gigliotti",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Diane Bonder",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Dieter Meier",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dmitry Troitsky",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Donigan Cumming",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elsa Cayo",
        "nationality": "Peruvian",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Frank Gillette",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fred Simon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Graeme Whifler",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gregg Bordowitz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Greg Durbin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Helen De Michiel",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hitoshi Nomura",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Irit Batsry",
        "nationality": "Israeli",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Byrne",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jane Veeder",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joan Logue",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Reilly",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "John Sanborn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jon Alpert",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jud Yalkut",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Judith Barry",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jules Backus",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Keiko Tsuno",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ken Feingold",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ken Kobland",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kevin Godley",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kyoko Michishita",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lol Crème",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mao Kawaguchi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark Magill",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Matthew Geller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Nesmith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Smith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mickey McGowan",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Miranda July",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nancy Buchanan",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Neil Goldberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Norman Cohn",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Olga Stolpovskaya",
        "nationality": "Russian",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Dougherty",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Harrison",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Ryan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Peter Care",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Péter Forgács",
        "nationality": "Hungarian",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Phyllis Baldino",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pia Lindman",
        "nationality": "Finnish",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pratibha Parmar",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ralph Hocking",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Foreman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Lowenstein",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Farber",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Rocky Morton",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sadie Benning",
        "nationality": "American",
        "gender": "Non-Binary",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shi Jian",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Simone Forti",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1935,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Skip Blumberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Skip Sweeney",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Spike Jonze",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stefan Moore",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephen Beck",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephen Laub",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Steve Reinke",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stuart Marshall",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Stuart Sherman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Teodoro Maus",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thierry Kuntzel",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Thomas Dolby",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Toni Basil",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tsuneo Nakai",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ulrike Rosenbach",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vanalyne Green",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Victor Masayesva, Jr.",
        "nationality": "Native American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William Dear",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yasuo Shinohara",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zacharias Kunuk",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zbigniew Rybczynski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mick Rock",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julien Temple",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tony Cokes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tony Labat",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tim Pope",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean Baptises-Mondino",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gus Van Sant",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rudi Dolezal",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hannes Rosacher",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark Romanek",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sam Bayer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Malachi Farrell",
        "nationality": "Irish",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Seamus Farrell",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Otto E. Rossler",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Efrain Almeida",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gerhard Grossing",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Raimond Chaves",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Gilda Mantilla",
        "nationality": "Peruvian",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Heinz Gappmayr",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gertrud Fischbacher",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harrell Fletcher",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Josephine Meckseper",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cao Guimarães",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cao Fei",
        "nationality": "Chinese",
        "gender": "Female",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Lamelas",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Batiste Madalena",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Shannon Ebner",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Babette Mangolte",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sergej Jensen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Aernout Mik",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harry Dodge",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stanya Kahn",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yui Kugimiya",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Seher Shah",
        "nationality": "Pakistani",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Walid Raad",
        "nationality": "Lebanese",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joachim Koester",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alex Hubbard",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Beca Lipscombe",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Amar Kanwar",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "José Resende",
        "nationality": "Brazilian",
        "gender": "male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bani Abidi",
        "nationality": "Pakistani",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Roehr",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 1968,
        "count": 1
    },
    {
        "name": "Liza Béar",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jarbes Lopes",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 9
    },
    {
        "name": "Enrico David",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Linder [Linda Sterling]",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Patty Chang",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Derek Jarman",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Alexander Roitburd",
        "nationality": "Ukrainian",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alix Pearlstein",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anne Ramsden",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barbara Hammann",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bernar Hébert",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bruce Yonemoto",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Norman Yonemoto",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Cara DeVito",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Catalina Parra",
        "nationality": "Chilean",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chris Cunningham",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Aphex Twin",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Claudia Von Alemann",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Daizaburo Harada",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Haruhiko Shono",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dan Sandin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Danièle Nyst",
        "nationality": "Belgian",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Jacques Louis Nyst",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Danielle Jaeggi",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Claerbout",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Cort",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dennis Day",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dimitrije Martinovic",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Donald Fagen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Doug Hall",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Doug Melnyck",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Duan Jinchuan",
        "nationality": "Chinese",
        "gender": "Female",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ed Bowes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edward Rankus",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elizabeth van der Zaag",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ellen Spiro",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Enrique Aguerre",
        "nationality": "Uruguayan",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eric Metcalfe",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ernest Gusella",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Francesc Torres",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fujiko Nakaya",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hakudo Kobayashi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gabor Body",
        "nationality": "Hungarian",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Garth Jennings",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gloria Camiruaga",
        "nationality": "Chilean",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Pablo Lavin",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gusztáv Hámos",
        "nationality": "Hungarian",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Guy Ben-Ner",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hans Breder",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hironori Terai",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hitoshi Takekiyo",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ian Breakwell",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Beryl Korot",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jan Peacock",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean Carlomusto",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean-Baptiste Mondino",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jeanette Reinhardt",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jöelle de la Casinière",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Adams",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jorge Furtado",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julie Gustafson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jun Okazaki",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Emi Segawa",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Kathy High",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Katsuhiro Yamaguchi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Katsuya Taka",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Keigo Yamamoto",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kit Galloway",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sherrie Rabinowitz",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Klaudia Kemper",
        "nationality": "Chilean",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Klaus vom Bruch",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Heike-Melba Fendel",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ko Nakajima",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Koen Theys",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kumiko Kushiyama",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kunio Noda",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Laura Cottingham",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Li Hong",
        "nationality": "Chinese",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Liang Yue",
        "nationality": "Chinese",
        "gender": "Female",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Linda Goode Bryant",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Laura Poitras",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kim Tomczak",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lu Chunsheng",
        "nationality": "Chinese",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lyn Blumenthal",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Madelon Hooykaas",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elsa Stansfield",
        "nationality": "Scottish",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Marcela Poch",
        "nationality": "Chilean",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marcelo Tas",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fernando Meirelles",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Max Almy",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Maxi Cohen",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michel Gondry",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Morihiro Wada",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Nan Hoover",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Naoko Tosa",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nelson Henricks",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nestor Olhagaray",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nobuhiro Kawanaka",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Noriyuki Okuda",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Osamu Nagata",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dimitri Devyatkin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Wong",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paulette Phillips",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Geoffrey Shea",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Philip G. Atwell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dr. Dre",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Philip Virus",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alec Empire",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rafael França",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Robert Morin",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roman Coppola",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roman Maskalev",
        "nationality": "Kyrgyz",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Maxim Boronilov",
        "nationality": "Kyrgyz",
        "gender": "Male",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ros Barron",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dalibor Martinis",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sara Diamond",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Satoshi Ono",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shoichiro Azuma",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shuntaro Tanikawa",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shuji Terayama",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Steve Fagin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Susan Rynard",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tetsuo Mizuno",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tirtza Even",
        "nationality": "Israeli",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tom Sherman",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vern Hume",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vincent Carelli",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wang Jianwei",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Xu Zhen",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yang Zhenzhong",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yau Ching",
        "nationality": "Chinese",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zhang Yuan",
        "nationality": "Chinese",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Perry Hoberman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sibylle Bergemann",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Willem de Ridder",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "German Bobe",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Henry Flynt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 35
    },
    {
        "name": "James Victore",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Esther Bubley",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1921,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Hock E Aye Vi Edgar Heap of Birds",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Matteo Bazzicalupo",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Raffaella Mangiarotti",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Clemens Weisshaar",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christoph Weisshaar",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jorre van Ast",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tabaimo",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Lotty Rosenfeld",
        "nationality": "Chilean",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Eder Santos",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alan Scarritt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sandra Llano-Mejia",
        "nationality": "Colombian",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rita Myers",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "François Girard",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alexander Isley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Silvia Bächli",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 12
    },
    {
        "name": "Frank Barkow",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Regine Leibinger",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Blake Rayne",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christian Jankowski",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gedi Sibony",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jordi Canudas",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Klara Liden",
        "nationality": "Swedish",
        "gender": "Female",
        "startYear": 1979,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Lutz Mommartz",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrei Monastyrski",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ken Loach",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jim Gaylord",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Tipping",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robin Day",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Judit Reigl",
        "nationality": "Hungarian",
        "gender": "Female",
        "startYear": 1923,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Feng Mengbo",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Erich Mrozek",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1993,
        "count": 1
    },
    {
        "name": "Moris (Israel Meza Moreno)",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 10
    },
    {
        "name": "Peter Downsbrough",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Robert H. Bergman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Matt Connors",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Beatriz González",
        "nationality": "Colombian",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 16
    },
    {
        "name": "Ingrid Wiener",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 57
    },
    {
        "name": "Suzanne Lacy",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Frank Lupo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Daniel Rowen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Alison Knowles",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1933,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Sue de Beer",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shay Alkalay",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jonathan Runcio",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lene Berg",
        "nationality": "Norwegian",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rosa Loy",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zachary Wollard",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Amy Granat",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrea Modica",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Toshiko Okanoue",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1928,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Julian Rosefeldt",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Guido van der Werve",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edith Dekyndt",
        "nationality": "Belgian",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tomas Saraceno",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Grete Stern",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1904,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "John Wood",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anna Halprin",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1920,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "Mary Ellen Mark",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Kinya Tagawa",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Toshio Iwai",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Song Dong",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bengt af Klintberg",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Eric Andersen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Sylvano Bussotti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Henning Christiansen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2008,
        "count": 9
    },
    {
        "name": "Giuseppe Chiari",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2007,
        "count": 6
    },
    {
        "name": "Philip Corner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 109
    },
    {
        "name": "Toshi Ichiyanagi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2022,
        "count": 11
    },
    {
        "name": "Terry Jennings",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 1981,
        "count": 1
    },
    {
        "name": "György Sándor Ligeti",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Yoriaki Matsudaira",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 13
    },
    {
        "name": "Pierre Mercure",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1966,
        "count": 4
    },
    {
        "name": "Heinz-Klaus Metzger",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2009,
        "count": 1
    },
    {
        "name": "Tomas Schmit",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 2006,
        "count": 3
    },
    {
        "name": "Dieter Schnebel",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Karlheinz Stockhausen",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2007,
        "count": 3
    },
    {
        "name": "James Tenney",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2006,
        "count": 1
    },
    {
        "name": "Yasunao Tone",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Michael Von Biel",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George Yuasa",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jerzy Flisak",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Jutta Koether",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Walead Beshty",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Daniel Gordon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carter Mull",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kathy Acker",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Shunji Yamanaka",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Genpei Akasegawa",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Alice Hutchins",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1916,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Carla Liss",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 2012,
        "count": 3
    },
    {
        "name": "Larry Miller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Peter Moore",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Jock Reynolds",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Yoshi Wada",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 2021,
        "count": 3
    },
    {
        "name": "Roman Polanski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rolf Jährling",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Salvo (Salvatore Mangione)",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Richard Kern",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barbara Ess",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "Jesper Just",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Silvie Defraoui",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Didier Rittener",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Glenn O'Brien",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "R. H. Quaytman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vera Molnar",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1924,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Ericka Beckman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lynne Tillman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giancarlo Mattioli",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kuniharu Akiyama",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Pope.L",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Eugen Rubin",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Frank Shepard Fairey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Laurel Nakadate",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Musgrave",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Thurston Moore",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William Crosby Renwick",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Eduardo Costa",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Werner Graul",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Lorraine O'Grady",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roberta Bayley",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edit deAk",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Beatriz Cifuentes",
        "nationality": "Spanish",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yoshiki Waterhouse",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Frank Stewart",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Riccardo Blumer",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Matteo Borghi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephan Van Dam",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Matthias Sauerbruch",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Louisa Hutton",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Seung H-Sang",
        "nationality": "Korean",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Young Joon Kim",
        "nationality": "Korean",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Keenen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edward Krasiński",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Oscar van Alphen",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Manfred Leve",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Hai Quoc Tran",
        "nationality": "Vietnamese",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tuan Andrew Nguyen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Majda Dobravec-Lajovic",
        "nationality": "Slovenian",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Greg Sharits",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 1980,
        "count": 1
    },
    {
        "name": "Kate Millett",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1934,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Dorine van der Klei",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elohim (Ely) Raman",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Jacqueline Humphries",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anton Furst",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Juan Araujo",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Martha Colburn",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Aluisio Carvão",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Leonard Freed",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Sophie Tottie",
        "nationality": "Swedish",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Holger Nielsen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Cecilia Vicuña",
        "nationality": "Chilean",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 29
    },
    {
        "name": "Ranjani Shettar",
        "nationality": "Indian",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Candice Breitz",
        "nationality": "South African",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ragnar Kjartansson",
        "nationality": "Icelandic",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Igor Kopystiansky",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Svetlana Kopystiansky",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ryan McNamara",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mickalene Thomas",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jan Tichy",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Freddie Yauner",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mai-Thu Perret",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christien Meindertsma",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julia Wachtel",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julie Joliat",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hân Pham",
        "nationality": "Danish",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cornelia Blatter",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marcel Hermans",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lino Manfrotto",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gilberto Battocchio",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Raymond Jacobs",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Teresita Fernández",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Cyprien Gaillard",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean Tschumi",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1962,
        "count": 0
    },
    {
        "name": "Nye Ffarrabas (formerly Bici Forbes and Bici Forbes Hendricks)",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jack Goldstein",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Katharina Sieverding",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elizaveta Ignatovich",
        "nationality": "Russian",
        "gender": "Female",
        "startYear": 1903,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Axel Schmid",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anne Lacaton",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean Philippe Vassal",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Iwan Baan",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jeff Preiss",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Raúl Lozza",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Tomás Maldonado",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Peter Coffin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard McGuire",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mariah Robertson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Lewis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David J. Lewis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dmitrij Naumovich Babichenko",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Edgar Arceneaux",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Sara Greenberger Rafferty",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Donald Genaro",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chitra Ganesh",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chris Kabel",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Cocksedge",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vincent Fecteau",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Haegue Yang",
        "nationality": "Korean",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Tom-Arne Solhaug",
        "nationality": "Norwegian",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Aleksander Borgenhov",
        "nationality": "Norwegian",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Qiu Anxiong",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ernst Fischer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Charles Henri Ford",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Shahryar Nashat",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Brian Buczak",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 1987,
        "count": 0
    },
    {
        "name": "Ray Tomlinson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Nancy Azara",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Betsy Damon",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lauren Ewing",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Harmony Hammond",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Diane Neumaier",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joan Semmel",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sylvia Sleigh",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1916,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "May Stevens",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1924,
        "endYear": 2019,
        "count": 2
    },
    {
        "name": "Athena Tacha",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Martha Wilson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Frederic Lieberman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "János Kender",
        "nationality": "Hungarian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Alex Prager",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elad Lassry",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roe Ethridge",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Amanda Ross-Ho",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dmitri Baltermants",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Ludwig Gosewitz",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "François Dufrêne",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Mary Adshead",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1904,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Eric Lombers",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Frédéric Druot",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elaine Summers",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1925,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "George Landow",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Jessica Jackson Hutchins",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Herbert Tomlinson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alexandre Singh",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1980,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Danh Vo",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Miriam Böhm",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yusuf Arakkal",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giselle Baillie",
        "nationality": "South African",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kim Berman",
        "nationality": "South African",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kanuge John Bosco",
        "nationality": "Ugandan",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bronwen Findlay",
        "nationality": "South African",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sister Sheila Flynn",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Deryck Healy",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Trevor Makhoba",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Judith Mason",
        "nationality": "South African",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sipho Mdanda",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Martin Moratillo",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gabisile Nkosi",
        "nationality": "South African",
        "gender": "Female",
        "startYear": 1974,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Daniel Ohene-Adu",
        "nationality": "Ghanaian",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carmen Perrin",
        "nationality": "Peruvian",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sthembiso Sibisi",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Yusuf Vahed",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Judy Woodborne",
        "nationality": "South African",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nhlanhla Xaba",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mduduzi Xakaza",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bjarke Ingels",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cameron Platter",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Laurence Humier",
        "nationality": "Belgian",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kudzanai Chiurai",
        "nationality": "Zimbabwean",
        "gender": "Male",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marco Cianfanelli",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barthelemy Toguo",
        "nationality": "Cameroonian",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ernestine White",
        "nationality": "South African",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Billy Apple",
        "nationality": "New Zealander",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Davorin Savnik",
        "nationality": "Slovenian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Susan Hefuna",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1962,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Marie Cool",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Heatherwick",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Granger Moorhead",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Moorhead",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tobias Frere-Jones",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jonathan Hoefler",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Susan Kare",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "P. Scott Makela",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Jeffery Keedy",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barry Deck",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Albert-Jan Pool",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Erik Spiekermann",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Neville Brody",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Erik van Blokland",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Just van Rossum",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Inge Morath",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1923,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Tunga",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "De Wain Valentine",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2022,
        "count": 0
    },
    {
        "name": "Rafael Ortega",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rita Ackermann",
        "nationality": "Hungarian",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mateo López",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carmela Gross",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paula Hayes",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Grace Jones",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Karen Finley",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dani (Leventhal) ReStack",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Annette Kelm",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hank Willis Thomas",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alex Dodge",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Brian Eno",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sameer Makarius",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Björk",
        "nationality": "Icelandic",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Daniel Joseph Martinez",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Deana Lawson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ilene Segalove",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Revital Cohen",
        "nationality": "Israeli",
        "gender": "Female",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Reid Miles",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Adam Pendleton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1984,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kerstin Brätsch",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1979,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Andrea Geyer",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sharon Hayes",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ashley Hunt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Katya Sander",
        "nationality": "Danish",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Emiliano Godoy",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William Plumb",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Marek Piasecki",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Cengiz Çekil",
        "nationality": "Turkish",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 2015,
        "count": 1
    },
    {
        "name": "Debnath Basu",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anandajit Ray",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kiyoji Otsuji",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Kazuo Shiraga",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Saburo Murakami",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1996,
        "count": 1
    },
    {
        "name": "Diana Balton",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Jacqueline Groag",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1903,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Franciszka Themerson",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1907,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Kacie Kinzer",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Opsvik",
        "nationality": "Norwegian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Golan Levin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ayah Bdeir",
        "nationality": "Lebanese",
        "gender": "Female",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Benjamin Dennel",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stefanie Posavec",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nicholas Felton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Soner Ozenc",
        "nationality": "Turkish",
        "gender": "Male",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gabriele Basilico",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Munio Gitai Weinraub",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1970,
        "count": 0
    },
    {
        "name": "Manon de Boer",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ion Grigorescu",
        "nationality": "Romanian",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Maarten Baas",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Max Weisel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1991,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kate Hartman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Aram Bartholl",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julien Devaux",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jack Schulze",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Oscar Bony",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Ko Verzuu",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Ron Gilad",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zach Lieberman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Powderly",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chris Sugrue",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Armando Andrade Tudela",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kerry Tribe",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Campbell Orme",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Will Wright",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tarn Adams",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zach Adams",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Erik Hersman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shigeru Ishitsuka",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Misako Kirigaya",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1985,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Matt Kenyon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Douglas Easterly",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lidy Prati",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1921,
        "endYear": 2008,
        "count": 1
    },
    {
        "name": "Jacques Charlier",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Gary Bigot",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Marcel Mariën",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1993,
        "count": 3
    },
    {
        "name": "Didier Vermeiren",
        "nationality": "Belgian",
        "gender": "male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ian Wilson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Anne Collier",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Saville",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Buthe",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 1994,
        "count": 1
    },
    {
        "name": "Lynn Hershman Leeson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giancarlo Mazzanti",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Faludi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kati London",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rebecca Bray",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrzej Budek",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Moyra Davey",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George Georgiou",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marguerite Humeau",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1986,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Doug Rickard",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 2022,
        "count": 0
    },
    {
        "name": "Viviane Sassen",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Phil Collins",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephen Vitiello",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luigi Ghirri",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Robert Rotifer",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Walter Käch",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1970,
        "count": 0
    },
    {
        "name": "Andrei Ujică",
        "nationality": "Romanian",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hans Ehrich",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tom Ahlström",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ray Yoshida",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2009,
        "count": 6
    },
    {
        "name": "Ei Arakawa",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nikolas Gambaroff",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tauba Auerbach",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1981,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Nora Schultz",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Henri Chopin",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Zenon Januszewski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Filippo Romoli",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1969,
        "count": 0
    },
    {
        "name": "Sanford Biggers",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sara Cwynar",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1985,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Werner John",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Damián Ortega",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Hans Brockhage",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Tom DeFanti",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yvonne Farrell",
        "nationality": "Irish",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shelley McNamara",
        "nationality": "Irish",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bill Rauhauser",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Douglas Davis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Rainer (Imi) Giese",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 1974,
        "count": 0
    },
    {
        "name": "Bernd Lohaus",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Val Telberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Alec Soth",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pieter Hugo",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Jackson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Siv Helene Stangeland",
        "nationality": "Norwegian",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Reinhard Kropf",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Chipperfield",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Diamela Eltit",
        "nationality": "Chilean",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Juan Castillo",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Raúl Zurita",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fernando Balcells",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Boris Orlov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Spain Rodriguez",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Ryan Gander",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Senga Nengudi",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joe Bradley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Quay Brothers",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sturtevant",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1924,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Mariana Castillo Deball",
        "nationality": "Mexican",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephen Kaltenbach",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mathias Poledna",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles Gaines",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Simon Fujiwara",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hajra Waheed",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1980,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Norbert Schwontkowski",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Huber",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Piller",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pedro Almodóvar",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Emily Sundblad",
        "nationality": "Swedish",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stefan Tcherepnin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tomislav Gotovac",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Craig Dykers",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hilary Sample",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Adele Röder",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Annemarie Heinrich",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1912,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Haluk Akakçe",
        "nationality": "Turkish",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jason Salavon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anatole Saderman",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Jan Slothouber",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Willy Ørskov",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Aldo van den Nieuwelaar",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Ed Sommer",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Emmy van Leersum",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Willem Breuker",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "David Tremlett",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Abraham Cruzvillegas",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Beauford Delaney",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1979,
        "count": 1
    },
    {
        "name": "Gabriel Sierra",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "María Teresa Hincapié",
        "nationality": "Colombian",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Yuya Ushida",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Meredith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joe Maloney",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jorinde Voigt",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Josip Vaništa",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2018,
        "count": 4
    },
    {
        "name": "Tom Arndt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Corita Kent (Sister Mary Corita)",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1918,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Giorgio Berretti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bernard Heidsieck",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Ricardo Porro",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Shambhavi",
        "nationality": "Indian",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Johanna Calle",
        "nationality": "Colombian",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Jens S Jensen",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Taryn Simon",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Oscar Tuazon",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lucien Hervé",
        "nationality": "Hungarian",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Takuma Nakahira",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Virginia Overton",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Outterbridge",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Nick Cave",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Akram Zaatari",
        "nationality": "Lebanese",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fred Eversley",
        "nationality": "American",
        "gender": "male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Maren Hassinger",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Suzanne Jackson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Adam Ant (Stuart Leslie Goddard)",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Malcolm Garrett",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Guy Peellaert",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Barney Bubbles (Colin Fulcher)",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Samella Lewis",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1923,
        "endYear": 2022,
        "count": 0
    },
    {
        "name": "Nick Egan",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bob Heimall",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Spencer Drate",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Arturo Vega",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Noah Purifoy",
        "nationality": "American",
        "gender": "male",
        "startYear": 1917,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Gene Greif",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Ray Lowry",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Pennie Smith",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pearl Thompson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andy Vella",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Poly Styrene (Marianne Joan Elliott-Said)",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Noriko Ambe",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bernardo Ortiz Campo",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 20
    },
    {
        "name": "Henry Taylor",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kader Attia",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrés Jaque",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shen Wei",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Josh MacPhee",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Stathis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael McCoy",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ousmane Sembène",
        "nationality": "Senegalese",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Marijan Jevšovar",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Ivan Kožarić",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Miljenko Horvat",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Carapetian",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "André Komatsu",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chen Shaoxiong",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Lou Bunin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "John Karidis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Didier Faustino",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wael Shawky",
        "nationality": "Egyptian",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Justin Matherly",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carla Accardi",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1924,
        "endYear": 2014,
        "count": 1
    },
    {
        "name": "Robert Wilhite",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fabio Balducci",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Arch Connelly",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Oscar Muñoz",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lea Lublin",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "István Orosz",
        "nationality": "Hungarian",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Massoud Hassani",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cathy Wilkes",
        "nationality": "Irish",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ben Kinmont",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dirk Vander Kooij",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles Nichols",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Kishio Suga",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Erwin Hauer",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Randy Tuten",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Adam Helms",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Newsom",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William Villalongo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Danilo Barbosa",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ellen Auerbach",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1906,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "John Follis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Michele Abeles",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mike Nichols",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Michael Harris",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zoe Crosher",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Éric Chahi",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Masaya Matsuura",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Clorindo Testa",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Simon Levy",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alexey Pajitnov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Keita Takahashi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anya Phillips",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "James McCaffry",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Nina Leen",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1909,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Jimenez Lai",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nipa Doshi",
        "nationality": "Indian",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jonathan Levien",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yto Barrada",
        "nationality": "Moroccan",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Irwin Kremen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2020,
        "count": 1
    },
    {
        "name": "Běla Kolářová",
        "nationality": "Czech",
        "gender": "Female",
        "startYear": 1923,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Martin Boyce",
        "nationality": "Scottish",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Monteiro Filho",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Antonio Bandeira",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1967,
        "count": 0
    },
    {
        "name": "Danilo di Prete",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Felix Beltran",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paolo Labañino",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "González Ruiz",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Daniel Grataloup",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Markus Kayser",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "DoDo Jin Ming",
        "nationality": "Chinese",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nathalie Djurberg",
        "nationality": "Swedish",
        "gender": "Female",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zanele Muholi",
        "nationality": "South African",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dóra Maurer",
        "nationality": "Hungarian",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Norman Seeff",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gardar Eide Einarsson",
        "nationality": "Norwegian",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rashid Johnson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Douglas Darden",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Abby Leigh",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Klinkowstein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Arnoff",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carlos Bunga",
        "nationality": "Portuguese",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Diane Lewis",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Richard Barnes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Arthus Carlheinz Caspari",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Ido Bruno",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Arthur Brutter",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Augustin Scott de Martinville",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Grégoire Jeanmonod",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elric Petit",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marion Weiss",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Adrián Villar Rojas",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1980,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Adam Saltsman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jenova (Xinghan) Chen",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nick Clark",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1984,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Toru Iwatani",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rand Miller",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robyn Miller",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "José Guillermo Castillo",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Carlos Herrera",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Valentin Carron",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rabih Mroué",
        "nationality": "Lebanese",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Meiro Koizumi",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Daniel Baranowsky",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1984,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Loretta Fahrenholz",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Albert Mertz",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Annee Olofsson",
        "nationality": "Swedish",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tom Hunter",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Daniela Rossell",
        "nationality": "Mexican",
        "gender": "Female",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vibeke Tandberg",
        "nationality": "Norwegian",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hellen Van Meene",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carol Rama",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1918,
        "endYear": 2015,
        "count": 1
    },
    {
        "name": "Filip Dujardin",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ada Tolla",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giuseppe Lignano",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cristóbal Lehyt",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 10
    },
    {
        "name": "Joseph Byrd",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Waldemar Cordeiro",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1973,
        "count": 0
    },
    {
        "name": "Analivia Cordeiro",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Janice Kerbel",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William Scott",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Lisa Oppenheim",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gordon Bruce",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Allen Hawthorne",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christine Sun Kim",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1980,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Lyle Ashton Harris",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lucy Raven",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anna Ostoya",
        "nationality": "Polish",
        "gender": "Female",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eileen Quinlan",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Josephine Pryde",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Brendan Fowler",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Adam Broomberg",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Oliver Chanarin",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robi Müller",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lourdes Portillo",
        "nationality": "Mexican",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sheela Gowda",
        "nationality": "Indian",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "François Truffaut",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Per Olof Ultvedt",
        "nationality": "Finnish",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2006,
        "count": 1
    },
    {
        "name": "Franz Josef van der Grinten",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Clifford Owens",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Geta Brătescu",
        "nationality": "Romanian",
        "gender": "Female",
        "startYear": 1926,
        "endYear": 2018,
        "count": 1
    },
    {
        "name": "Yael Mer",
        "nationality": "Israeli",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Haroon Mirza",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Maxfield",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1969,
        "count": 0
    },
    {
        "name": "Mary Vieira",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1927,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Pandit Pran Nath",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1918,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Paulo Monteiro",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Ryan Trecartin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paulo Bruscky",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jeanne Verdoux",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ronnie Goodman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Molly Crabapple",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fernando Vilela",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fernando Ortega",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Lanigan-Schmidt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Liu Xiaodong",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tom Shortlidge",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jayson Musson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Amanda Guest",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bart Wasserman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Pier Paolo Pasolini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Ralph Baer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Cristobal Palma",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Markus \"Notch\" Persson",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Allan Alcorn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George \"Ed\" Logg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Howard Scott Warshaw",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Corneille Hannoset",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Tomohiro Nishikado",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nicolás Paris",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Tobias Madison",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1985,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lizzie Fitch",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luis Barragán",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Ed Atkins",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hito Steyerl",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Antón Garcia-Abril",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Javier Cuesta Rodríguez-Torices",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Débora Mesa",
        "nationality": "Spanish",
        "gender": "Female",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Agustín Hernández Navarro",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "José Antonio Attolini Lack",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "José Miguel Galia",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Jesús Tenreiro-Degwitz",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "David Palladini",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Michael C. Gross",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Putnam",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "J.D. 'Okhai Ojeikere",
        "nationality": "Nigerian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Scott Snibbe",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Flora Manteola",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Javier Sánchez Gómez",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Josefa Santos",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Justo Solsona",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lina Bo Bardi",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1914,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Emil Schulthess",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Georg Gerster",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bronislaw Schlabs",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Claus Bremer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "James Waring",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "A.L. Steiner",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Al Loving",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Mark Morrisroe",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Helen Marten",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1985,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vivienne Westwood",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mogens Lassen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1987,
        "count": 0
    },
    {
        "name": "Delia Smith",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Adam Yarinsky",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kim Yao",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sara Hendren",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Brian Glenney",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kengo Kuma",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roddy Bogawa",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tim Ferguson Sauder",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jens Eilstrup Rasmussen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yoshiki Okamoto",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Akira Yasuda",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Buggenhout",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cinthia Marcelle",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ryan Sullivan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jiří Hilmar",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jaroslav Sůra",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Karel Vaca",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Halil Altindere",
        "nationality": "Turkish",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jonathas de Andrade",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Every Ocean Hughes",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jeff Elrod",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jeremy Shaw",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Danny Boyle",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Bresson",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Alex Cox",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sergio Leone",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Jean-Pierre Melville",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1973,
        "count": 0
    },
    {
        "name": "Paul Morrisey",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Daniel Schmid",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Luchino Visconti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Alfredo Cortina",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Francisco Méndez Labbé",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mario Gandelsonas",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Diana Agrest",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Edi Hirose",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mario Roberto Álvarez",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Eladio Dieste",
        "nationality": "Uruguayan",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Ramiro Bascans",
        "nationality": "Uruguayan",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Thomas Sprechmann",
        "nationality": "Uruguayan",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Héctor Vigliecca",
        "nationality": "Uruguayan",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Arturo Villaamil",
        "nationality": "Uruguayan",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Camille Henrot",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sung Hwan Kim",
        "nationality": "Korean",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tuur Van Balen",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Van Saun",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Guillermo Bermúdez",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Wolfgang Stoerchle",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 1976,
        "count": 0
    },
    {
        "name": "Lee Jaffe",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George Trakas",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jimmy Alcock",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "José Sigala",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 1955,
        "count": 0
    },
    {
        "name": "Miguel Rodrigo Mazuré",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Frederick Cooper Llosa",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Miguel Cruchaga Belaúnde",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dieter Hülsmanns",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 1981,
        "count": 0
    },
    {
        "name": "Susan Meiselas",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pietro Roccasalva",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Hilliard",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jiri Kovanda",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Juan Baixas",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Albrecht/d.",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Walter Weberhofer Quintana",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Felipe Ehrenberg",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 2017,
        "count": 3
    },
    {
        "name": "Sergio Larrain",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Federico Guillermo Beckhoff",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Volker Hüller",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nick Knight",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kurt Kren",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Klaus Eschen",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Williams",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michaela Eichwald",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Aldrich",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jakub Szczęsny",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Klára Tamás",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Franco Rossi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Florian Idenburg",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jing Liu",
        "nationality": "Chinese",
        "gender": "Female",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marc Downie",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shelley Eshkar",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Kaiser",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Juan Sordo Madaleno",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Charles de Meaux",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leonardo Finotti",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Justino Serralta",
        "nationality": "Uruguayan",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Abe Frajndlich",
        "nationality": "German",
        "gender": "male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Günter Günschel",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Mauricio Pezo",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sofía von Ellrichshausen",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lux Interior (Erick Lee Purkhiser)",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Lutz Bacher",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1952,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Juan Luis Martinez",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 1993,
        "count": 9
    },
    {
        "name": "Ulises Carrión",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 1989,
        "count": 4
    },
    {
        "name": "Robert H. McNeill",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Eva Kotátková",
        "nationality": "Czech",
        "gender": "Female",
        "startYear": 1982,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Alair Gomes",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Sara Facio",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nicolas Garcia Uriburu",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Mladen Stilinović",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Ed Clark",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Judith Scott",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Matías Duville",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "David Tudor",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Rosângela Rennó",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tom Wedell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ken Okiishi",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Beatrice Trueblood",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "José Carlos Martinat",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Simon Denny",
        "nationality": "New Zealander",
        "gender": "Male",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mathias Augustyniak",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Amzalag",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sarah Stocker",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark Danks",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sjón (Sigurjón Birgir Sigurðsson)",
        "nationality": "Icelandic",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nikki Dibben",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephen Malinowski",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Erin Shirreff",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Keld Helmer-Petersen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Jay Silver",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eric Rosenbaum",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nancy Burson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carlos Motta",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Adams",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tim Berresheim",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Courttney Cooper",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Keiji Uematsu",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Martin Sharp",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "James Richards",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alfred Gescheidt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Jean-Philippe Toussaint",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yuri Suzuki",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Arjen Bangma",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Torbjørn Rødland",
        "nationality": "Norwegian",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jorge Castillo",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marjan van Aubel",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1985,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Philippe Van Snick",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Oscar Tenreiro Degwitz",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tihamér Csemiczky",
        "nationality": "Hungarian",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1960,
        "count": 0
    },
    {
        "name": "Fernando Guerra",
        "nationality": "Portuguese",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stefan Diez",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "W. Craig Carter",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joe Hicklin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jann Haworth",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Martin Christopherson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Peter Gabriel",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean-Paul Goude",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George Hardie",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Andrews",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "David Inshaw",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Clive Barker",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Tindle",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tony Wright",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark Farrow",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eric Watson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Francis Wolff",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "Jerry Harrison",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Whitaker",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Ronald Clyne",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Gerald Scarfe",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roger Waters",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bob Dylan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Cooper",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 1973,
        "count": 0
    },
    {
        "name": "Bob Seidmann",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Richard Evans",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Adelle Lutz",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Freeman",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark McKeague",
        "nationality": "Irish",
        "gender": "Male",
        "startYear": 1989,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Naomi Elliott",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1987,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joseph Pleass",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1990,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Martyn Atkins",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bernard Pierre Wolff",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Turlif Vilbrandt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ulrike Müller",
        "nationality": "Austrian",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Brigitte Hellgoth",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kevin Beasley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1985,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leo Fender",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "George Fullerton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Freddie Tavares",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Massimo Banzi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Cuartielles",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tom Igoe",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gianluca Martino",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Mellis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giorgio Olivero",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sam Lewitt",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ryan Oakes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Trevor Oakes",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jennie C. Jones",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julia Rommel",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Aloisio Magalhães",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Carlos Zilio",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jessica Rosenkrantz",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jesse Louis-Rosenberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1986,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Iñaki Bonillas",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fred Stein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1967,
        "count": 0
    },
    {
        "name": "Conte Candoli",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Brian Aris",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tina Weymouth",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chris Frantz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Billy Name",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Craig Braun",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ernie Cefalu",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Pasche",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eric Hurtado",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marc Hurtado",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Arrigo Lora Totino",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joe Colley",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George Herms",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alex Israel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christian Vetter",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lynette Yiadom-Boakye",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Smiljan Radić",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jan Trtílek",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Achim Duchow",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Natalie Czech",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrea Trimarchi",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Simone Farresin",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "LaToya Ruby Frazier",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Guillermo Zamora",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Vivian Browne",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 1993,
        "count": 2
    },
    {
        "name": "Eldzier Cortor",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2015,
        "count": 1
    },
    {
        "name": "Trevor Key",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Hélène Binet",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Orlando Garcia",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Houck",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Annett Zinsmeister",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Reginald Orton",
        "nationality": "New Zealander",
        "gender": "Male",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Philip Walton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yuki Kimura",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ryan Junell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Benjamin Bauer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Lucas Blalock",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Steffani Jemison",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bouchra Khalili",
        "nationality": "Moroccan",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wu Tsang",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Donald Elliot Ingber",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dan Dongeun Huh",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christian Richters",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Igildo Biesele",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Bradshaw",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Abdul Mati Klarwein",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "John Berg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Adriana Salazar",
        "nationality": "Colombian",
        "gender": "Female",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Manuel González Rul",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Patrick Nagel",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Annie Leibovitz",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christian Piper",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nick Fasciano",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Don Hunstein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Katharina Gaenssler",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Hartt",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ilit Azoulay",
        "nationality": "Israeli",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lele Saveri",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marina Pinsky",
        "nationality": "Russian",
        "gender": "Female",
        "startYear": 1986,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lieko Shiga",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Brian Duffy",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Zacron (Richard Drew)",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Rick Griffin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Rocco Redondo",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Zumthor",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Brian Griffin",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alex Roberts",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Walter Jule",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gerald Holtom",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1985,
        "count": 0
    },
    {
        "name": "Gary Anderson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John \"Teflon\" Sims",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrzej Karpiński",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mirosław Ryszard Ryszard",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Arnold Rosenberg",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Philip Hays",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ben Drury",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "András Wahorn",
        "nationality": "Hungarian",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shawn Sims",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1986,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Häberer",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Tomasz Lipiński",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephen Kroninger",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Annette Peacock",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrzej Zborski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alex Schweder",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rick Mann",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Francisco Matto",
        "nationality": "Uruguayan",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Sandra Cinto",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sarah Grilo",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1919,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Etel Adnan",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1925,
        "endYear": 2021,
        "count": 1
    },
    {
        "name": "Takashi Kono",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Toshihiro Katayama",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tony Lewis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1986,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Cindy Bernard",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Juan Batlle Planas",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1966,
        "count": 2
    },
    {
        "name": "Art Kane",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Rosemberg Sandoval",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Patterson Beckwith",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alex Bag",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Gerrard",
        "nationality": "Irish",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Katalin Ladik",
        "nationality": "Hungarian",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Adam McEwen",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Basim Magdy",
        "nationality": "Egyptian",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Naoya Hatakeyama",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tennessee Williams",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "John Wallowitch",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Aretha Franklin",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Ha Chong-Hyun",
        "nationality": "Korean",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lionel Maunz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Chen Zhen",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Josh Kline",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thea Djordjadze",
        "nationality": "Georgian",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zvonimir Golob",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Iain Macmillan",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Philipp Schaerer",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Helen Balfour Morrison",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1901,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Claude Parent",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Terrol Dew Johnson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Uche Okeke",
        "nationality": "Nigerian",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2016,
        "count": 4
    },
    {
        "name": "Kees Wagenaars",
        "nationality": "Dutch",
        "gender": "male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jie Qi",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1987,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrew \"Bunnie\" Huang",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gilbert Baker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Mirko Ilić",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eleanor Kluck",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1919,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Henry Kluck",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Eduardo Munoz Bachs",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Titus Kaphar",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Rene Azcuy Cardenas",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Antonio Fernandez Reboiro",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrew John Hessel",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jacqueline Yuan Quinn",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1990,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Richard Ayre Jaschke",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joseph Schwartz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Anna Boghiguian",
        "nationality": "Egyptian",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 12
    },
    {
        "name": "Martine Bedin",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dayanita Singh",
        "nationality": "Indian",
        "gender": "Female",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michele De Lucchi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nathalie du Pasquier",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Massimo Giacon",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roland Shakespear",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Guillermo González Ruiz",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sou Fujimoto",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tala Madani",
        "nationality": "Iranian",
        "gender": "Female",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eric Baudelaire",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tania Bruguera",
        "nationality": "Cuban",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joan Heemskerk",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dirk Paesmans",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Phu Nam",
        "nationality": "Vietnamese",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Matt Lucero",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fritz Kühn",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1910,
        "endYear": 1967,
        "count": 0
    },
    {
        "name": "Radovan Sredić",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ewa Partum",
        "nationality": "Polish",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "James Gleeson",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Frank Hinder",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Hal Missingham",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Alice Attie",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 16
    },
    {
        "name": "Rodney McMillian",
        "nationality": "American",
        "gender": "male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gillian Ayres",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ben Johnson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Woods",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Catherine Yass",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vincent Kohler",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Hominal",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Werner David Feist",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1909,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Alvin Baltrop",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Max Abramovitz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Cameron Rowland",
        "nationality": "American",
        "gender": "male",
        "startYear": 1988,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vincent Desiderio",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tom Finkelpearl",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dona Ann McAdams",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Park McArthur",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1984,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "François Blanciak",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tabor Robak",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1986,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sondra Perry",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1986,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alison Rossiter",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Park Seo Bo",
        "nationality": "Korean",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shigetaka Kurita",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Evan Holloway",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Matt Keegan",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sean Landers",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sharon Lockhart",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Aaron Curry",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roger Herman",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shio Kusaka",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Liza Lou",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rita McBride",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tom Sachs",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Juan Uslé",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Petr Tučný",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Jeffrey Gibson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ugo Gregoretti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julio César Morales",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luc Delahaye",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Terence Conran",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Marian Mahler",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1911,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Barbara Brown",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1932,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lucienne Day",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1917,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Peter Bankov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Amy O'Neill",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vuokko Eskolin",
        "nationality": "Finnish",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Calvin Marcus",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1988,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Osvaldo Peruzzi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Hubert Hilscher",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Lav Diaz",
        "nationality": "Filipino",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dan Peterman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Umberto Luigi Ronco",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Will Burtin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1972,
        "count": 0
    },
    {
        "name": "Bruno Monguzzi",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Deborah Sussman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Marcelo Ferraz",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Xaviera Simmons",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Muriel Cooper",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1925,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "David Small",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kim Beom",
        "nationality": "Korean",
        "gender": "male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrea Büttner",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joan Charysyn",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Clare Strand",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eric Owen Moss",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nancy Reddin Kienholz",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Antonio Quintana Simonetti",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Lucas Pope",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Loretta Lux",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "inri",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hermelindo Fiaminghi",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Tsibi Geva",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Gitlin",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Phillip Rantzer",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Micha Ullman",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jared Bark",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marco Brambilla",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Dowd",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Phillip Hefferton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Sanne Sannes",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 1967,
        "count": 0
    },
    {
        "name": "Johan Van Der Keuken",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Ambrogio Pozzi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Paz Errázuriz",
        "nationality": "Chilean",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jiří Pelcl",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "George Stone",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Matt Frewer",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Helen Mayer Harrison",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 2018,
        "count": 1
    },
    {
        "name": "Newton Harrison",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Gertrudes Altschul",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1904,
        "endYear": 1962,
        "count": 0
    },
    {
        "name": "Iman Issa",
        "nationality": "Egyptian",
        "gender": "Female",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marcel Giró",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Ademar Manarini",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Neïl Beloufa",
        "nationality": "Algerian",
        "gender": "Male",
        "startYear": 1985,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jumana Manna",
        "nationality": "Palestinian",
        "gender": "Female",
        "startYear": 1987,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Moon Hoon",
        "nationality": "Korean",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cecily Hoyt",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paulo Pires da Silva",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Eduardo Salvatore",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "Gottfried Helnwein",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Emilio Greco",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Ila Bêka",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Louise Lemoine",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Louis Draper",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Albert Fennar",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Herman Howard",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 1980,
        "count": 0
    },
    {
        "name": "James Mannas",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shawn W. Walker",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ray Francis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 2006,
        "count": 0
    },
    {
        "name": "David Carter",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Channa Horwitz",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1932,
        "endYear": 2013,
        "count": 7
    },
    {
        "name": "Ramin Bahrani",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Deborah Turbeville",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1932,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Joshua White",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Amilcar de Castro",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Louise Despont",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1983,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Alberto Greco",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 1965,
        "count": 37
    },
    {
        "name": "Byung Hoon Choi",
        "nationality": "Korean",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dargoljub Pavlov",
        "nationality": "Serbian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bora Vitorac",
        "nationality": "Serbian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Basel Abbas",
        "nationality": "Palestinian",
        "gender": "Male",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ruanne Abou-Rahme",
        "nationality": "Palestinian",
        "gender": "Female",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Akomfrah",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ian Cheng",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1984,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shaun Gladwell",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Miguel Rio Branco",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jacolby Satterwhite",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1986,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julio Agostinelli",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "André Carneiro",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Lucílio Corrêa Leite Filho",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1915,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Ivo Ferreira da Silva",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "German Lorca",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "Aldo Augusto de Souza Lima",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1971,
        "count": 0
    },
    {
        "name": "João Bizarro Da Nave Filho",
        "nationality": "Portuguese",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Walter Niedermayr",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lida Moser",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1920,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Aslı Çavuşoğlu",
        "nationality": "Turkish",
        "gender": "Female",
        "startYear": 1982,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Hudinilson Jr.",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 2013,
        "count": 1
    },
    {
        "name": "Carlos Leppe",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Suguru Ishizaki",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Robert Silvers",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Earl Rennison",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yin Yin Wong",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fazal Sheikh",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pamela Rosenkranz",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "K8 Hardy",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eric Marciano",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 2022,
        "count": 0
    },
    {
        "name": "Sharon Johnston",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark Lee",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dale Henry",
        "nationality": "American",
        "gender": "male",
        "startYear": 1931,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Charles Harrison",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Ronald Rael",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Virginia San Fratello",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marko Mušič",
        "nationality": "Slovenian",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Janko Konstantinov",
        "nationality": "Macedonian",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Georgi Konstantinovski",
        "nationality": "Macedonian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Romain Urhausen",
        "nationality": "Luxembourger",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luc Dietrich",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 1944,
        "count": 0
    },
    {
        "name": "Elisabeth Hase",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1905,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Juraj Neidhardt",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1979,
        "count": 0
    },
    {
        "name": "Omar Carreño",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "María Freire",
        "nationality": "Uruguayan",
        "gender": "Female",
        "startYear": 1917,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Carlos González Bogen",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Elsa Gramcko",
        "nationality": "Venezuelan",
        "gender": "Female",
        "startYear": 1925,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Alfredo Hlito",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 1993,
        "count": 1
    },
    {
        "name": "Judith Lauand",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1922,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rubén Núñez",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Rhod Rothfuss",
        "nationality": "Uruguayan",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 1969,
        "count": 0
    },
    {
        "name": "Luiz Sacilotto",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Antonieta Sosa",
        "nationality": "Venezuelan",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rubem Valentim",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Gregorio Vardanega",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Virgilio Villalba",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Franz Weissmann",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Juan Alberto Molenberg",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1921,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Nat Fein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Zlatko Ugljen",
        "nationality": "Bosnian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Learoyd",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Niko Kralj",
        "nationality": "Slovenian",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Dinko Kovačić",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yve Laris Cohen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1985,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cécile B. Evans",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Milton Weiner",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Alexander Hammid",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1907,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Carlotta Corpron",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1901,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Roger Schall",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1955,
        "count": 0
    },
    {
        "name": "Rosalyn Drexler",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Karl Wirsum",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 2021,
        "count": 1
    },
    {
        "name": "James Ingo Freed",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 2005,
        "count": 1
    },
    {
        "name": "Richard Jackson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Nick de Morgoli",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "John Smith",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bernard Villemot",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1989,
        "count": 0
    },
    {
        "name": "Constance DeJong",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ketty La Rocca",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 1976,
        "count": 2
    },
    {
        "name": "Alden B. Dow",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Lois Dodd",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eileen Cowin",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Peter Greenaway",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ignazio Gardella",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Charles A. Arnold Jr.",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Samuel Fosso",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ivan Štraus",
        "nationality": "Bosnian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Ali Tayar",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 2016,
        "count": 0
    },
    {
        "name": "Vaclav Pozarek",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Claudia Comte",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giorgia Lupi",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Sex",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Egidio Bonfante",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Mohammad Shawky Hassan",
        "nationality": "Egyptian",
        "gender": "Male",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bogdan Bogdanović",
        "nationality": "Serbian",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Tamiko Thiel",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elfriede Stegemeyer",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1908,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Toyin Ojih Odutola",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1985,
        "endYear": 0,
        "count": 6
    },
    {
        "name": "Raúl Marroquin",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Keiichi Tanaami",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Whitfield Lovell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Adam Khalil",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1988,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zack Khalil",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1991,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zilia Sánchez",
        "nationality": "Cuban",
        "gender": "Female",
        "startYear": 1926,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mrinalini Mukherjee",
        "nationality": "Indian",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Frank Majore",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "N. Dash",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Driscoll",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sebastian Black",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1985,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Iskra Grabulovski",
        "nationality": "Macedonian",
        "gender": "Female",
        "startYear": 1936,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Marcello Geppetti",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Xiao Yu",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Liu Jianhua",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leng Lin",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Antoni Llena",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 115
    },
    {
        "name": "Boris Krstulović",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Andrija Mutnjaković",
        "nationality": "Croatian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephen Tashjian",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Judy Linn",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Craig Kalpakjian",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gauri Gill",
        "nationality": "Indian",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "F.A. \"Butzi\" Porsche",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2012,
        "count": 0
    },
    {
        "name": "Sunil Gupta",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Salim Al-Kadi",
        "nationality": "Lebanese",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eduardo Berliner",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 10
    },
    {
        "name": "Pedro Mardones Lemebel",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Francisco Casas Silva",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Feliza Bursztyn",
        "nationality": "Colombian",
        "gender": "Female",
        "startYear": 1933,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Jochen Lempert",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Otto Stupakoff",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Michael Stevenson",
        "nationality": "New Zealander",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Natalia Pinus",
        "nationality": "Russian",
        "gender": "Female",
        "startYear": 1901,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Samuel Szczekacz",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1983,
        "count": 1
    },
    {
        "name": "Fré Cohen",
        "nationality": "Dutch",
        "gender": "Female",
        "startYear": 1903,
        "endYear": 1943,
        "count": 0
    },
    {
        "name": "Steve Kahn",
        "nationality": "American",
        "gender": "male",
        "startYear": 1943,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Sandra Vásquez de la Horra",
        "nationality": "Chilean",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 7
    },
    {
        "name": "Lucy Jones",
        "nationality": "Welsh",
        "gender": "Female",
        "startYear": 1991,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Li Ming",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1986,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Graciela Carnevale",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Frazer Dougherty",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elizabeth Shaw",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1921,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Alain Tanner",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zhijun Wang",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paul Mpagi Sepuya",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anicka Yi",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Buster Cleveland",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 1998,
        "count": 3
    },
    {
        "name": "Letícia Parente",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1930,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Rosemary Mayer",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1943,
        "endYear": 2014,
        "count": 5
    },
    {
        "name": "Liisa Roberts",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zofia Kulik",
        "nationality": "Polish",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alejandro Cesarco",
        "nationality": "Uruguayan",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Juan Manuel Echavarría",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pedro Manrique Figueroa",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Regina José Galindo",
        "nationality": "Guatemalan",
        "gender": "Female",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mario García Torres",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nan González",
        "nationality": "Venezuelan",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Maria Laet",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Juan Nascimento",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Daniela Lovera",
        "nationality": "Venezuelan",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christian Vinck Henriquez",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vladimir Vladimirovich Kudryashev",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1944,
        "count": 0
    },
    {
        "name": "Alejandro Paz Navas",
        "nationality": "Guatemalan",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Amalia Pica",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wilfredo Prieto",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "José Alejandro Restrepo",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Theaster Gates",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lee Kit",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Omer Fast",
        "nationality": "Israeli",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Adrian Ghenie",
        "nationality": "Romanian",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pat Passlof",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1928,
        "endYear": 2011,
        "count": 1
    },
    {
        "name": "Chung Chang-Sup",
        "nationality": "Korean",
        "gender": "Male",
        "startYear": 1927,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Martine Syms",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1988,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carlo Mollino",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1973,
        "count": 0
    },
    {
        "name": "Aleksandar Stjepanović",
        "nationality": "Serbian",
        "gender": "Male",
        "startYear": 1931,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Branislav Karadžić",
        "nationality": "Serbian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Rajesh Vangad",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Gene Friedman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dante Giacosa",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1905,
        "endYear": 1996,
        "count": 0
    },
    {
        "name": "Carmelo Arden Quin",
        "nationality": "Uruguayan",
        "gender": "Male",
        "startYear": 1913,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Barkley L. Hendricks",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 2017,
        "count": 3
    },
    {
        "name": "Darko Marušić",
        "nationality": "Serbian",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Milenija Marušić",
        "nationality": "Serbian",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Martirene Alcántara",
        "nationality": "Mexican",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "B. Ingrid Olson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1987,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alysa Nahmias",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Benjamin Murray",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alois Lichtsteiner",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Przemysław Kwiek",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marie Losier",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Héctor Fuenmayor",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sigfredo Chacón",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elena Damiani",
        "nationality": "Peruvian",
        "gender": "Female",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eugenio Espinoza",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Suwon Lee",
        "nationality": "Venezuelan",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luis Molina-Pantin",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Roberto Obregón",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 2003,
        "count": 1
    },
    {
        "name": "Mauro Restiffe",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Zink Yi",
        "nationality": "Peruvian",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bodys Isek Kingelez",
        "nationality": "Congolese",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Danny Hillis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Susan Te Kahurangi King",
        "nationality": "New Zealander",
        "gender": "Female",
        "startYear": 1951,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Naotaka Hiro",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Kaari Upson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "Sam Contis",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sofia Borges",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1984,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephanie Syjuco",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Carmen Winant",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Adelita Husni-Bey",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1985,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joanna Piotrowska",
        "nationality": "Polish",
        "gender": "Female",
        "startYear": 1985,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hương Ngô",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hồng-An Trương",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thom Browne",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bernadette Thompson",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yohji Yamamoto",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jamie Reid",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zandra Rhodes",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Giancarlo Zanatta",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jason Benning",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Miuccia Prada",
        "nationality": "Italian",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Reed",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Suzanne Lee",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Richard Malone",
        "nationality": "Irish",
        "gender": "Male",
        "startYear": 1990,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John A. Rogers",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paco Rabanne",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Matthew Connors",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ann Moore",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lucille Aukerman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1909,
        "endYear": 1983,
        "count": 0
    },
    {
        "name": "Ana Maria Millan",
        "nationality": "Colombian",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Josef Hesoun",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1965,
        "count": 1
    },
    {
        "name": "Nikolay Diulgheroff",
        "nationality": "Bulgarian",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Monika Correa",
        "nationality": "Indian",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hal Fischer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Donna-Lee Phillips",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Elger Esser",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Károly Kismányoky",
        "nationality": "Hungarian",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Géza Perneczky",
        "nationality": "Hungarian",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Charles Burnett",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ivan Cardoso",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jerry Lewis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Arthur Jafa",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Jon Rafman",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nairy Baghramian",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nicolas Party",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jordan Wolfson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Daniel González",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "ORLAN (Mirielle Suzanne Francette Porte)",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean Sellem",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Flaminio Bertoni",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1964,
        "count": 0
    },
    {
        "name": "Enrico Filippini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 1988,
        "count": 0
    },
    {
        "name": "Amie Siegel",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Silke Otto-Knapp",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 2022,
        "count": 0
    },
    {
        "name": "Robert Picault",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Robert Opron",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1932,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "Valentin Jeck",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pádraig Timoney",
        "nationality": "Irish",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ted Purves",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Brian O'Doherty",
        "nationality": "Irish",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Dalton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Khan",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eduardo Carvajal",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jennifer Hackshaw",
        "nationality": "Venezuelan",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kenneth Pietrobono",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jan Burka",
        "nationality": "Czech",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Gil J Wolman",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 1995,
        "count": 0
    },
    {
        "name": "Bernard Aubertin",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Stephen Shames",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Storm Thorgerson",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 2013,
        "count": 0
    },
    {
        "name": "Aubrey Powell",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Furnival",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Aude Jessemin",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luc Peire",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1916,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Dora Feilane",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alain Jouffroy",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Rodolfo Krasno",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Roberto Altmann",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dom Sylvester Houédard",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Claude Pélieu",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "James Guitet",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Françoise Janicot",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Shigeru Onishi",
        "nationality": "Japanese",
        "gender": "male",
        "startYear": 1928,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Raj Rewal",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bob Cobbing",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Hugh Davies",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Hansjörg Gisiger",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Mary Corse",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Pushpamala N",
        "nationality": "Indian",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Trevor Paglen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Anupama Kundoo",
        "nationality": "Indian",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zhang Ke",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ram Rahman",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ketaki Sheth",
        "nationality": "Indian",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Phil Edelstein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Matt Rogalsky",
        "nationality": "Canadian",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jo Randerson",
        "nationality": "New Zealander",
        "gender": "Female",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hiroki Tsukuda",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Rania Ghosn",
        "nationality": "Lebanese",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "El Hadi Jazairy",
        "nationality": "Algerian",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Martin Margiela",
        "nationality": "Belgian",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Norma Kamali",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Meriem Bennani",
        "nationality": "Moroccan",
        "gender": "Female",
        "startYear": 1988,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Camille Billops",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1933,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Jennifer Bolande",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cui Jie",
        "nationality": "Chinese",
        "gender": "Female",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Emory Douglas",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Coco Fusco",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Walter Gutman",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1903,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Gavin Jantjes",
        "nationality": "South African",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mary Kelly",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Teresa Margolles",
        "nationality": "Mexican",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Medalla",
        "nationality": "Filipino",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Sam Moyer",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marilyn Nance",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Malangatana Valente Ngwenya",
        "nationality": "Mozambican",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2011,
        "count": 3
    },
    {
        "name": "Pepón Osorio",
        "nationality": "Puerto Rican",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zak Prekop",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sophie Ristelhueber",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julia Scher",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gazbia Sirry",
        "nationality": "Egyptian",
        "gender": "Female",
        "startYear": 1925,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "Valeska Soares",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Piotr Uklanski",
        "nationality": "Polish",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wang Jinsong",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wang Qingsong",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jurgen Bey",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Naeem Mohaiemen",
        "nationality": "Bangladeshi",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kikuo Saito",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1939,
        "endYear": 2016,
        "count": 1
    },
    {
        "name": "Paul Magès",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1908,
        "endYear": 1999,
        "count": 0
    },
    {
        "name": "Miguel Calvo",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 2014,
        "count": 0
    },
    {
        "name": "Julia Phillips",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1985,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "David Storey",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Thomas Brinkmann",
        "nationality": "German",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Boyd Rice",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lydia Naumova",
        "nationality": "Russian",
        "gender": "Female",
        "startYear": 1902,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Li Binyuan",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1985,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christina Fernandez",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Park Hyunki",
        "nationality": "Korean",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 2000,
        "count": 0
    },
    {
        "name": "Barbara Brändli",
        "nationality": "Venezuelan",
        "gender": "Female",
        "startYear": 1932,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Cang Xin",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gen Otsuka",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1912,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Raghu Rai",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1942,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Saloua Raouda Choucair",
        "nationality": "Lebanese",
        "gender": "Female",
        "startYear": 1916,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Kwame Brathwaite",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Patrick Staff",
        "nationality": "British",
        "gender": "Non-Binary",
        "startYear": 1987,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ei-Q (Hideo Sugita)",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1960,
        "count": 0
    },
    {
        "name": "Facundo de Zuviría",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ulrik Heltoft",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Aurèlia Muñoz",
        "nationality": "Spanish",
        "gender": "Female",
        "startYear": 1926,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Leopold Strobl",
        "nationality": "Austrian",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 5
    },
    {
        "name": "Idelle Weber",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1932,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Melvin Way",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "Silvia Buonvicini",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gareth Nyandoro",
        "nationality": "Zimbabwean",
        "gender": "Male",
        "startYear": 1982,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Loredana Sperini",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wyatt Kahn",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nathlie Provosty",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Martha Tuttle",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1989,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marianne Wex",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1937,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Jorge Ortiz",
        "nationality": "Colombian",
        "gender": "Male",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mostapha El Oulhani",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sibille Berger",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1987,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Delphine Meriaux",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1988,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sharp (Aaron Goodstone)",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1966,
        "endYear": 0,
        "count": 4
    },
    {
        "name": "Phase 2",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 2019,
        "count": 1
    },
    {
        "name": "NOC 167 (Melvin Samuels)",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "José Rafael Moneo",
        "nationality": "Spanish",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "MARE 139 (Carlos Rodriguez)",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 2
    },
    {
        "name": "ERO (Dominique Philbert)",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 2011,
        "count": 5
    },
    {
        "name": "Duster",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Kase2 (Jeff Brown)",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 2011,
        "count": 12
    },
    {
        "name": "William Cordero (Bill Blast)",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Walter Price",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1989,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Yuri Avvakumov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrei Bokov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vyacheslav Petrenko",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 1982,
        "count": 0
    },
    {
        "name": "Mikhail Belov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yuri Kuzin",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Igor Pischukevich",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lev Evzovich",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alexei Gutnov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1937,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Boris Levyant",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sergei Lobachev",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ivan Shalmin",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Totan Kuzembaev",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1953,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrei Ivanov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vyacheslav Aristov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sergei Korobov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mikhail Labazov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrei Cheltsov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alexander Zossimov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrei Savin",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vika Voronova",
        "nationality": "Russian",
        "gender": "Female",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vladimir Tyurin",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Iskander Galimov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dmitry Bush",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sergei Chuklov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alexander Khomyakov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sergei Grebennikov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrei Chernov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Viktor Smyshlyaev",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1959,
        "endYear": 2004,
        "count": 0
    },
    {
        "name": "Slava Mizin",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Evgeny Burov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dmitry Ivanov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrei Kuznetsov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vera Chuklova",
        "nationality": "Russian",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Algirdas Šeškus",
        "nationality": "Lithuanian",
        "gender": "Male",
        "startYear": 1945,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mikhail Filippov",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mirtha Dermisache",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 2012,
        "count": 6
    },
    {
        "name": "Anna Andreeva",
        "nationality": "Russian",
        "gender": "Female",
        "startYear": 1917,
        "endYear": 2009,
        "count": 0
    },
    {
        "name": "Eduardo Kac",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Shuzo Azuchi Gulliver",
        "nationality": "Japanese",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rasheed Araeen",
        "nationality": "Pakistani",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Amanda Williams",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Enrique Ramírez",
        "nationality": "Chilean",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Leandro Feal",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1986,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nelly Richard",
        "nationality": "Chilean",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sanlé Sory",
        "nationality": "Burkinabé",
        "gender": "Male",
        "startYear": 1943,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Aldo Bakker",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Margaret Hoening French",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1906,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "LaGardo Tackett",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Benjamin Bowden",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1906,
        "endYear": 1998,
        "count": 0
    },
    {
        "name": "Giorgetto Giugiaro",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zoltán Réczey",
        "nationality": "Hungarian",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jordan Grabulovski",
        "nationality": "Macedonian",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 1986,
        "count": 0
    },
    {
        "name": "Hans Berg",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mario Meier",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christopher Williamson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jean Depara",
        "nationality": "Congolese",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 1997,
        "count": 0
    },
    {
        "name": "Romuald Hazoumè",
        "nationality": "Beninese",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Abu Bakarr Mansaray",
        "nationality": "Sierra Leonean",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Moké",
        "nationality": "Congolese",
        "gender": "Male",
        "startYear": 1950,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Ambroise Ngaimoko, Studio 3Z",
        "nationality": "Congolese",
        "gender": "Male",
        "startYear": 1949,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Felix Akinniran Olunloyo",
        "nationality": "Nigerian",
        "gender": "Male",
        "startYear": 1970,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dulce Carneiro",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1929,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Palmira Puig Giró",
        "nationality": "Spanish",
        "gender": "Female",
        "startYear": 1912,
        "endYear": 1978,
        "count": 0
    },
    {
        "name": "Maria Helena Valente da Cruz",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1927,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alzira Helena Teixeira",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1934,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Barbara Mors",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alberto Korda",
        "nationality": "Cuban",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2001,
        "count": 0
    },
    {
        "name": "Sun Ra",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Paul Coldwell",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1952,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tosh Matsumoto",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1920,
        "endYear": 2010,
        "count": 0
    },
    {
        "name": "Hervé Guibert",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 1991,
        "count": 0
    },
    {
        "name": "Sandrine Pelletier",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Raha Raissnia",
        "nationality": "Iranian",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Fernanda Gomes",
        "nationality": "Brazilian",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sable Elyse Smith",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1986,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Delta 2 (Calvin Gonzales)",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1965,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Jonathan Demme",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Edin Velez",
        "nationality": "Puerto Rican",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Juan José Gurrola",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1935,
        "endYear": 2007,
        "count": 0
    },
    {
        "name": "Jonathan Calm",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joanne Leonard",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jo Spence",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1934,
        "endYear": 1992,
        "count": 0
    },
    {
        "name": "Suzanne Bocanegra",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1957,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Alex Goad",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1989,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kosuke Araki",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1988,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Caroline Slotte",
        "nationality": "Finnish",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mustafa Faruki",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marta Maria Pérez  Bravo",
        "nationality": "Cuban",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Benode Behari Mukherjee",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1904,
        "endYear": 1980,
        "count": 3
    },
    {
        "name": "Barbara Jones-Hogu",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 2017,
        "count": 0
    },
    {
        "name": "Blanche Parker",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1902,
        "endYear": 1984,
        "count": 0
    },
    {
        "name": "Wadsworth Jarrell",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Farah Al Qasimi",
        "nationality": "Emirati",
        "gender": "Female",
        "startYear": 1991,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Terry Dennett",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1938,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Ming Wong",
        "nationality": "Singaporean",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andrew Norman Wilson",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1986,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alexander Groves",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Aki Inomata",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Olalekan Jeyifous",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kate Crawford",
        "nationality": "Australian",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Paolo Roversi",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Laure Prouvost",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Arne Arnbom",
        "nationality": "Swedish",
        "gender": "Male",
        "startYear": 1922,
        "endYear": 1975,
        "count": 0
    },
    {
        "name": "Petra Cortright",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1986,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yente (Eugenia Crenovich)",
        "nationality": "Argentine",
        "gender": "female",
        "startYear": 1905,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Pacifico Silano",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1986,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sachiko Kazama",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mark Teemer",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Alexandre Wollner",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1928,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Guadalupe Maravilla",
        "nationality": "Salvadoran",
        "gender": "Male",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tsuruko Yamazaki",
        "nationality": "Japanese",
        "gender": "Female",
        "startYear": 1925,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Laura Aguilar",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 2018,
        "count": 0
    },
    {
        "name": "Judithe Hernández",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Ishmael (Angaluuk) Hope",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sheryl Oppenheim",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ruby Sky Stiler",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jeff Mermelstein",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Victoria Cabezas",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vladan Joler",
        "nationality": "Serbian",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gino Levi Montalcini",
        "nationality": "Italian",
        "gender": "Male",
        "startYear": 1902,
        "endYear": 1974,
        "count": 0
    },
    {
        "name": "Eric Klarenbeek",
        "nationality": "Dutch",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Larry Cook",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1986,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dyani White Hawk",
        "nationality": "Native American",
        "gender": "Female",
        "startYear": 1976,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gabrielle L’Hirondelle Hill",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Theo Eshetu",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1958,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sohrab Hura",
        "nationality": "Indian",
        "gender": "Male",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Irina Rozovsky",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Cara Romero",
        "nationality": "Native American",
        "gender": "Female",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hulleah J. Tsinhnahjinnie",
        "nationality": "Native American",
        "gender": "Female",
        "startYear": 1954,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tatiana Parcero",
        "nationality": "Mexican",
        "gender": "Female",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Suzy Lake",
        "nationality": "Canadian",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dionne Lee",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1988,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zora J Murff",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1987,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Rifat Chadirji",
        "nationality": "Iraqi",
        "gender": "Male",
        "startYear": 1926,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Jock Kinneir",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1917,
        "endYear": 1994,
        "count": 0
    },
    {
        "name": "Margaret Calvert",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Noah Davis",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1983,
        "endYear": 2015,
        "count": 4
    },
    {
        "name": "Anne Tyng",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1920,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Wendy Red Star",
        "nationality": "Native American",
        "gender": "Female",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ebecho Muslimova",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1984,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nathalie Lawhead",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Davey Wreden",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1988,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Bennett Foddy",
        "nationality": "Australian",
        "gender": "Male",
        "startYear": 1978,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zilla Leutenegger",
        "nationality": "Swiss",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Latifa Echakhch",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dalton Paula",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tourmaline",
        "nationality": "American",
        "gender": "Non-binary",
        "startYear": 1983,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yuli Karasik",
        "nationality": "Russian",
        "gender": "Male",
        "startYear": 1923,
        "endYear": 2005,
        "count": 0
    },
    {
        "name": "Garrett Bradley",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1986,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zhu Pei",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marlon Mullen",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ilka Gedő",
        "nationality": "Hungarian",
        "gender": "Female",
        "startYear": 1921,
        "endYear": 1985,
        "count": 7
    },
    {
        "name": "Alejandro Kuropatwa",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1956,
        "endYear": 2003,
        "count": 0
    },
    {
        "name": "Nina Chanel Abney",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1982,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Kamala Ibrahim Ishag",
        "nationality": "Sudanese",
        "gender": "Female",
        "startYear": 1939,
        "endYear": 0,
        "count": 10
    },
    {
        "name": "Ouattara Watts",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1957,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luis Hernández Cruz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Marina Adams",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1960,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Eddie Martinez",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mohammed Iman Fayaz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1990,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Althea McNish",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1924,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Marwan Rechmaoui",
        "nationality": "Lebanese",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Tiona Nekkia McClodden",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1981,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Julia Weist",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1984,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dong Gong",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jordan Casteel",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1989,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nicholas Galanin",
        "nationality": "Native American",
        "gender": "Male",
        "startYear": 1979,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Orian Barki",
        "nationality": "Israeli",
        "gender": "Female",
        "startYear": 1985,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jocelyne Coster",
        "nationality": "Belgian",
        "gender": "Female",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Meridel Rubenstein",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Philip Jones Griffiths",
        "nationality": "Welsh",
        "gender": "Male",
        "startYear": 1936,
        "endYear": 2008,
        "count": 0
    },
    {
        "name": "Jason Moran",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Gelsen Gas",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1933,
        "endYear": 2015,
        "count": 0
    },
    {
        "name": "Arnaldo Coen",
        "nationality": "Mexican",
        "gender": "Male",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Wang Shu",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1963,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lu Wenyu",
        "nationality": "Chinese",
        "gender": "Female",
        "startYear": 1966,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Edmonds",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1989,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Xiao Du Liu",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yan Meng",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1964,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hui Wang",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1967,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Li Hu",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Huang Wenjing",
        "nationality": "Chinese",
        "gender": "Female",
        "startYear": 1973,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Zhang Han",
        "nationality": "Chinese",
        "gender": "Female",
        "startYear": 1990,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lee Seung Jio",
        "nationality": "Korean",
        "gender": "Male",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Helen Rae",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 2021,
        "count": 2
    },
    {
        "name": "Lola Flash",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1959,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Dori Atlantis",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1950,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Jan Lester Martin",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1947,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nancy Youdelman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1948,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Hannah Collins",
        "nationality": "British",
        "gender": "Female",
        "startYear": 1956,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Egon Riss",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1901,
        "endYear": 1964,
        "count": 0
    },
    {
        "name": "Liu Yichun",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Chen Yifeng",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Xu Tiantian",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "James Barnor",
        "nationality": "Ghanaian",
        "gender": "Male",
        "startYear": 1929,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joshua Bolchover",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1974,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "John Lin",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Deanna Templeton",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Ed Templeton",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luis Frangella",
        "nationality": "Argentine",
        "gender": "Male",
        "startYear": 1944,
        "endYear": 1990,
        "count": 0
    },
    {
        "name": "Ahmed Morsi",
        "nationality": "Egyptian",
        "gender": "Male",
        "startYear": 1930,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kandis Williams",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1985,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Stanley Wolukau-Wanambwa",
        "nationality": "British",
        "gender": "Male",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Andy Robert",
        "nationality": "Haitian",
        "gender": "Male",
        "startYear": 1984,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Raque Ford",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1986,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Evelyne Axell",
        "nationality": "Belgian",
        "gender": "Female",
        "startYear": 1935,
        "endYear": 1972,
        "count": 0
    },
    {
        "name": "Jana Euler",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1982,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Norman Teague",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kahlil Robert Irving",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1992,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Analia Saban",
        "nationality": "Argentine",
        "gender": "Female",
        "startYear": 1980,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Abdias Nascimento",
        "nationality": "Brazilian",
        "gender": "Male",
        "startYear": 1914,
        "endYear": 2011,
        "count": 0
    },
    {
        "name": "Dorothee Becker",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1938,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Michael Jang",
        "nationality": "American",
        "gender": "male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Kenturah Davis",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1984,
        "endYear": 0,
        "count": 1
    },
    {
        "name": "Dove Allouche",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1972,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mariette Pathy Allen",
        "nationality": "Egyptian",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Diamond Stingily",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1990,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sheila Pinkel",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1941,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Luo Bonian",
        "nationality": "Chinese",
        "gender": "Male",
        "startYear": 1911,
        "endYear": 2002,
        "count": 0
    },
    {
        "name": "Judy Gelles",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1944,
        "endYear": 2020,
        "count": 0
    },
    {
        "name": "Obiora Udechukwu",
        "nationality": "Nigerian",
        "gender": "Male",
        "startYear": 1946,
        "endYear": 0,
        "count": 25
    },
    {
        "name": "Barbara Rossi",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1940,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Emeka Ogboh",
        "nationality": "Nigerian",
        "gender": "Male",
        "startYear": 1977,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Huguette Caland",
        "nationality": "Lebanese",
        "gender": "Female",
        "startYear": 1931,
        "endYear": 2019,
        "count": 0
    },
    {
        "name": "Lili Reynaud-Dewar",
        "nationality": "French",
        "gender": "Female",
        "startYear": 1975,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Milagros de la Torre",
        "nationality": "Peruvian",
        "gender": "Female",
        "startYear": 1965,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Joey Terrill",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1955,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Artie Vierkant",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1986,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Victor Obsatz",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1925,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Vaginal Davis",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1969,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "John M. Valadez",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1951,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sandra Mujinga",
        "nationality": "Norwegian",
        "gender": "Female",
        "startYear": 1989,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Sheroanawe Hakihiiwe",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 8
    },
    {
        "name": "Martin Barré",
        "nationality": "French",
        "gender": "Male",
        "startYear": 1924,
        "endYear": 1993,
        "count": 0
    },
    {
        "name": "Kay WalkingStick",
        "nationality": "Native American",
        "gender": "Female",
        "startYear": 1935,
        "endYear": 0,
        "count": 3
    },
    {
        "name": "Fonna Forman",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1968,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Abel Naím",
        "nationality": "Venezuelan",
        "gender": "Male",
        "startYear": 1961,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Christophe Guberan",
        "nationality": "Swiss",
        "gender": "Male",
        "startYear": 1985,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Angèle Etoundi Essamba",
        "nationality": "Cameroonian",
        "gender": "Female",
        "startYear": 1962,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Nadja Buttendorf",
        "nationality": "German",
        "gender": "Female",
        "startYear": 1984,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Mohammed Sami",
        "nationality": "Iraqi",
        "gender": "Male",
        "startYear": 1984,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Lauren Halsey",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1987,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "Yolanda Lopez",
        "nationality": "American",
        "gender": "Female",
        "startYear": 1942,
        "endYear": 2021,
        "count": 0
    },
    {
        "name": "Arnt Jensen",
        "nationality": "Danish",
        "gender": "Male",
        "startYear": 1971,
        "endYear": 0,
        "count": 0
    },
    {
        "name": "William Theophilius Brown",
        "nationality": "American",
        "gender": "Male",
        "startYear": 1919,
        "endYear": 2012,
        "count": 0
    }
];