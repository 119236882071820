import L from 'leaflet';
import data from '../data/timeline';

const tiles =
  'https://tiles.stadiamaps.com/tiles/stamen_toner_lite/{z}/{x}/{y}{r}.png';
const attribution =
  '&copy; <a href="https://osm.org/copyright" target="_blank" rel="noopener noreferrer">OpenStreetMap</a> contributors | <a href="https://maps.stamen.com" target="_blank" rel="noopener noreferrer">Map tiles</a> by <a href="https://stamen.com" target="_blank" rel="noopener noreferrer">Stamen Design</a> | Data from <a href="https://en.wikipedia.org" target="_blank" rel="noopener noreferrer">Wikipedia</a>';

const mapSettings = {
  trackResize: false,
  boxZoom: false,
  doubleClickZoom: false,
  keyboard: false,
  scrollWheelZoom: false,
  touchZoom: false,
};
const center = [50.0, 21.0];
const zoom = 4;

document.addEventListener('DOMContentLoaded', globalEvent => {
  if (globalEvent.target.location.pathname !== '/timeline') {
    return;
  }

  const map = L.map('map', mapSettings).setView(center, zoom);
  L.tileLayer(tiles, { attribution }).addTo(map);

  const markersGroup = L.layerGroup().addTo(map);
  const markerIcon = L.divIcon({ className: 'marker' });
  const animatedMarkerIcon = L.divIcon({ className: 'marker animated' });

  const startButton = document.querySelector('.start-button');
  const controls = document.querySelector('.controls-container');
  const lineSlider = controls.querySelector('.line');
  const playPauseButton = controls.querySelector('.button');
  const yearValue = controls.querySelector('.year');
  const switchText = controls.querySelector('.switch');

  const firstYear = data[0].year;
  const lastYear = data[data.length - 1].year;

  let year = firstYear - 1;
  let currentIndex = 0;
  let timeout;
  let sliderElement;
  let sliding = false;
  let lineWidth = lineSlider.clientWidth;
  let lineOffset = lineSlider.getBoundingClientRect().left;

  const getSliderPosition = yValue => {
    const value =
      ((yValue - firstYear) / (lastYear - firstYear - 1)) * lineWidth;
    return Math.min(Math.max(value, 0), lineWidth);
  };

  const getSliderYear = value => {
    const yValue = Math.round(
      firstYear + (value / lineWidth) * (lastYear - firstYear - 1)
    );
    return yValue >= 0 ? yValue + 1 : yValue;
  };

  const hideStartButton = () => {
    startButton.remove();
  };

  const renderMarker = (item, animated = true) => {
    const name = `${item.city}, ${item.country}, ${Math.abs(item.year)} ${
      item.year < 0 ? 'BC' : 'AD'
    }`;

    L.marker(item.coords, { icon: animated ? animatedMarkerIcon : markerIcon })
      .bindTooltip(name, { className: 'tooltip', opacity: 0.95 })
      .addTo(markersGroup);
  };

  const setYear = (animated = true) => {
    yearValue.value = Math.abs(year);
    switchText.innerText = year < 0 ? 'BC' : 'AD';
    controls.classList.toggle('ended', year === lastYear);
    sliderElement.style.left = `${getSliderPosition(year)}px`;

    markersGroup.clearLayers();
    currentIndex = data.findIndex(d => d.year > year);

    data.slice(0, currentIndex).forEach(d => renderMarker(d, animated));
  };

  const resetYear = () => {
    year = firstYear - 1;
    setYear();
  };

  const addYear = () => {
    year += 1;
    if (year === 0) year += 1;
    yearValue.value = Math.abs(year);
    if (year < 0 && switchText.innerText !== 'BC') switchText.innerText = 'BC';
    else if (year > 0 && switchText.innerText !== 'AD')
      switchText.innerText = 'AD';

    sliderElement.style.left = `${getSliderPosition(year)}px`;

    while (currentIndex < data.length && data[currentIndex].year === year) {
      renderMarker(data[currentIndex]);
      currentIndex += 1;
    }

    if (currentIndex === data.length) {
      clearTimeout(timeout);
      controls.classList.remove('playing');
      controls.classList.toggle('ended', true);
    } else {
      timeout = setTimeout(addYear, 20);
    }
  };

  const onStartStop = () => {
    const isPlaying = controls.classList.contains('playing');

    if (isPlaying) {
      controls.classList.remove('playing');
      clearTimeout(timeout);
      timeout = null;
    } else if (year < lastYear) {
      controls.classList.add('playing');
      year -= 1;
      addYear();
    } else {
      resetYear();
    }
  };

  const onStartSlide = event => {
    event.stopPropagation();
    sliding = true;
    hideStartButton();
  };

  const onStopSlide = () => {
    if (!sliding) return;
    sliding = false;
  };

  const onSlide = (event, animated = false) => {
    if (!sliding && !animated) return;

    if (controls.classList.contains('playing')) onStartStop();

    const clientX = event.clientX || event.touches[0].clientX || 0;
    const position = Math.min(Math.max(clientX - lineOffset, 0), lineWidth);

    sliderElement.style.left = `${position}px`;

    year = getSliderYear(position);
    setYear(animated);
  };

  const renderSlider = () => {
    lineSlider.innerHTML = '';

    data.forEach(point => {
      const pointElem = document.createElement('div');

      pointElem.className = 'point';
      pointElem.style.left = `${getSliderPosition(point.year)}px`;
      lineSlider.appendChild(pointElem);
    });

    const zeroElem = document.createElement('div');
    zeroElem.className = 'point zero';
    zeroElem.style.left = `${getSliderPosition(1)}px`;
    lineSlider.appendChild(zeroElem);

    sliderElement = document.createElement('div');
    sliderElement.className = 'slider';
    lineSlider.appendChild(sliderElement);

    sliderElement.addEventListener('mousedown', onStartSlide);
    sliderElement.addEventListener('touchstart', onStartSlide);
  };

  const fitBounds = () => {
    map.fitBounds(data.map(p => p.coords), { padding: [10, 10] });
  };

  startButton.addEventListener('click', onStartStop);
  playPauseButton.addEventListener('click', onStartStop);

  lineSlider.addEventListener('mousedown', event => {
    onSlide(event, true);
  });
  lineSlider.addEventListener('touchstart', event => {
    onSlide(event, true);
  });

  window.addEventListener('resize', () => {
    lineWidth = lineSlider.clientWidth;
    lineOffset = lineSlider.getBoundingClientRect().left;
    fitBounds();
    renderSlider();
  });

  document.addEventListener('mousemove', onSlide);
  document.addEventListener('touchmove', onSlide);
  document.addEventListener('mouseup', onStopSlide);
  document.addEventListener('touchend', onStopSlide);

  document.addEventListener('click', hideStartButton);

  yearValue.addEventListener('change', event => {
    const epoch = switchText.innerText === 'BC' ? -1 : 1;
    const value = Math.max(
      Math.min(epoch * Math.abs(event.target.valueAsNumber), lastYear),
      firstYear
    );

    year = value === 0 ? value + epoch : value;
    setYear();
  });

  switchText.addEventListener('click', () => {
    year = Math.max(Math.min(-year, lastYear), firstYear);
    setYear();
  });

  fitBounds();
  yearValue.value = Math.abs(year);
  renderSlider();
});
