export default [
  {
    year: -770,
    city: 'Málaga',
    country: 'Spain',
    coords: [36.72016, -4.42034],
  },
  { year: -753, city: 'Rome', country: 'Italy', coords: [41.89193, 12.51133] },
  {
    year: -700,
    city: 'Lisbon',
    country: 'Portugal',
    coords: [38.71667, -9.13333],
  },
  {
    year: -660,
    city: 'Istanbul',
    country: 'Turkey',
    coords: [41.01384, 28.94966],
  },
  {
    year: -656,
    city: 'Constanța',
    country: 'Romania',
    coords: [44.1733, 28.6383],
  },
  {
    year: -600,
    city: 'Marseille',
    country: 'France',
    coords: [43.29695, 5.38107],
  },
  { year: -600, city: 'Milan', country: 'Italy', coords: [45.46427, 9.18951] },
  {
    year: -600,
    city: 'Naples',
    country: 'Italy',
    coords: [40.85631, 14.24641],
  },
  {
    year: -575,
    city: 'Varna',
    country: 'Bulgaria',
    coords: [43.23726, 27.829096],
  },
  {
    year: -550,
    city: 'Verona',
    country: 'Italy',
    coords: [45.4299, 10.98444],
  },
  {
    year: -510,
    city: 'Bologna',
    country: 'Italy',
    coords: [44.49381, 11.33875],
  },
  {
    year: -500,
    city: 'Sofia',
    country: 'Bulgaria',
    coords: [42.698334, 23.319941],
  },
  { year: -400, city: 'Genoa', country: 'Italy', coords: [44.4264, 8.91519] },
  {
    year: -350,
    city: 'Nice',
    country: 'France',
    coords: [43.70313, 7.26608],
  },
  {
    year: -300,
    city: 'Porto',
    country: 'Portugal',
    coords: [41.14961, -8.61099],
  },
  { year: -250, city: 'Paris', country: 'France', coords: [48.85341, 2.3488] },
  {
    year: -230,
    city: 'Barcelona',
    country: 'Spain',
    coords: [41.38879, 2.15899],
  },
  {
    year: -200,
    city: 'Toulouse',
    country: 'France',
    coords: [43.60426, 1.44367],
  },
  {
    year: -138,
    city: 'Valencia',
    country: 'Spain',
    coords: [39.46975, -0.37739],
  },
  {
    year: -59,
    city: 'Florence',
    country: 'Italy',
    coords: [43.77925, 11.24626],
  },
  { year: -43, city: 'Lyon', country: 'France', coords: [45.74846, 4.84671] },
  { year: -38, city: 'Cologne', country: 'Germany', coords: [50.93333, 6.95] },
  {
    year: 47,
    city: 'London',
    country: 'United Kingdom',
    coords: [51.50853, -0.12574],
  },
  {
    year: 100,
    city: 'Stuttgart',
    country: 'Germany',
    coords: [48.78232, 9.17702],
  },
  {
    year: 421,
    city: 'Venice',
    country: 'Italy',
    coords: [45.43713, 12.33265],
  },
  { year: 482, city: 'Kiev', country: 'Ukraine', coords: [50.45466, 30.5238] },
  {
    year: 500,
    city: 'Hamburg',
    country: 'Germany',
    coords: [53.57532, 10.01534],
  },
  {
    year: 550,
    city: 'Madrid',
    country: 'Spain',
    coords: [40.4165, -3.70256],
  },
  { year: 550, city: 'Wrocław', country: 'Poland', coords: [51.1, 17.03333] },
  {
    year: 750,
    city: 'Dubrovnik',
    country: 'Croatia',
    coords: [42.64807, 18.09216],
  },
  {
    year: 800,
    city: 'Prague',
    country: 'Czech Republic',
    coords: [50.08804, 14.42076],
  },
  {
    year: 859,
    city: 'Veliky Novgorod',
    country: 'Russia',
    coords: [58.52131, 31.27104],
  },
  {
    year: 862,
    city: 'Polotsk',
    country: 'Belarus',
    coords: [55.4879, 28.7856],
  },
  {
    year: 863,
    city: 'Smolensk',
    country: 'Russia',
    coords: [54.7818, 32.0401],
  },
  {
    year: 881,
    city: 'Vienna',
    country: 'Austria',
    coords: [48.20849, 16.37208],
  },
  { year: 903, city: 'Pskov', country: 'Russia', coords: [57.8136, 28.3496] },
  {
    year: 965,
    city: 'Kraków',
    country: 'Poland',
    coords: [50.06143, 19.93658],
  },
  {
    year: 974,
    city: 'Vitebsk',
    country: 'Belarus',
    coords: [55.1904, 30.2049],
  },
  {
    year: 979,
    city: 'Brussels',
    country: 'Belgium',
    coords: [50.85045, 4.34878],
  },
  {
    year: 990,
    city: 'Vladimir',
    country: 'Russia',
    coords: [56.13655, 40.39658],
  },
  {
    year: 997,
    city: 'Gdańsk',
    country: 'Poland',
    coords: [54.35205, 18.64637],
  },
  {
    year: 1010,
    city: 'Yaroslavl',
    country: 'Russia',
    coords: [57.62987, 39.87368],
  },
  {
    year: 1019,
    city: 'Brest',
    country: 'Belarus',
    coords: [52.09755, 23.68775],
  },
  {
    year: 1024,
    city: 'Suzdal',
    country: 'Russia',
    coords: [56.42274, 40.44668],
  },
  { year: 1067, city: 'Minsk', country: 'Belarus', coords: [53.9, 27.56667] },
  {
    year: 1094,
    city: 'Zagreb',
    country: 'Croatia',
    coords: [45.81444, 15.97798],
  },
  { year: 1095, city: 'Ryazan', country: 'Russia', coords: [54.6269, 39.6916] },
  {
    year: 1135,
    city: 'Tver',
    country: 'Russia',
    coords: [56.85836, 35.90057],
  },
  {
    year: 1147,
    city: 'Moscow',
    country: 'Russia',
    coords: [55.75222, 37.61556],
  },
  {
    year: 1152,
    city: 'Kostroma',
    country: 'Russia',
    coords: [57.76647, 40.92686],
  },
  {
    year: 1158,
    city: 'Munich',
    country: 'Germany',
    coords: [48.13743, 11.57549],
  },
  {
    year: 1167,
    city: 'Copenhagen',
    country: 'Denmark',
    coords: [55.67594, 12.56553],
  },
  {
    year: 1174,
    city: 'Poltava',
    country: 'Ukraine',
    coords: [49.59373, 34.54073],
  },
  { year: 1201, city: 'Riga', country: 'Latvia', coords: [56.946, 24.10589] },
  {
    year: 1206,
    city: 'Dresden',
    country: 'Germany',
    coords: [51.05089, 13.73832],
  },
  {
    year: 1219,
    city: 'Tallinn',
    country: 'Estonia',
    coords: [59.43696, 24.75353],
  },
  {
    year: 1221,
    city: 'Nizhny Novgorod',
    country: 'Russia',
    coords: [56.32867, 44.00205],
  },
  {
    year: 1237,
    city: 'Berlin',
    country: 'Germany',
    coords: [52.52437, 13.41053],
  },
  {
    year: 1240,
    city: 'Lviv',
    country: 'Ukraine',
    coords: [49.83826, 24.02324],
  },
  {
    year: 1241,
    city: 'Hannover',
    country: 'Germany',
    coords: [52.37052, 9.73322],
  },
  {
    year: 1252,
    city: 'Stockholm',
    country: 'Sweden',
    coords: [59.33258, 18.0649],
  },
  {
    year: 1275,
    city: 'Amsterdam',
    country: 'Netherlands',
    coords: [52.37403, 4.88969],
  },
  {
    year: 1300,
    city: 'Warsaw',
    country: 'Poland',
    coords: [52.22977, 21.01178],
  },
  {
    year: 1323,
    city: 'Vilnius',
    country: 'Lithuania',
    coords: [54.68916, 25.2798],
  },
];
