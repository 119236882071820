import * as d3 from 'd3';

const bounds = {
  lat: [38.86617680355024, 38.92976949341693],
  lon: [-77.09793090820314, -76.9750213623047],
};
const types = ['lime', 'bird', 'lyft', 'spin', 'bolt', 'jumpmobility'];
const colors = {
  lime: '#1b9e77',
  bird: '#d95f02',
  lyft: '#7570b3',
  bolt: '#e7298a',
  jumpmobility: '#66a61e',
  spin: '#e6ab02',
};
const canvases = {};

document.addEventListener('DOMContentLoaded', globalEvent => {
  if (globalEvent.target.location.pathname !== '/scooters') {
    return;
  }

  const initCanvases = () => {
    types.forEach(type => {
      const container = document.querySelector(`#map-${type}`);
      const canvas = document.createElement('canvas');
      canvas.width = container.clientWidth;
      canvas.height = container.clientHeight;
      container.appendChild(canvas);
      canvases[type] = canvas;
    });
  };

  const renderLegend = (element, data) => {
    element.innerHTML = types.reduce(
      (res, t) =>
        `${res}<div><span style="background-color: ${colors[t]}"></span>${t}: ${
          data[t] ? `${data[t].length.toLocaleString('ru-RU')}` : '0'
        }</div>`,
      ''
    );
  };

  const renderMarker = (ctx, width, height, { lat, lon }, color) => {
    const x = ((lon - bounds.lon[0]) / (bounds.lon[1] - bounds.lon[0])) * width;
    const y =
      ((lat - bounds.lat[1]) / (bounds.lat[0] - bounds.lat[1])) * height;

    ctx.beginPath();
    ctx.arc(x, y, 0.5, 0, 2 * Math.PI, false);
    ctx.fillStyle = color;
    ctx.fill();
  };

  const renderMap = (type, data) => {
    const ctx = canvases[type].getContext('2d');
    const { width, height } = canvases[type];

    ctx.clearRect(0, 0, width, height);

    if (data[type]) {
      data[type].forEach(marker => {
        renderMarker(ctx, width, height, marker, colors[type]);
      });
    }
  };

  const renderData = data => {
    types.forEach(t => renderMap(t, data));
    renderLegend(document.querySelector(`.types-legend-points`), data);
  };

  const request = new XMLHttpRequest();
  request.open('GET', '/data/scooters-positions.json', false);
  request.send(null);

  const data = JSON.parse(request.responseText);

  initCanvases();
  renderData(data.july);

  document.querySelectorAll('h2 > span').forEach(el => {
    el.addEventListener('click', () => {
      document.querySelector('h2 > span.active').classList.remove('active');
      el.classList.add('active');
      renderData(data[el.dataset.value]);
    });
  });

  // -------
  // -------
  // -------

  const tempRequest = new XMLHttpRequest();
  tempRequest.open('GET', '/data/scooters-counts.json', false);
  tempRequest.send(null);

  const countsData = JSON.parse(tempRequest.responseText);

  renderLegend(document.querySelector('.types-legend-counts'), types);

  const margin = { top: 20, right: 20, bottom: 30, left: 40 };
  const width = 800 - margin.left - margin.right;
  const height = 400 - margin.top - margin.bottom;

  const parseTime = d3.timeParse('%Y-%m-%d');

  const x = d3
    .scaleTime()
    .range([0, width])
    .domain([
      parseTime(countsData[0].date),
      parseTime(countsData[countsData.length - 1].date),
    ]);
  const y = d3
    .scaleLinear()
    .range([height, 0])
    .domain([0, 50000]);

  const lines = types.map((_, index) =>
    d3
      .line()
      .x(d => x(parseTime(d.date)))
      .y(d => y(d.values[index].count))
  );

  const svg = d3
    .select('svg.counts')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  svg
    .append('g')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x));

  svg.append('g').call(d3.axisLeft(y));

  types.forEach((type, index) => {
    svg
      .append('path')
      .data([countsData])
      .attr('class', 'line')
      .attr('stroke', colors[type])
      .attr('d', lines[index]);
  });
});
